import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
// Types
import { Items } from 'types/globalTypes';
// Actions
import { QuoteGetDataAction } from 'store/quote/quoteActions';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useFormField } from 'hooks/useFormField';
import { useTranslations } from 'hooks/useTranslations';
// Utils
import { twoLetterString } from 'utils/strings';
import { calcTotalFees, CurrencyFormatByISO } from 'utils/price';
import { calcTaxesQuote } from 'utils/functions';
// Assets
import { ReactComponent as Close } from 'assets/icons/switch/close.svg';
import { ReactComponent as Check } from 'assets/icons/switch/check.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
// styles
import classes from './ModalAddPrice.module.scss';

interface Props {
  handleClose: () => void;
  currentItem: Items;
  iso?: string;
}

const ModalAddPrice: React.FC<Props> = ({
  handleClose,
  iso,
  currentItem,
}) => {
  const [taxIncluded, setTaxIncluded] = useState(
    currentItem?.taxes?.length ? !currentItem?.taxes[0].included : true,
  );
  const {
    data,
  } = useTypeSelector((state) => state.quote);
  const formatCurrencyByISO = CurrencyFormatByISO(iso);
  const priceField = useFormField(String(currentItem.price));
  const taxField = useFormField(String(currentItem?.taxes?.length ? currentItem?.taxes[0].percentage : ''));
  const typeField = useFormField(currentItem?.taxes?.length ? currentItem?.taxes[0].name : '');
  const translations = useTranslations();
  const dispatch = useDispatch();
  const handleTaxClick = useCallback(() => {
    setTaxIncluded((prop) => !prop);
  }, []);
  const total = () => {
    const subtotal = Number(priceField.value) * currentItem.quantity;
    if (!taxIncluded) {
      return subtotal;
    }

    return subtotal + (Number(taxField.value) / 100) * Number(priceField.value);
  };
  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    if (!data) {
      return;
    }

    const updateItems = data.items.map((item) => {
      if (item.itemId !== currentItem.itemId) {
        return {
          ...item,
        };
      }
      return {
        ...item,
        price: Number(priceField.value),
        taxes: taxField.value ? [{
          amount: Number(priceField.value) / Number(taxField.value),
          included: !taxIncluded,
          name: typeField.value,
          percentage: Number(taxField.value),
        }] : [],
      };
    });

    const subtotal = () => {
      if (updateItems.some((i) => i.price)) {
        return updateItems.reduce(
          (acc: number, item) => acc + (item.price || 0) * item.quantity,
          0,
        );
      }
      return undefined;
    };

    const totalFees = calcTotalFees(data.price.fees);
    const taxes = calcTaxesQuote(updateItems);

    const totalPrice = () => {
      const sbtl = subtotal();
      if (typeof sbtl === 'undefined') {
        return undefined;
      }
      return sbtl - (data?.price?.discount || 0) + totalFees + (taxes.excluded ?? 0);
    };

    dispatch(QuoteGetDataAction({
      ...data,
      items: updateItems,
      price: {
        subtotal: subtotal(),
        currencyIso: data.price.currencyIso,
        discount: data.price.discount,
        total: totalPrice(),
        fees: data.price.fees,
        currencySymbol: data.organisation.address.currencySymbol,
        taxesIncluded: taxes.included,
        taxesExcluded: taxes.excluded,
      },
    }));
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    handleClose,
    currentItem.itemId,
    priceField.value,
    taxField.value,
    taxIncluded,
    typeField.value]);

  return (
    <div className={classes.modal}>
      <div
        className={classes.modal_wrapper}
      >
        <div
          aria-hidden="true"
          onClick={handleClose}
          className={classes.modal_close}
        >
          <CloseIcon />
        </div>
        <div className={classes.modal_content}>
          <h2 className={classes.title}>
            {translations.documents.quotes.item_pricing}
          </h2>
          <div className={classes.item}>
            <div className={classes.item_description}>
              <div className={classes.item_avatar}>
                {currentItem?.src ? (
                  <img src={currentItem?.src} alt={currentItem.name} />
                ) : (
                  <span>
                    {twoLetterString(currentItem.name)}
                  </span>
                )}
              </div>
              <h3>
                {currentItem.name}
                {!!currentItem.options?.length && currentItem.options.map((option, index) => (
                  currentItem?.options && (
                    <div
                      key={option.variant}
                    >
                      {option.variant}{index === currentItem.options.length - 1 ? '' : ', '}
                    </div>
                  )
                ))}
              </h3>
              <div className={classes.item_quantity}>
                {currentItem.quantity}
              </div>
            </div>
          </div>
          <form
            className={classes.price_form}
            onSubmit={handleSubmit}
          >
            <div className={classes.price_form_item}>
              <div className={classes.price_form_item_description}>
                <p className={classes.price_form_item_description_label}>
                  {`${translations.transactions.unit_price} (${data?.organisation.address.currencySymbol})`}
                </p>
              </div>
              <input
                className={classes.price_form_item_input}
                type="number"
                min="0"
                required
                id="price"
                step=".01"
                placeholder={translations.documents.quotes.modal_add_discount.input_placeholder}
                {...priceField}
              />
            </div>
            <div className={classes.price_form_item}>
              <div className={classes.price_form_item_description}>
                <p className={classes.price_form_item_description_label}>
                  {translations.documents.quotes.tax}
                </p>
                <p className={classes.price_form_item_description_optional}>
                  {translations.documents.quotes.optional}
                </p>
              </div>
              <input
                className={classes.price_form_item_input}
                type="number"
                id="tax"
                min="0"
                step=".01"
                placeholder={translations.documents.quotes.enter_tax_value}
                {...taxField}
              />
            </div>
            <div className={classes.price_form_item}>
              <div className={classes.price_form_item_description}>
                <p className={classes.price_form_item_description_label}>
                  {translations.documents.quotes.tax_type}
                </p>
                <p className={classes.price_form_item_description_optional}>
                  {translations.documents.quotes.optional}
                </p>
              </div>
              <input
                className={classes.price_form_item_input_tax_type}
                type="text"
                id="type"
                {...typeField}
                placeholder={translations.documents.quotes.vat}
              />
            </div>
            <div className={classes.price_form_item}>
              <p className={classes.price_form_item_description_label}>
                {translations.documents.quotes.tax_included}
              </p>
              <div
                tabIndex={0}
                role="button"
                className={classes.tax_included_container}
                onClick={handleTaxClick}
              >
                <div className={cn(classes.tax_included, {
                  [classes.tax_included_check]: taxIncluded,
                })}
                >
                  {taxIncluded ? <Close /> : <Check />}
                </div>
              </div>
            </div>
            <div className={classes.add_price_footer}>
              <div className={classes.add_price_footer_left}>
                <p className={classes.add_price_footer_left_title}>
                  {translations.documents.quotes.total}
                </p>
                <p className={classes.add_price_footer_left_price}>
                  {formatCurrencyByISO(total())}
                </p>
              </div>
              <input
                className={classes.add_price_footer_btn}
                type="submit"
                value={translations.documents.quotes.add_to_quote}
                disabled={!priceField.value}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModalAddPrice;
