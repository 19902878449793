import React from 'react';
// Hooks
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Types
import { SalesChannel } from 'store/storefront/shop/shopTypes';
// Utils
import { StoreFrontFunctions } from 'utils/storefront/StoreFrontFunctions';
// styles
import { IOrganisationData } from 'types/globalTypes';
import classes from './Footer.module.scss';

interface Props {
  organisation: IOrganisationData;
}

const Footer: React.FC<Props> = ({ organisation }) => {
  const translations = useTranslationsStorefront();
  const fullMobileNumber = `${organisation.address.idd} ${organisation.phoneNumber}`;

  return (
    <div className={classes.footer}>
      <div className={classes.footer_left}>
        <p className={classes.footer_title}>
          {translations.documents.footer.title}
        </p>
        <div className={classes.footer_address}>
          <p>{organisation?.businessAs}</p>
          <p>{organisation?.address.line1}</p>
          <p>{organisation?.address.line2}</p>
          <p>
            {`${organisation.address.country}
              ${organisation?.address.postalCode}
              ${organisation.address.country}`}
          </p>
        </div>
      </div>
      <div className={classes.footer_right}>
        <a href={`mailto:${organisation.email}`}>
          {organisation.email}
        </a>
        <a href={`tel: ${fullMobileNumber}`}>
          {fullMobileNumber}
        </a>
      </div>
    </div>
  );
};

export default Footer;
