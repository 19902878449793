import React from 'react';
// Hooks
import { useUser } from 'hooks/useUser';
// Types
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
import { ProductItem } from 'store/dashboard/catalog/items/itemsTypes';
// Utils
import { CurrencyFormatRange } from 'utils/price';
import { twoLetterString } from 'utils/strings';
// Styles
import classes from './Item.module.scss';

interface Props {
  data: ProductItem,
  translations: TranslationDashboardModel['sidebar_transaction']['catalog_item']
  openDeleteConfirm: () => void
}

const Item: React.FC<Props> = ({ data, translations, openDeleteConfirm }) => {
  const user = useUser();
  const currencyISO = user?.currentOrganisation?.address?.currencyISO || 'EUR';

  return (
    <div className={classes.item}>
      <div className={classes.item_name}>
        <div className={classes.item_avatar}>
          {data?.images?.length ? (
            <img src={data?.images[0]} alt={data.name} />
          ) : (
            <span>
              {twoLetterString(data.name)}
            </span>
          )}
        </div>
        <h3>
          {data.name}
        </h3>
      </div>
      <div className={classes.item_price_range}>
        {CurrencyFormatRange(data.priceRange, currencyISO)}
      </div>
      <div className={classes.item_taxes}>
        {data.taxes && data.taxes.map((tax) => (
          <div className={classes.item_tax} key={tax.name}>
            <div>
              <p className={classes.item_tax_type}>
                {tax.name}
              </p>
            </div>
            <span>
              {`${tax.percentage}% (${(tax.included)
                ? translations.taxes_included
                : translations.taxes_excluded})`}
            </span>
          </div>
        ))}
      </div>
      <div className={classes.item_description}>
        <div className={classes.brand}>
          <p className={classes.brand_label}>
            {translations.brand_label}
          </p>
          <p className={classes.brand_value}>
            {data.brand}
          </p>
        </div>
        <div className={classes.category}>
          <p className={classes.category_label}>
            {translations.category_label}
          </p>
          <p className={classes.category_value}>
            {data.category?.name}
          </p>
        </div>
        <div className={classes.identifier}>
          <p className={classes.identifier_label}>
            {translations.unit_identifier_label}
          </p>
          <p className={classes.identifier_value}>
            {data.unitId}
          </p>
        </div>
        <div className={classes.description}>
          <p className={classes.description_label}>
            {translations.description_label}
          </p>
          <p className={classes.description_value}>
            {data.description}
          </p>
        </div>
      </div>
      <button type="button" className={classes.btn_delete} onClick={openDeleteConfirm}>
        {translations.delete_btn_title}
      </button>
    </div>
  );
};

export default Item;
