import React, { useMemo } from 'react';
// Hooks
import { useMobileScreen } from 'hooks/useMobileScreen';
// Types
import { Items } from 'types/globalTypes';
import { TranslationStorefrontModel } from 'types/TranslationStorefrontModel';
// Utils
import { CurrencyFormatByISO, totalPriceItem } from 'utils/price';
import { twoLetterString } from 'utils/strings';
// Styles
import classes from './Item.module.scss';

interface Props {
  data: Items;
  currencyIso: string;
  translation: TranslationStorefrontModel['cart']['cart_item'];
}

const Item: React.FC<Props> = ({ data, currencyIso, translation }) => {
  const formatCurrencyByISO = useMemo(
    () => CurrencyFormatByISO(currencyIso),
    [currencyIso],
  );
  const { isMobile } = useMobileScreen();

  const totalItem = useMemo(() => {
    const taxExcluded = data?.taxes?.reduce(
      (acc, item) => acc + (item.included ? 0 : item.amount),
      0,
    );

    return totalPriceItem(data.price, data.quantity, taxExcluded || 0);
  }, [data.quantity, data?.taxes, data.price]);

  if (isMobile) {
    return (
      <div className={classes.item}>
        <div className={classes.item_description}>
          <div className={classes.item_avatar}>
            {data.src ? (
              <img src={data.src} alt={data.name} />
            ) : (
              <span>{twoLetterString(data.name)}</span>
            )}
          </div>
          <div className={classes.item_description_name_option}>
            <p>{data.name}</p>
            <p>{data.description}</p>
          </div>
        </div>
        <div className={classes.item_quantity}>
          <p className={classes.item_quantity_title}>{translation.quantity}</p>
          <p className={classes.item_quantity_value}>{data.quantity}</p>
        </div>
        <div className={classes.item_mob_unit_price}>
          <p>{translation.unit_price}</p>
          <p>
            {formatCurrencyByISO(data.price)}
            <div className={classes.item_mob_taxes}>
              {data?.taxes?.map((tax) => (
                <div className={classes.item_price_taxes_tax} key={tax.name}>
                  <span className={classes.item_mob_tax}>
                    +{tax.name}
                    {formatCurrencyByISO(tax.amount)}
                    {tax.included
                      ? translation.taxes_included
                      : translation.taxes_excluded}
                  </span>
                </div>
              ))}
            </div>
          </p>
        </div>
        <div className={classes.item_mob_total}>
          <p>{translation.total}</p>
          <p>{formatCurrencyByISO(totalItem)}</p>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.item}>
      <div className={classes.item_description}>
        <div className={classes.item_avatar}>
          {data.src ? (
            <img src={data.src} alt={data.name} />
          ) : (
            <span>{twoLetterString(data.name)}</span>
          )}
        </div>
        <div className={classes.item_description_name_option}>
          <p>{data.name}</p>
          <p>{data.description}</p>
        </div>
      </div>
      <div className={classes.item_quantity}>{data.quantity}</div>
      <div className={classes.item_price}>
        <div className={classes.item_price_subtotal}>
          {formatCurrencyByISO(data.price)}
        </div>
        <div className={classes.item_price_taxes}>
          {data?.taxes?.map((tax) => (
            <div className={classes.item_price_taxes_tax} key={tax.name}>
              <p>
                +{tax.name} {formatCurrencyByISO(tax.amount)}
              </p>
              <p>
                {tax.included
                  ? translation.taxes_included
                  : translation.taxes_excluded}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.item_total}>{formatCurrencyByISO(totalItem)}</div>
    </div>
  );
};

export default Item;
