import { useMobileScreen } from 'hooks/useMobileScreen';
import React, { useMemo } from 'react';
import { formatReg } from 'utils/dates';
import { CurrencyFormatByISO } from 'utils/price';
import { useTranslations } from 'hooks/useTranslations';
import { Link } from 'react-router-dom';
import { useDashboardRoutes } from 'hooks/useDashboardRoutes';
import { capitalizeFirstLetter } from 'utils/strings';
import { DirectoryListType } from 'store/dashboard/directory/directoryTypes';
import { useUser } from 'hooks/useUser';
// Styles
import classes from './Directory.module.scss';

interface Props {
  item: DirectoryListType;
}

const Directory: React.FC<Props> = ({ item }) => {
  const { isMobile } = useMobileScreen();
  const { pathToDirectoryDetails } = useDashboardRoutes();
  const translations = useTranslations();
  const user = useUser();
  const tsMobile = translations.directory.mobile_card;
  const formatCurrencyByISO = CurrencyFormatByISO(user?.currentOrganisation?.address?.currencyISO || 'USD');

  const type = useMemo(() => {
    if (item.sales && item.expenses) {
      return !isMobile ? translations.directory.type.customer_vendor : `${translations.directory.type.customer} / ${translations.directory.type.vendor}`;
    }

    if (item.sales) {
      return translations.directory.type.customer;
    }

    return translations.directory.type.vendor;
  }, [item.expenses, item.sales, translations, isMobile]);

  return isMobile ? (
    <Link key={item.id} className={classes.item_mb} to={pathToDirectoryDetails(`${item.id}`)}>
      <div className={classes.item_mb_header}>
        <p className={classes.item_mb_header_date}>{`${tsMobile.added} ${formatReg(item.added)}`}</p>
        <p className={classes.item_mb_header_type}>{type}</p>
      </div>
      <div className={classes.item_mb_name}>{item.name}</div>
      <div className={classes.item_mb_source}>
        {`${tsMobile.last_active} ${item.activity}`}
      </div>
      <div className={classes.item_mb_value}>{item.location}</div>
      <div className={classes.item_mb_bottom}>
        <div className={classes.item_mb_bottom_first_row}>
          <div>{tsMobile.transactions}</div>
          <div>{item.transactions}</div>
        </div>
        <div className={classes.item_mb_bottom_second_row}>
          <div>{tsMobile.items}</div>
          <div>{formatCurrencyByISO(item.avgSpend)}</div>
        </div>
      </div>
    </Link>
  ) : (
    <Link
      key={item.id}
      className={classes.table_content_item}
      to={pathToDirectoryDetails(`${item.id}`)}
    >
      <p>{item.name}</p>
      <p>{formatReg(item.added)}</p>
      <p>{capitalizeFirstLetter(item.location) || '-'}</p>
      <p>{type}</p>
      <p>{item.transactions}</p>
      <p>{formatCurrencyByISO(item.avgSpend)}</p>
      <p>{item.activity}</p>
    </Link>
  );
};

export default Directory;
