import React from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useUser } from 'hooks/useUser';
import { Link } from 'react-router-dom';
// Utils
import { CurrencyFormatRange } from 'utils/price';
// Types
import { ProductItem } from 'store/dashboard/catalog/items/itemsTypes';
// Styles
import classes from './TableItemMobile.module.scss';

interface Props {
  item: ProductItem;
  pathToDetails: string;

}

const TableItemMobile: React.FC<Props> = ({
  item,
  pathToDetails,
}) => {
  const user = useUser();
  const translations = useTranslations();
  const ts = translations.catalog;

  return (
    <Link
      to={pathToDetails}
      className={classes.mobile__table_item}
    >
      <h3 className={classes.mobile__table_item_title}>
        {item.name}
      </h3>
      <p className={classes.mobile__table_item_subtitle}>
        {item.brand}
      </p>
      <p className={classes.price}>
        {user && CurrencyFormatRange(item.priceRange, user?.currentOrganisation?.address?.currencyISO || 'USD')}
      </p>
      <div className={classes.mobile__table_item_info}>
        <span>
          {ts.options}
        </span>
        <span className={classes.active}>
          {item.countOptions}
        </span>
      </div>
      <div className={classes.mobile__table_item_info}>
        <span>
          {ts.stock}
        </span>
        <span className={classes.subtitle}>
          {item.stock}
        </span>
      </div>
      <div className={classes.mobile__table_item_info}>
        <span>
          {ts.locations}
        </span>
        <span className={classes.subtitle}>
          {item.salesChannelCount || '-'}
        </span>
      </div>
    </Link>
  );
};

export default TableItemMobile;
