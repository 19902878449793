import React from 'react';
// Hooks
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useTypeSelector } from 'hooks/useTypeSelector';
// Actions
import { TransactionTypes } from 'store/dashboard/transaction/transactionTypes';
// Types
import { Items, TransactionStatus } from 'types/globalTypes';
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
// Utils
import { CurrencyFormatByISO } from 'utils/price';
import { twoLetterString } from 'utils/strings';
// Styles
import classes from './Item.module.scss';

interface Props {
  data: Items,
  currencyIso: string,
  translations: TranslationDashboardModel['sidebar_transaction']['transaction_item']
}

const Item: React.FC<Props> = ({ data, currencyIso, translations }) => {
  const formatCurrencyByISO = CurrencyFormatByISO(currencyIso);
  const { isTablet } = useMobileScreen();
  const { detailItem } = useTypeSelector(({ transaction }) => transaction);
  const isNoPricedQuote = detailItem?.type === TransactionTypes.PURCHASE_ORDER
    && (detailItem.status === TransactionStatus.PENDING
      || detailItem.status === TransactionStatus.EXPIRED);

  return (
    <div className={classes.item}>
      <div className={classes.item_description}>
        <div className={classes.item_avatar}>
          {data?.src ? (
            <img src={data?.src} alt={data.name} />
          ) : (
            <span>
              {twoLetterString(data.name)}
            </span>
          )}
        </div>
        <h3>
          {data.name}

          {!isTablet && !!data.options?.length && data.options.map((option, index) => (
            data?.options && (
              <div
                className={classes.variant}
                key={option.variant}
              >
                {option.variant}{index === data.options.length - 1 ? '' : ', '}
              </div>
            )
          ))}
        </h3>
        <div className={classes.item_quantity}>
          {data.quantity}
        </div>
      </div>
      {isTablet && !!data.options?.length && data.options.map((option, index) => (
        data?.options && (
          <div
            className={classes.variant}
            key={option.variant}
          >
            {option.variant}{index === data.options.length - 1 ? '' : ', '}
          </div>
        )
      ))}
      {isNoPricedQuote
        ? (
          <span className={classes.pending_vendor}>
            {translations.pending_vendor_response}
          </span>
        )
        : (
          <>
            <div className={classes.item_price}>
              <span>
                {formatCurrencyByISO(data.price * data.quantity)}
              </span>
            </div>
            <div className={classes.item_taxes}>
              {data?.taxes?.map((tax) => (
                <div className={classes.item_tax} key={tax.name}>
                  <div>
                    <p className={classes.item_tax_type}>
                      {(tax.included)
                        ? translations.taxes_excluded
                        : translations.taxes_included}
                    </p>
                    <p className={classes.item_tax_amount}>
                      {formatCurrencyByISO(
                        tax.amount,
                      )}
                    </p>
                  </div>
                  <span>
                    {`${tax.name} (${tax.percentage}%)`}
                  </span>
                </div>
              ))}
            </div>
          </>
        )}
    </div>
  );
};

export default Item;
