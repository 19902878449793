import { SalesChannel } from 'store/storefront/shop/shopTypes';
import {
  ContactType,
  PriceType,
  DetailsType,
  TermsPaymentType,
  Payments,
  Items,
  IOrganisationData,
  TransactionStatus,
} from 'types/globalTypes';

// Action Types
export const ESTIMATE_LOADING = 'ESTIMATE_LOADING';
export const ESTIMATE_GET_DATA = 'ESTIMATE_GET_DATA';
export const ESTIMATE_NOTIFICATION = 'ESTIMATE_NOTIFICATION';
export const ESTIMATE_GET_STATUS = 'ESTIMATE_GET_STATUS';
export const ESTIMATE_GET_ACCEPT = 'ESTIMATE_GET_ACCEPT';

// Reducer type
export interface EstimateState {
  loading: boolean
  isNewRequestEstimate?: boolean
  data: IEstimateData | null;
  paymentSchedule?: boolean
  status: TransactionStatus;
  notification: boolean
  loadingAccept: boolean;
}

export interface IEstimateData {
  organisation: IOrganisationData
  contact: ContactType & {fullName: string}
  details: DetailsType;
  items: Items[],
  price: PriceType
  payments: Payments[] | []
  termsPayment: TermsPaymentType
}

export type EstimateDetailsType = {
  type: string,
  estimateNumber: string,
  estimateDate: string,
}

export enum EstimateModal {
  accept = 'ACCEPT',
  decline = 'DECLINE'
}

interface EstimateLoadingAction {
  type: typeof ESTIMATE_LOADING
  payload: boolean
}

interface GetEstimateDataAction {
  type: typeof ESTIMATE_GET_DATA
  payload: IEstimateData
}

interface EstimateNotificationAction {
  type: typeof ESTIMATE_NOTIFICATION
  payload: boolean
}

interface EstimateGetStatusAction {
  type: typeof ESTIMATE_GET_STATUS
  payload: TransactionStatus
}
interface EstimateGetAcceptAction {
  type: typeof ESTIMATE_GET_ACCEPT
  payload: boolean
}

export type EstimateActions = EstimateLoadingAction
  | GetEstimateDataAction
  | EstimateNotificationAction
  | EstimateGetStatusAction
  | EstimateGetAcceptAction;
