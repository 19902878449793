import React, { useState, useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
// Stripe
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import {
  StripeCardNumberElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardCvcElementChangeEvent,
  StripeError,
} from '@stripe/stripe-js';
// Constants
import { PaymentsOptions, PayMethodsEnum } from 'constants/paymentsMethods';
import CountriesList from 'data/countries-list.json';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
// Types
import { NotificationEnum } from 'store/storefront/shop/shopTypes';
import { TranslationStorefrontModel } from 'types/TranslationStorefrontModel';
// Images
import { ReactComponent as AlertIcon } from 'assets/icons/alert.svg';
import { ReactComponent as SecurePayment } from 'assets/storefront-icons/secure-payment.svg';
import StripeLogo from 'assets/images/stripe-logo.png';
// Utils
import { CurrencyFormatByISO } from 'utils/price';
import { cardPay } from 'utils/storefront/PaymentMethods/CardPay';
// Components
import CountrySelect from 'components/StorefrontComponents/CountrySelect';
import Modal from 'components/Modal';
import HeaderModal from 'components/StorefrontComponents/HeaderModal';
// Styles
import classes from './StripeCardFormModal.module.scss';

interface CardComplete {
  cardNumber: boolean;
  cardExpiry: boolean;
  cardCvc: boolean;
}

interface ErrorMsg {
  cardNumber: string | undefined;
  cardExpiry: string | undefined;
  cardCvc: string | undefined;
}

interface Props {
  translation: TranslationStorefrontModel['checkout']['card_form'];
  handleSuccess: () => void;
  handleError: (error: StripeError | undefined | unknown) => void;
  handleLoading: () => void;
  handleNotificationError: (typeNotification: NotificationEnum) => void;
  clientSecret?: string;
  active: boolean;
  setActive: () => void;
  total: number;
}

const CardForm: React.FC<Props> = ({
  translation,
  handleSuccess,
  handleError,
  handleLoading,
  clientSecret,
  active,
  setActive,
  total,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { geoInfo } = useTypeSelector(({ storefront }) => storefront.app);
  const { data: invoice } = useTypeSelector((state) => state.invoice);

  const defaultCountryBrowser: { label: string; value: string } | undefined = useMemo(
    () => CountriesList.find(
      (item) => item.label
      === (invoice?.contact?.address?.country || geoInfo?.countryName),
    ),
    [geoInfo?.countryName, invoice?.contact?.address?.country],
  );

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { isValid, isDirty },
  } = useForm({ mode: 'onChange' });

  const [errorMsg, setErrorMsg] = useState<ErrorMsg>({
    cardNumber: undefined,
    cardExpiry: undefined,
    cardCvc: undefined,
  });

  const [cardComplete, setCardComplete] = useState<CardComplete>({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  const disabled = useMemo(
    () => Object.values(errorMsg).some((value) => value !== undefined)
      || Object.values(cardComplete).some((value) => value !== true),
    [errorMsg, cardComplete],
  );

  const formatCurrencyByISO = useMemo(
    () => CurrencyFormatByISO(invoice?.organisation?.address.currencyISO),
    [invoice?.organisation?.address.currencyISO],
  );

  const cardStyles = useMemo(
    () => ({
      base: {
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'Visuelt Pro',
        lineHeight: '20px',
        color: 'rgba(25, 28, 35, 1)',
        minWidth: '280px',

        ':-webkit-autofill': {
          color: 'rgba(25, 28, 35, 0.4)',
        },

        '::placeholder': {
          color: 'rgba(25, 28, 35, 0.4)',
        },
      },

      invalid: {
        color: 'rgba(25, 28, 35, 0.4)',
      },
    }),
    [],
  );

  const cardErrorHandler = useCallback(
    (
      event:
        | StripeCardNumberElementChangeEvent
        | StripeCardExpiryElementChangeEvent
        | StripeCardCvcElementChangeEvent,
    ) => {
      if (event.error) {
        setErrorMsg({
          ...errorMsg,
          [event.elementType]: event.error.message,
        });
      } else {
        setCardComplete({
          ...cardComplete,
          [event.elementType]: event.complete,
        });

        setErrorMsg({
          ...errorMsg,
          [event.elementType]: undefined,
        });
      }
    },
    [cardComplete, errorMsg],
  );

  const onSubmit = async (data: {
    name: string;
    country: {
      label: string;
      value: string;
    };
    zipCode: string;
  }) => {
    handleLoading();

    try {
      if (
        !stripe
        || !elements
        || !clientSecret
        || !data.name
        || !data.country
      ) {
        handleError('err');
        return;
      }

      const cardNumber = elements.getElement(CardNumberElement);

      if (!cardNumber) {
        handleError('err');
        return;
      }

      const { error, paymentIntent } = await cardPay(
        clientSecret,
        stripe,
        cardNumber,
        data.name,
        data.country,
        data.zipCode,
      );

      if (error) {
        handleError(error);
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        handleSuccess();
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Modal
      active={active}
      setActive={setActive}
      className={classes.card_form_modal}
    >
      <div className={classes.stripe__cardform}>
        <HeaderModal onClick={setActive} className={classes.header} />
        <div className={classes.main}>
          <div className={classes.title}>
            <p>
              {`${translation.title_mobile} ${formatCurrencyByISO(total)}`}
            </p>
            <div>
              {PaymentsOptions.find(
                (option) => option.name === PayMethodsEnum.CARD,
              )?.logos?.map((logo) => (
                <img src={logo} alt={logo} key={logo} />
              ))}
            </div>
          </div>
          <form
            className={classes.stripe__cardform_form}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={classes.inputs}>
              <label htmlFor="name" className={classes.stripe__cardform_input}>
                <span>{translation.name_on_card_label}</span>
                <input
                  {...register('name', { required: true, minLength: 2 })}
                  placeholder={translation.name_on_card_placeholder}
                  defaultValue={`${invoice?.contact?.firstName} ${invoice?.contact?.lastName}`}
                />
              </label>
              <label className={classes.stripe__cardform_input}>
                <span>{translation.card_number_label}</span>
                <div className={classes.stripe__input}>
                  <CardNumberElement
                    options={{ style: cardStyles }}
                    onChange={cardErrorHandler}
                  />
                </div>
                {errorMsg.cardNumber && (
                  <small>
                    {errorMsg.cardNumber}
                    <AlertIcon />
                  </small>
                )}
              </label>
              <div className={classes.stripe__cardform_form_group}>
                <label className={classes.stripe__cardform_input}>
                  <span>{translation.card_expiration_label}</span>
                  <div className={classes.stripe__input}>
                    <CardExpiryElement
                      onChange={cardErrorHandler}
                      options={{
                        placeholder: translation.expiry_card_placeholder,
                        style: cardStyles,
                      }}
                    />
                  </div>
                  {errorMsg.cardExpiry && (
                    <small>
                      {errorMsg.cardExpiry}
                      <AlertIcon />
                    </small>
                  )}
                </label>
                <label className={classes.stripe__cardform_input}>
                  <span>{translation.card_cvv_label}</span>
                  <div className={classes.stripe__input}>
                    <CardCvcElement
                      onChange={cardErrorHandler}
                      options={{
                        placeholder: translation.cvc,
                        style: cardStyles,
                      }}
                    />
                  </div>
                  {errorMsg.cardCvc && (
                    <small>
                      {errorMsg.cardCvc}
                      <AlertIcon />
                    </small>
                  )}
                </label>
              </div>
              <div className={classes.stripe__cardform_form_group}>
                <label
                  htmlFor="name"
                  className={classes.stripe__cardform_input}
                >
                  <span>{translation.post_code_label}</span>
                  <input
                    {...register('zipCode', { required: true, minLength: 2 })}
                    placeholder={translation.post_code}
                    defaultValue={invoice?.contact?.address?.postalCode}
                  />
                </label>
                <div className={classes.county_select}>
                  <CountrySelect
                    control={control}
                    setValue={setValue}
                    defaultCountry={defaultCountryBrowser}
                    menuWidth="100%"
                  />
                </div>
              </div>
            </div>
            <div className={classes.stripe__cardform_footer}>
              <div className={classes.stripe__cardform_footer_left}>
                <SecurePayment />
                <div>
                  <img src={StripeLogo} alt="stripe" />
                  <span>{translation.description}</span>
                </div>
              </div>
              <button
                type="submit"
                disabled={disabled && (!isValid || !isDirty)}
              >
                {translation.pay_btn_label}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CardForm;
