import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Actions
import {
  getEstimateData,
  updateUnexpiredEstimate,
} from 'store/estimate/estimateActions';
// Types
import { TransactionStatus } from 'types/globalTypes';
import { EstimateModal } from 'store/estimate/estimateTypes';
// Components
import DocumentsGetApp from 'components/Documents/GetApp';
import Contacts from 'components/Documents/Contacts';
import LoadingPage from 'pages/LoadingPage';
import DocumentsLayout from 'layouts/DocumentsLayout';
import Title from 'components/Documents/Title';
import Status from 'components/Documents/Status';
import ItemsTable from 'components/Documents/ItemsTable';
import Notes from 'components/Documents/Notes';
import ModalEstimate from './EstimateModal';
import PaymentTerms from './PaymentTerms';

interface IParams {
  publicId: string;
  id: string;
}

const Invoice: React.FC = () => {
  const { id, publicId } = useParams<IParams>();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [type, setType] = useState<EstimateModal>(EstimateModal.accept);
  const { loading, data } = useTypeSelector((state) => state.estimate);
  const translations = useTranslationsStorefront();

  const handleModal = useCallback(() => {
    setModal((prev) => !prev);
  }, []);

  const handleTypeModal = useCallback(
    (t: EstimateModal) => {
      handleModal();
      setType(t);
    },
    [handleModal],
  );

  const handleButton = useCallback(
    (status: TransactionStatus) => {
      if (!data) {
        return;
      }

      handleModal();
      dispatch(updateUnexpiredEstimate(id, status, data, publicId));
    },
    [data, dispatch, handleModal, id, publicId],
  );

  useEffect(() => {
    if (!data) {
      dispatch(getEstimateData(id, publicId));
    }
  }, [data, dispatch, id, publicId]);

  if (!data || loading) {
    return <LoadingPage />;
  }

  return (
    <DocumentsLayout
      data={{
        organisation: data.organisation,
      }}
    >
      <Title name={translations.documents.estimate.estimate} number={data.details.reference} />
      <Status
        status={data.details.status}
        date={data.termsPayment?.date}
        dueDate={data?.details.dueDate}
      />
      <Contacts
        organisation={data.organisation}
        contact={data.contact}
        translations={translations.documents.contacts}
        type={data.details.type}
      />
      <ItemsTable items={data.items} price={data.price} />
      <PaymentTerms
        status={data.details.status}
        handleType={handleTypeModal}
        updateDate={data.termsPayment.updatedAt}
      />
      <Notes
        title={translations.documents.notes}
        notes={data.termsPayment.notes}
      />
      <DocumentsGetApp
        type={data.details.type}
        name={data.contact.fullName}
        phone={data.contact.mobileNumber}
        text={translations.documents.estimate.get_app_text}
      />
      <ModalEstimate
        active={modal}
        setActive={handleModal}
        type={type}
        handleButton={handleButton}
        organisationName={data.organisation.name}
      />
    </DocumentsLayout>
  );
};

export default Invoice;
