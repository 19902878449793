import SettingsPage from 'components/DashboardComponents/SettingsPage';
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import LoadingPage from 'pages/LoadingPage';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
// Styles
import classes from './OrganisationsDetails.module.scss';

const OrganisationsDetails: React.FC = () => {
  const { user } = useTypeSelector(({ auth }) => auth);
  const history = useHistory();
  const translations = useTranslations();
  const tsOrganisationDetails = translations.organization_details;

  const profileDetails = useMemo(() => [
    {
      label: tsOrganisationDetails.operation_in,
      name: `${user?.currentOrganisation?.address?.country}`,
    },
    {
      label: tsOrganisationDetails.currency,
      name: `${user?.currentOrganisation?.address?.currencySymbol}`,
    },
    {
      label: tsOrganisationDetails.business_phone_number,
      name: `${user?.currentOrganisation?.address?.idd} ${user?.currentOrganisation?.phoneNumber}`,
    },
    {
      label: tsOrganisationDetails.business_email,
      name: user?.currentOrganisation?.email,
    },
    {
      label: tsOrganisationDetails.business_address,
      name: `
      ${user?.currentOrganisation?.address?.line1 ?? ''}
      ${user?.currentOrganisation?.address?.line2 ?? ''}
      ${user?.currentOrganisation?.address?.city ?? ''}
      ${user?.currentOrganisation?.address?.postalCode ?? ''}
      ${user?.currentOrganisation?.address?.country}
      `,
    },
  ], [user, tsOrganisationDetails]);

  if (!user?.currentOrganisation) {
    return (
      <LoadingPage />
    );
  }

  return (
    <SettingsPage
      handleClose={() => history.goBack()}
      title={user?.currentOrganisation?.name}
    >
      <div className={classes.profile__details}>
        {profileDetails.map((detail) => (
          <div className={classes.setting_box} key={detail.label}>
            <label className={classes.setting_box_label}>
              {detail.label}
            </label>
            <p className={classes.setting_box_name}>
              {detail.name}
            </p>
          </div>
        ))}
      </div>
    </SettingsPage>
  );
};

export default OrganisationsDetails;
