import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Stripe } from '@stripe/stripe-js';
import { FieldValues } from 'react-hook-form';
import { Elements } from '@stripe/react-stripe-js';
// Constants
import { PayMethodsEnum } from 'constants/paymentsMethods';
// Actions
import { checkout, checkoutSetOrder } from 'store/storefront/checkout/checkoutActions';
// Hooks
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useTypeSelector } from 'hooks/useTypeSelector';
// Types
import { TranslationStorefrontModel } from 'types/TranslationStorefrontModel';
// Utils
import { IPaymentMethods } from 'utils/storefront/PaymentFunctions';
// Components
import Modal from 'components/Modal';
import FooterPaymentModal from 'components/StorefrontComponents/Checkout/FooterPaymentModal';
import HeaderModal from 'components/StorefrontComponents/HeaderModal';
import PaymentTitle from 'components/StorefrontComponents/PaymentTitle';
import { useStorefrontRoutes } from 'hooks/useStorefrontRoutes';
import { LoadingEnum } from 'store/storefront/checkout/checkoutTypes';
import { dateToDue } from 'utils/dates';
import { isMobile } from 'react-device-detect';
import Slider from 'react-slick';
import PaymentLaterForm from './PaymentLaterForm';
import PaymentLaterOption from './PaymentLaterOption/PaymentLaterOption';
// Styles
import classes from './PaymentLaterOptionsModal.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import StripeConfirm from './StripeConfirm';

interface Props {
  setActive: () => void;
  active: boolean;
  clientSecret: string;
  allPayMethods: PayMethodsEnum[]
  payLaterOptions: IPaymentMethods[]
  setClientSecret: React.Dispatch<React.SetStateAction<string>>

}

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 2.2,
        slidesToScroll: 1,
      },
    },
  ],
};

type TPaymentsMethod = keyof TranslationStorefrontModel['checkout']['payment_page']['payment_methods'];

const PaymentLaterOptionsModal: React.FC<Props> = ({
  active, setActive, clientSecret, payLaterOptions, setClientSecret,
}) => {
  const { id } = useParams<{ id: string, orderId: string }>();
  const { fulfillment, items, order } = useTypeSelector(({ storefront }) => storefront.checkout);
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);
  const { goToOrder } = useStorefrontRoutes();
  const sliderRef = useRef<Slider>(null);
  const location = useLocation();
  const dispatch = useDispatch();

  const translations = useTranslationsStorefront();
  const paymentsMethodsTs = translations.checkout.payment_page.payment_methods;

  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);

  const [payLaterOption, setPayLaterOption] = useState<PayMethodsEnum | null>(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [dataForm, setDataForm] = useState<FieldValues | null>();
  const [pay, setPay] = useState<boolean>(false);
  const currencyIso = currentSalesChannel?.address.currencyISO || 'EUR';

  const handleClick = useCallback(async () => {
    dispatch(checkoutSetOrder({
      ...order,
      loading: LoadingEnum.SET_ORDER,
    }));

    if (stripePromise) {
      setPay(true);
    }

    if (currentSalesChannel
      && payLaterOption
      && (!stripePromise || payLaterOption === PayMethodsEnum.PAY_LATER)) {
      const data = await checkout(
        id,
        payLaterOption === PayMethodsEnum.PAY_LATER ? 'LATER' : 'INSTANT',
        items,
        fulfillment,
        currentSalesChannel,
        'STRIPE',
        payLaterOption === PayMethodsEnum.PAY_LATER
          ? [PayMethodsEnum.CARD]
          : [PayMethodsEnum.AFTERPAY_CLEARPAY, PayMethodsEnum.KLARNA, PayMethodsEnum.CARD],
      );

      if (!data) {
        dispatch(checkoutSetOrder({
          ...order,
          loading: null,
          error: translations.server_error,
        }));
        return;
      }

      if (data && payLaterOption === PayMethodsEnum.PAY_LATER) {
        goToOrder(id, data.id, location);
      }

      setPay(true);
      localStorage.setItem(`leja-${id}-order`, data.id);
      setClientSecret(data.payment.client_secret);
      dispatch(checkoutSetOrder({
        loading: LoadingEnum.SET_ORDER,
        error: undefined,
        isPayed: false,
        id: data.id,
        stripe: {
          stripeAccount: data.payment.stripeAccount,
          key: data.payment.key,
        },
      }));
    }
  }, [
    currentSalesChannel,
    dispatch,
    fulfillment,
    goToOrder,
    id,
    items,
    location, order, payLaterOption, setClientSecret, stripePromise, translations.server_error]);

  const iStripe = useCallback(
    async () => {
      if (order.stripe?.key && order.stripe?.stripeAccount) {
        setStripePromise(await loadStripe(order.stripe.key, {
          stripeAccount: order.stripe.stripeAccount,
        }));
      } else {
        dispatch(checkoutSetOrder({
          ...order,
          loading: null,
          error: 'Server error',
        }));
      }
    }, [dispatch, order],
  );

  useEffect(() => {
    if (order.stripe) {
      iStripe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.stripe]);

  return (
    <Modal
      active={active}
      setActive={setActive}
      className={classes.payment_later_options_modal}
    >
      <div className={classes.root}>
        <div>
          <HeaderModal onClick={setActive} />
          <PaymentTitle currencyIso={currencyIso} value={fulfillment.amount.total} />
          <div className={classes.subtitle}>
            {translations.checkout.payment_modal.subtitle}
          </div>
          <div className={classes.pay_later_options}>
            <div className={classes.pay_later_options_title}>
              {translations.checkout.pay_later_modal.title}
            </div>
            {isMobile
              ? (
                <Slider {...settings} ref={sliderRef}>
                  {payLaterOptions.map((option) => (
                    <PaymentLaterOption
                      key={option.name}
                      check={option.name === payLaterOption}
                      onClick={() => setPayLaterOption(option.name)}
                    >
                      {option.name === PayMethodsEnum.PAY_LATER
                        ? (
                          <div className={classes.pay_later_option_pay_later}>
                            <p className={classes.pay_later_option_pay_later_title}>
                              {translations.checkout.pay_later_modal.pay_later_invoice.title}
                            </p>
                            <div className={classes.pay_later_option_pay_later_due_title}>
                              {translations.checkout.pay_later_modal.pay_later_invoice.term}
                              <p className={classes.pay_later_option_pay_later_due_value}>
                                {dateToDue(currentSalesChannel?.checkout.payLater)}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className={classes.pay_later_option}>
                            {option.logos.map((logo) => (
                              <img src={logo} alt={logo} key={logo} />
                            ))}
                            <p>
                              {paymentsMethodsTs[option.name as TPaymentsMethod].name}
                            </p>
                            <p>
                              {paymentsMethodsTs[option.name as TPaymentsMethod].description}
                            </p>
                          </div>
                        )}
                    </PaymentLaterOption>
                  ))}
                </Slider>
              ) : (
                <div className={classes.pay_later_options_list}>
                  {payLaterOptions.map((option) => (
                    <PaymentLaterOption
                      key={option.name}
                      check={option.name === payLaterOption}
                      onClick={() => setPayLaterOption(option.name)}
                    >
                      {option.name === PayMethodsEnum.PAY_LATER
                        ? (
                          <div className={classes.pay_later_option_pay_later}>
                            <p className={classes.pay_later_option_pay_later_title}>
                              {translations.checkout.pay_later_modal.pay_later_invoice.title}
                            </p>
                            <div className={classes.pay_later_option_pay_later_due_title}>
                              {translations.checkout.pay_later_modal.pay_later_invoice.term}
                              <p className={classes.pay_later_option_pay_later_due_value}>
                                {dateToDue(currentSalesChannel?.checkout.payLater)}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className={classes.pay_later_option}>
                            {option.logos.map((logo) => (
                              <img src={logo} alt={logo} key={logo} />
                            ))}
                            <p>
                              {paymentsMethodsTs[option.name as TPaymentsMethod].name}
                            </p>
                            <p>
                              {paymentsMethodsTs[option.name as TPaymentsMethod].description}
                            </p>
                          </div>
                        )}
                    </PaymentLaterOption>
                  ))}
                </div>
              )}
          </div>
          <PaymentLaterForm type={payLaterOption} setDisabled={setDisabled} setData={setDataForm} />
          {payLaterOption && payLaterOption !== PayMethodsEnum.PAY_LATER && (
            <div className={classes.description}>
              {translations.checkout.description_bank_redirect_pay}
            </div>
          )}
        </div>
        {payLaterOption ? (
          <FooterPaymentModal
            disabled={!disabled || !payLaterOption}
            isPayLaterInvoice={payLaterOption === PayMethodsEnum.PAY_LATER}
            onClick={handleClick}
          />
        ) : (
          <FooterPaymentModal onClick={isMobile ? handleClick : undefined} disabled />
        )}
      </div>
      {stripePromise && clientSecret && payLaterOption && pay && (
        <Elements stripe={stripePromise}>
          <StripeConfirm
            type={payLaterOption}
            values={dataForm}
            setPay={setPay}
            clientSecret={clientSecret}
          />
        </Elements>
      )}
    </Modal>
  );
};

export default PaymentLaterOptionsModal;
