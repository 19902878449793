/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState, useEffect, useRef, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useUser } from 'hooks/useUser';
// d3
import {
  select,
  scaleBand,
  scaleLinear,
  stack,
  max,
  axisBottom,
} from 'd3';
// Actions
import { changeAverageMonth } from 'store/dashboard/report/reportActions';
import { directorySetAverageMonth } from 'store/dashboard/directory/directoryActions';
// Types
import { DataChart } from 'store/dashboard/report/reportTypes';
import { DirectoryType } from 'store/dashboard/directory/directoryTypes';
// Utils
import { capitalizeFirstLetter } from 'utils/strings';
import { yAixDraw } from 'utils/functions';
import { CurrencyFormatByISO } from 'utils/price';
// Classes
import classes from './Graph.module.scss';

interface Props {
  datasets: DataChart[];
  color?: string;
  name: string;
  tooltipClassName?: string;
  isIso?: boolean;
  type?: DirectoryType;
  heightChart?: number
}

interface Tooltip {
  [key: string]: number;
}

export const Graph: React.FC<Props> = ({
  datasets, color = 'rgba(25, 28, 35, 0.8)', name, isIso, tooltipClassName, type, heightChart,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const xAxisRef = useRef<SVGSVGElement>(null);
  const yAxisRef = useRef<SVGSVGElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [showToolTip, setShowToolTip] = useState(false);
  const [check, setCheck] = useState(false);
  const [toolTip, setToolTip] = useState<Tooltip | null>(null);
  const { isMobile } = useMobileScreen();
  const user = useUser();
  const getTotal = useMemo(
    () => datasets.map((item) => item.value).reduce((a, b) => a + b, 0),
    [datasets],
  );

  const formatCurrencyByISO = useCallback(CurrencyFormatByISO(
    user?.currentOrganisation?.address?.currencyISO,
  ), [user?.currentOrganisation?.address?.currencyISO]);

  const handleDirectoryAverage = useCallback(
    (dataset, selectedMonth) => {
      if (type) {
        dispatch(directorySetAverageMonth({ datasets: dataset, selectedMonth, type }));
      }
    },
    [dispatch, type],
  );

  useEffect(() => {
    if (!svgRef.current || !wrapperRef.current) {
      return;
    }

    const svg = select(svgRef.current);
    const { width, height } = wrapperRef.current.getBoundingClientRect();
    const stackGenerator = stack().keys(['value']);
    const layers = stackGenerator(datasets as Iterable<{ [key: string]: number; }>);
    const getMax = max(layers, (layer) => max(layer, (sequence) => sequence.data.value))
      || 0;
    const extent = [0, getMax];
    const yScale = scaleLinear().domain(extent).rangeRound([height, 50]);
    const x0Scale = scaleBand()
      .domain(datasets.map((d) => (d.month)))
      .range([0, width])
      .padding(0.7);
    const xAxis = axisBottom(x0Scale).tickFormat((d) => (isMobile ? d[0] : d.substring(0, 3)));

    const x1Scale = scaleBand()
      .rangeRound([0, 10]);

    svg
      .on('click', () => {
        if (!check) {
          setShowToolTip(false);
          svg
            .selectAll('.layer')
            .data(layers)
            .join('g')
            .attr('class', 'layer')
            .selectAll('rect')
            .data((layer) => layer)
            .join('rect')
            .attr('fill', (seq) => (seq.data.value === 0 ? '#F8F8F8' : color))
            .attr(
              'x',
              (sequence) => x0Scale(`${sequence?.data?.month}`) as number + 10,
            )
            .attr('width', x1Scale.bandwidth())
            .attr('style', 'outline: none;');
        }
        setCheck(false);
      });

    if (xAxisRef.current) {
      select(xAxisRef.current)
        .call(xAxis)
        .call((g) => g.select('.domain').remove())
        .attr('style', `font-size: ${isMobile ? '9px' : '10px'}; color: rgba(107, 105, 116, 1); font-weight:${isMobile ? 700 : 500}; line-height: 20px;`)
        .attr('transform', `translate(7, ${height - 20})`);
    }

    if (yAxisRef.current) {
      select(yAxisRef.current)
        .attr('style', 'font-size: 12px; color: rgba(203, 198, 215, 1); font-weight: 600; line-height:18px')
        .attr('transform', 'translate(20,-45)')
        .call(yAixDraw(yScale))
        .call((g) => g.select('.domain').remove());
    }

    svg
      .selectAll('.layer')
      .data(layers)
      .join('g')
      .attr('class', 'layer')
      .selectAll('rect')
      .data((layer) => layer)
      .join('rect')
      .attr('fill', (seq) => (seq.data.value === 0 ? '#F8F8F8' : color))
      .attr(
        'x',
        (sequence) => x0Scale(`${sequence?.data?.month}`) as number + 10,
      )
      .attr('width', x1Scale.bandwidth())
      .on('click', (e, seq) => {
        setShowToolTip(true);
        setCheck(true);
        handleDirectoryAverage(datasets, seq.data);
        dispatch(changeAverageMonth({
          selectedMonth: seq.data,
          datasets,
        }));
        setToolTip(seq.data);
        svg
          .selectAll('.layer')
          .data(layers)
          .join('g')
          .attr('class', 'layer')
          .selectAll('rect')
          .data((layer) => layer)
          .join('rect')
          .attr('fill', (s) => (s.data.value === 0 ? '#F8F8F8' : 'rgba(25, 28, 35, 1)'))
          .attr('style', (s) => {
            if (s.data.month === seq.data.month) {
              return 'outline: 4px solid rgba(255, 199, 38, 0.4); border-radius: 10px; transition: all 0.1s ease-out;';
            }

            return '';
          })
          .attr(
            'x',
            (sequence) => x0Scale(`${sequence?.data?.month}`) as number + 10,
          )
          .attr('width', x1Scale.bandwidth());
      })
      .transition()
      .duration(800)
      .attr('y', (sequence) => (sequence.data.value === 0 ? height - 45 : yScale(sequence.data.value) - 41))
      .attr('height', (sequence) => (
        sequence.data.value === 0 ? 5 : Math.abs(height - yScale(sequence.data.value))))
      .attr('rx', 4.75)
      .delay((d, i) => i * 100);

    svg.append('rect')
      .attr('x', 0)
      .attr('y', height - 20)
      .attr('height', '0.01px')
      .attr('width', width)
      .style('stroke', '#EEEAFC')
      .style('fill', 'none');
  }, [
    datasets,
    isMobile,
    showToolTip,
    check,
    color,
    handleDirectoryAverage,
    dispatch,
  ]);

  return (
    <div ref={wrapperRef} className={classes.graph_wrapper} style={{ height: heightChart || 139 }}>
      {showToolTip && (
        <div className={tooltipClassName || classes.tooltip}>
          <h4 className={classes.tooltip_month}>
            {`${toolTip?.month}`.substring(0, 3)}:
          </h4>
          <div className={classes.tooltip_container}>
            <p className={classes.tooltip_container_name}>
              {capitalizeFirstLetter(name)}
            </p>
            <p className={classes.tooltip_container_value} style={{ color }}>
              {isIso ? formatCurrencyByISO(toolTip?.value) : toolTip?.value}
            </p>
          </div>
        </div>
      )}
      <svg ref={svgRef} style={{ width: '100%', height: '100%' }}>
        <g className="x-axis" ref={xAxisRef} />
        {getTotal && !isMobile && <g className="y-axis" ref={yAxisRef} />}
      </svg>
    </div>
  );
};
