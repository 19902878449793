/* eslint-disable no-console */
import axios from 'axios';
import { AppThunk } from 'store';
import { ProductItem } from 'store/dashboard/catalog/items/itemsTypes';
import { ApiOrgPublicId } from 'services/api';
import { URLSearchParams } from 'url';
import {
  SHOP_SET,
  SHOP_SET_CATEGORIES,
  SHOP_SET_PRODUCTS,
  SHOP_ADD_TO_BAG,
  SHOP_SET_TO_SEARCH,
  SHOP_SET_SEARCH_KEYWORD,
  SHOP_SET_SEARCH_LOADING,
  SHOP_SET_SEARCH_DATA,
  SHOP_SET_LOADING,
  SHOP_SET_ERROR,
  Shop,
  ShopActions,
  ShopCategoriesOrProducts,
  ShopCategory,
  SHOP_SET_FILTER,
  Filter,
  SHOP_GET_FILTER,
  SHOP_CLEAN_FILTER,
  SHOP_SET_SORT,
  ProductSort,
  SHOP_SET_TO_FILTER,
  SHOP_SET_SALES_CHANNEL,
  SHOP_SET_TO_PRODUCT_DETAILS,
  SHOP_SET_TO_CONFIRM,
  CartItemType,
  SHOP_REMOVE_PRODUCT,
  SHOP_SET_PARAMS,
  IParams,
  SalesChannel,
  SHOP_SET_CURRENT_SALES_CHANNEL,
  SHOP_SET_PRODUCT,
} from './shopTypes';

const SetShopAction = (payload: Shop): ShopActions => ({
  type: SHOP_SET,
  payload,
});

const SetShopCategoriesAction = (
  payload: ShopCategoriesOrProducts<ShopCategory[]>,
): ShopActions => ({
  type: SHOP_SET_CATEGORIES,
  payload,
});

export const SetShopProductsAction = (
  payload: ShopCategoriesOrProducts<ProductItem[]>,
): ShopActions => ({
  type: SHOP_SET_PRODUCTS,
  payload,
});

export const SetAddToBagAction = (payload: (ProductItem & { quantity: number })[]): ShopActions => (
  {
    type: SHOP_ADD_TO_BAG,
    payload,
  });

export const SetToSearchAction = (payload: boolean): ShopActions => ({
  type: SHOP_SET_TO_SEARCH,
  payload,
});

export const SetSearchKeywordAction = (payload: string): ShopActions => ({
  type: SHOP_SET_SEARCH_KEYWORD,
  payload,
});

export const SetSearchLoadingAction = (payload: boolean): ShopActions => ({
  type: SHOP_SET_SEARCH_LOADING,
  payload,
});

export const SetSearchDataAction = (payload: ProductItem[]): ShopActions => ({
  type: SHOP_SET_SEARCH_DATA,
  payload,
});

export const SetShopLoadingAction = (payload: boolean): ShopActions => ({
  type: SHOP_SET_LOADING,
  payload,
});

const SetShopErrorAction = (payload: boolean): ShopActions => ({
  type: SHOP_SET_ERROR,
  payload,
});

export const SetShopFilterAction = (payload: Filter): ShopActions => ({
  type: SHOP_SET_FILTER,
  payload,
});

export const GetShopFilterAction = (payload: string[]): ShopActions => ({
  type: SHOP_GET_FILTER,
  payload,
});

export const CleanAllShopFilterAction = (): ShopActions => ({
  type: SHOP_CLEAN_FILTER,
});

export const SetShopSortAction = (payload: ProductSort): ShopActions => ({
  type: SHOP_SET_SORT,
  payload,
});

export const SetToFilterAction = (payload: boolean): ShopActions => ({
  type: SHOP_SET_TO_FILTER,
  payload,
});

export const SetSalesChannelAction = (payload: SalesChannel[]): ShopActions => ({
  type: SHOP_SET_SALES_CHANNEL,
  payload,
});

export const SetShopParamsAction = (payload: IParams): ShopActions => ({
  type: SHOP_SET_PARAMS,
  payload,
});

export const SetProductToDetailsAction = (payload: { show: boolean, productId: string })
  : ShopActions => (
  {
    type: SHOP_SET_TO_PRODUCT_DETAILS,
    payload,
  });

export const setCurrentSalesChannelAction = (payload: SalesChannel): ShopActions => (
  {
    type: SHOP_SET_CURRENT_SALES_CHANNEL,
    payload,
  }
);

export const SetToConfirmAction = (payload: {
  product?: CartItemType,
  show: boolean
}): ShopActions => ({
  type: SHOP_SET_TO_CONFIRM,
  payload,
});

export const RemoveProductAction = (payload: CartItemType): ShopActions => ({
  type: SHOP_REMOVE_PRODUCT,
  payload,
});

export const setProductAction = (payload: ProductItem | null): ShopActions => ({
  type: SHOP_SET_PRODUCT,
  payload,
});

export const getShop = (shopId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(SetShopLoadingAction(true));

    const data = await ApiOrgPublicId('/p/organisations', shopId);

    dispatch(SetShopAction({
      ...data,
    }));
    dispatch(SetShopLoadingAction(false));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      dispatch(SetShopErrorAction(true));
    }

    console.log(error);
    dispatch(SetShopLoadingAction(false));
  }
};

export const getCategories = (shopId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(SetShopCategoriesAction({
      data: [],
      loading: true,
      error: false,
    }));

    const data = await ApiOrgPublicId<ProductItem[]>('/p/categories', shopId);

    dispatch(SetShopCategoriesAction({
      data,
      loading: false,
      error: false,
    }));
  } catch (error) {
    console.log(error);
    dispatch(SetShopCategoriesAction({
      data: [],
      loading: false,
      error: true,
    }));
  }
};

export const getProducts = (
  shopId: string,
  query: URLSearchParams,
): AppThunk => async (dispatch) => {
  try {
    dispatch(SetShopProductsAction({
      data: [],
      loading: true,
      error: false,
      page: query.get('page'),
    }));

    if (query.has('search')) {
      query.set('page', '0');
    }

    const items = await ApiOrgPublicId(`/p/items?include=stock&${query}`, shopId);

    if (items.length >= 10) {
      dispatch(SetShopProductsAction({
        data: items,
        searchResult: query.has('search') ? items.length : null,
        loading: false,
        error: false,
        hasNextPage: !query.has('search'),
        page: query.get('page'),
      }));
    } else {
      dispatch(SetShopProductsAction({
        data: items,
        loading: false,
        searchResult: query.has('search') ? items.length : null,
        error: false,
        hasNextPage: false,
        page: query.get('page'),
      }));
    }
  } catch (error) {
    console.log(error);
    dispatch(SetShopProductsAction({
      data: [],
      searchResult: false,
      loading: false,
      error: true,
      hasNextPage: false,
      page: query.get('page'),
    }));
  }
};

export const getProductVariations = (
  shopId: string,
  productId: string,
  product: ProductItem,
): AppThunk => async (dispatch) => {
  try {
    const data = await ApiOrgPublicId(`/p/items/${productId}/variants`, shopId);
    const updatedProductVariations = {
      ...product,
      variations: data,
    };
    dispatch(setProductAction(updatedProductVariations));
  } catch (error) {
    console.log(error);
  }
};

export const getSalesChannel = (
  shopId: string,
  scid: string | null,
  shop: Shop | Record<string, never>,
): AppThunk => async (dispatch) => {
  try {
    if (scid) {
      const findSalesChannel = await ApiOrgPublicId(`/p/locations/${scid}`, shopId);
      dispatch(setCurrentSalesChannelAction(findSalesChannel));
    } else {
      const defaultSalesChannel = await ApiOrgPublicId('/p/locations/default', shopId);
      if (!defaultSalesChannel.address) {
        defaultSalesChannel.address = {
          countryISO: shop.countryISO,
          currencyISO: shop.currencyISO,
        };
      } else {
        defaultSalesChannel.address.countryISO = shop.countryISO;
        defaultSalesChannel.address.currencyISO = shop.currencyISO;
      }
      dispatch(setCurrentSalesChannelAction(defaultSalesChannel));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getProduct = (
  shopId: string,
  productId: string,
): AppThunk => async (dispatch) => {
  try {
    const data = await ApiOrgPublicId(`/p/items/${productId}`, shopId);
    dispatch(setProductAction(data));
  } catch (err) {
    console.log(err);
  }
};
