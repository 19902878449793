import {
  PaymentIntentResult, Stripe, StripeCardElement, StripeCardNumberElement,
} from '@stripe/stripe-js';

export const cardPay = async (
  clientSecret: string,
  stripe: Stripe,
  card: StripeCardElement | StripeCardNumberElement | {
    token: string;
  },
  name: string,
  country: {
    label: string,
    value: string,
  },
  zipCode?: string,
): Promise<PaymentIntentResult> => {
  console.log(clientSecret, card, country);
  const paymentIntentResult = await stripe.confirmCardPayment(clientSecret, {
    payment_method: {
      card,
      billing_details: {
        name,
        address: {
          country: country.value,
          postal_code: zipCode,
        },
      },
    },
  });
  console.log('Payment result', paymentIntentResult);
  return paymentIntentResult;
};
