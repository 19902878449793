import React, { useCallback } from 'react';
// Paypal
import { useLocation, useParams } from 'react-router-dom';
// Constants
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Actions
import { makePaystackPayment } from 'store/invoice/invoiceActions';
// Types
import { InvoicePaymentLoadingEnum } from 'store/invoice/invoiceTypes';
// Styles
import PaystackLogo from 'assets/icons/card/paystack.svg';
import classes from './Paystack.module.scss';

const style = { layout: 'horizontal', color: 'gold' };

interface Props {
  total: number
  handleSuccess: () => void;
  handleLoading: (loading: InvoicePaymentLoadingEnum) => void;
  handleError: (error: unknown) => void;
}

const Paystack: React.FC<Props> = ({
  total, handleLoading, handleSuccess, handleError,
}) => {
  const translations = useTranslationsStorefront();
  const location = useLocation();
  const { data } = useTypeSelector((state) => state.invoice);
  const { id } = useParams<{ id: string }>();

  const handlePay = useCallback(
    async () => {
      const payment = await makePaystackPayment(total, id);
      window.location.href = payment.data.authorization_url;
    },
    [],
  );

  if (!['ZA', 'NG'].includes(data?.organisation.address.countryISO || 'US')) {
    return null;
  }

  return (
    <button type="button" className={classes.paypal} onClick={() => handlePay()}>
      <img src={PaystackLogo} alt={PaystackLogo} />
    </button>
  );
};

export default Paystack;
