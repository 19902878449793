import React from 'react';
import { Route } from 'react-router';
// Documents components
import Invoice from 'pages/Documents/Invoice';
import Bill from 'pages/Documents/Bill';
import Estimate from 'pages/Documents/Estimate';
import Quote from 'pages//Documents/Quote';
import PaymentLink from 'pages/Documents/PaymentLink';
import DocumentsRoute from 'router/DocumentsRoute';

export const DocumentRoutePath = {
  INVOICE: '/:publicId/d/invoices/:id',
  BILL: '/:publicId/d/bills/:id',
  ESTIMATE: '/:publicId/d/sales-orders/:id',
  QUOTE: '/:publicId/d/purchase-orders/:id',
  PAY_LINK: '/:publicId/pl/:id',
};

export const DocumentsRoutes = [
  <DocumentsRoute key="invoices" exact path={DocumentRoutePath.INVOICE} component={Invoice} />,
  <DocumentsRoute key="bills" exact path={DocumentRoutePath.BILL} component={Bill} />,
  <DocumentsRoute key="sales-orders" exact path={DocumentRoutePath.ESTIMATE} component={Estimate} />,
  <DocumentsRoute key="purchase-orders" exact path={DocumentRoutePath.QUOTE} component={Quote} />,
  <Route key="payment-link" exact path={DocumentRoutePath.PAY_LINK} component={PaymentLink} />,
];
