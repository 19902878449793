import React from 'react';
// Constants
import { PaymentsOptions, PayMethodsEnum } from 'constants/paymentsMethods';
// Utils
import { PaymentFunctions } from 'utils/storefront/PaymentFunctions';
// Components
import { InvoicePaymentLoadingEnum } from 'store/invoice/invoiceTypes';
import PayPal from './PayPal';
// Styles
import classes from './ExpressPayments.module.scss';
import ExpressPaymentButton from './ExpressPaymentButton';
import Paystack from './Paystack';

interface Props {
  handlePay: (paymentMethodTypes: PayMethodsEnum) => Promise<void>;
  handleError: (error: unknown) => void;
  handleLoading: (loading: InvoicePaymentLoadingEnum) => void;
  handleSuccess: () => void;
  checkSupportStripe: boolean;
  currencyIso: string;
  countryIso?: string;
  total: number;
}

const ExpressPayments: React.FC<Props> = ({
  handlePay,
  countryIso,
  total,
  handleError,
  handleLoading,
  handleSuccess,
  checkSupportStripe,
  currencyIso,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const applePay = (window as any).ApplePaySession;
  const getExpressPaymentOptions = PaymentFunctions.expressPayments(
    applePay,
    PaymentsOptions,
    countryIso,
    currencyIso,
  );

  return (
    <div className={classes.express_card_cards}>
      {getExpressPaymentOptions.map((expressOption) => (
        <ExpressPaymentButton
          key={expressOption.name}
          type={expressOption.name}
          expressOption={expressOption}
          handlePay={handlePay}
          checkSupportStripe={checkSupportStripe}
        />
      ))}
      <PayPal
        total={total}
        handleError={handleError}
        handleLoading={handleLoading}
        handleSuccess={handleSuccess}
      />
      <Paystack
        total={total}
        handleError={handleError}
        handleLoading={handleLoading}
        handleSuccess={handleSuccess}
      />
    </div>
  );
};

export default ExpressPayments;
