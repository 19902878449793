import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// Stripe
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { PaymentRequest, StripeError } from '@stripe/stripe-js';
// Hooks
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useQuery } from 'hooks/useQuery';
// Utils
import { PaymentFunctions } from 'utils/storefront/PaymentFunctions';
// Actions
import { checkoutSetOrder, onError, onSuccess } from 'store/storefront/checkout/checkoutActions';
import { setNotificationAction } from 'store/storefront/app/appActions';
// Types
import { LoadingEnum } from 'store/storefront/checkout/checkoutTypes';
import { NotificationEnum } from 'store/storefront/shop/shopTypes';
// Constants
import { PayMethodsEnum } from 'constants/paymentsMethods';
// Components
import Modal from 'components/Modal';
import CardFormModal from './CardFormModal';

interface Props {
  setActive: () => void;
  active: boolean;
  clientSecret?: string
  type?: PayMethodsEnum | null
}

const Payment: React.FC<Props> = ({
  active, setActive, clientSecret, type,
}) => {
  const query = useQuery();
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);

  const { fulfillment, order } = useTypeSelector(({ storefront }) => storefront.checkout);
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);
  const { notification } = useTypeSelector(({ storefront }) => storefront.app);

  const translations = useTranslationsStorefront();
  const dispatch = useDispatch();
  const history = useHistory();

  const currencyIso = currentSalesChannel?.address.currencyISO || 'EUR';
  const totalPrice = fulfillment.amount.total;
  const fullName = `${fulfillment.contact?.firstName} ${fulfillment.contact?.lastName}`;

  const handleSuccess = () => {
    dispatch(onSuccess(order));
    setActive();
  };

  const handleLoading = (loading: LoadingEnum | null) => {
    dispatch(checkoutSetOrder({
      ...order,
      loading,
    }));
  };

  const handleNotificationError = (typeNotification: NotificationEnum) => {
    dispatch(setNotificationAction(typeNotification));
  };

  const handleError = useCallback((error: StripeError | undefined | unknown) => {
    dispatch(onError(error, order));
  }, [dispatch, order]);

  useEffect(() => {
    const PaymentIntentClientSecret = query.get('payment_intent_client_secret');
    if (PaymentIntentClientSecret && stripe) {
      const fetchPaymentIntent = async () => {
        const { error, paymentIntent } = await stripe.retrievePaymentIntent(
          PaymentIntentClientSecret,
        );

        if (error) {
          dispatch(onError('err', order));
        }

        if (paymentIntent?.status === 'requires_payment_method') {
          dispatch(onError('err', order));
        }

        if (paymentIntent?.status === 'succeeded') {
          dispatch(onSuccess(order));
        }
      };
      fetchPaymentIntent();
      query.delete('payment_intent_client_secret');
      query.delete('payment_intent');

      history.replace({
        search: query.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, order, query, stripe]);

  useEffect(() => {
    if (!stripe || !clientSecret || order.error) {
      return;
    }

    if (type === PayMethodsEnum.GIROPAY) {
      return;
    }

    PaymentFunctions.handlePay(
      handleLoading,
      handleError,
      handleSuccess,
      handleNotificationError,
      stripe,
      clientSecret,
      setPaymentRequest,
      fullName,
      currentSalesChannel?.address?.countryISO || 'US',
      currencyIso,
      translations.checkout.price_details.total_label,
      totalPrice,
      type,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, type]);

  console.log('Type', type);
  if (type === PayMethodsEnum.CARD) {
    return (
      <CardFormModal
        active={!!active}
        setActive={setActive}
        clientSecret={clientSecret}
        translation={translations.checkout.card_form}
        handleSuccess={handleSuccess}
        handleNotificationError={handleNotificationError}
        handleError={handleError}
        handleLoading={handleLoading}
      />
    );
  }

  if ((type === PayMethodsEnum.APPLE || type === PayMethodsEnum.GOOGLE)
    && notification !== NotificationEnum.ERROR_NOT_WALLET) {
    return (
      <Modal active={!!active} setActive={setActive}>
        {paymentRequest && (
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        )}
      </Modal>
    );
  }
  return null;
};

export default Payment;
