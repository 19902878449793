import React, { useMemo } from 'react';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslations } from 'hooks/useTranslations';
import { useUser } from 'hooks/useUser';
import { useMobileScreen } from 'hooks/useMobileScreen';
// Types
import { DirectoryType } from 'store/dashboard/directory/directoryTypes';
// Utils
import { dateFromNow, formatReg } from 'utils/dates';
import { CurrencyFormatByISO } from 'utils/price';
import { makeValue } from 'utils/functions';
// Components
import Accordion from 'components/Accordion';
// Classes
import classes from './DirectoryDetailsSidebar.module.scss';

interface Props {
  handleOpenTransactions: (tabIndex: number) => void;
}

const DirectoryDetailsSidebar: React.FC<Props> = ({ handleOpenTransactions }) => {
  const user = useUser();
  const translations = useTranslations();
  const profileTranslation = translations.directory.details.profile;
  const { isMobile } = useMobileScreen();
  const { details } = useTypeSelector(({ directory }) => directory);
  const formatCurrencyByISO = CurrencyFormatByISO(user?.currentOrganisation?.address?.currencyISO || 'USD');

  const sidebarInfo = useMemo(() => {
    if (details.type === DirectoryType.customer) {
      return ([
        makeValue(profileTranslation.added, formatReg(details.profile?.createdAt)),
        makeValue(profileTranslation.location, details.profile?.address?.city || '-'),
        makeValue(profileTranslation.lifetime_transactions, `${details.profile?.transactions}`),
        makeValue(profileTranslation.lifetime_sales,
          formatCurrencyByISO(details.summary.customers.sum)),
        makeValue(profileTranslation.average_transaction,
          formatCurrencyByISO(details.averageMonth?.customers?.value)),
        makeValue(profileTranslation.average_sales,
          formatCurrencyByISO(details.averageMonth?.customers?.value)),
        makeValue(profileTranslation.average_order, `${details.profile?.ordersPerMonth}`),
        makeValue(profileTranslation.last_activity, dateFromNow(translations.directory.time, details.profile?.activity || '')),
      ]);
    }

    if (details.type === DirectoryType.supplier) {
      return ([
        makeValue(profileTranslation.added, formatReg(details.profile?.createdAt)),
        makeValue(profileTranslation.location, details.profile?.address?.city || '-'),
        makeValue(profileTranslation.lifetime_transactions, `${details.profile?.transactions}`),
        makeValue(profileTranslation.lifetime_expenses,
          formatCurrencyByISO(details.summary.suppliers.sum)),
        makeValue(profileTranslation.average_transaction,
          formatCurrencyByISO(details.averageMonth?.suppliers?.value)),
        makeValue(profileTranslation.average_expenses,
          formatCurrencyByISO(details.averageMonth?.suppliers?.value)),
        makeValue(profileTranslation.last_activity, dateFromNow(translations.directory.time, details.profile?.activity || '')),
      ]);
    }

    return ([
      makeValue(profileTranslation.added, formatReg(details.profile?.createdAt)),
      makeValue(profileTranslation.location, details.profile?.address?.city || '-'),
      makeValue(profileTranslation.lifetime_transactions, `${details.profile?.transactions}`),
      makeValue(profileTranslation.lifetime_sales,
        formatCurrencyByISO(details.summary.customers.sum)),
      makeValue(profileTranslation.lifetime_expenses,
        formatCurrencyByISO(details.summary.suppliers.sum)),
      makeValue(profileTranslation.average_transaction,
        formatCurrencyByISO((details.averageMonth?.suppliers?.value || 0)
          + (details.averageMonth?.customers?.value || 0))),
      makeValue(profileTranslation.average_sales,
        formatCurrencyByISO(details.averageMonth?.customers?.value)),
      makeValue(profileTranslation.average_expenses,
        formatCurrencyByISO(details.averageMonth?.suppliers?.value)),
      makeValue(profileTranslation.average_order, `${details.profile?.ordersPerMonth}`),
      makeValue(profileTranslation.last_activity, dateFromNow(translations.directory.time, details.profile?.activity || '')),
    ]);
  }, [profileTranslation, details, formatCurrencyByISO, translations]);

  if (isMobile) {
    return (
      <div className={classes.sidebar_wrapper}>
        <Accordion
          title={translations.directory.details.profile.title}
          className={classes.accordion_sidebar}
        >
          <div className={classes.sidebar}>
            <h2 className={classes.sidebar_title}>
              {profileTranslation.title}
            </h2>
            <div className={classes.sidebar_list}>
              {sidebarInfo.map((item) => item.value && (
                <div className={classes.sidebar_list_item} key={item.name}>
                  <p className={classes.sidebar_list_item_name}>
                    {item.name}
                  </p>
                  <p className={classes.sidebar_list_item_value}>
                    {item.value}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </Accordion>
        <button type="button" className={classes.sidebar_button} onClick={() => handleOpenTransactions(0)}>
          {profileTranslation.view_transactions_btn}
        </button>
      </div>
    );
  }

  return (
    <div className={classes.sidebar}>
      <h2 className={classes.sidebar_title}>
        {profileTranslation.title}
      </h2>
      <div className={classes.sidebar_list}>
        {sidebarInfo.map((item) => item.value && (
          <div className={classes.sidebar_list_item} key={item.name}>
            <p className={classes.sidebar_list_item_name}>
              {item.name}
            </p>
            <p className={classes.sidebar_list_item_value}>
              {item.value}
            </p>
          </div>
        ))}
      </div>
      <button type="button" className={classes.sidebar_button} onClick={() => handleOpenTransactions(0)}>
        {profileTranslation.view_transactions_btn}
      </button>
    </div>
  );
};

export default DirectoryDetailsSidebar;
