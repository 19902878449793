import { IFulfillmentDetails } from 'store/storefront/order/orderTypes';
import {
  AddressType,
  Fees, IPayments, ItemOption, Items, TaxesRes, TermsPaymentType, TransactionType,
} from 'types/globalTypes';

// Action Types
export const TRANSACTION_GET_DATA = 'TRANSACTION_GET_DATA';
export const TRANSACTION_LOADING = 'TRANSACTION_LOADING';
export const TRANSACTION_DOCUMENTS_GET_DATA = 'DOCUMENTS_GET_DATA';
export const TRANSACTION_SET_DETAIL_ITEM = 'TRANSACTION_SET_DETAIL_ITEM';
export const TRANSACTION_DOCUMENTS_MORE_GET_DATA = 'TRANSACTION_DOCUMENTS_MORE_GET_DATA';
export const TRANSACTION_MORE_PAGE = 'TRANSACTION_MORE_PAGE';
export const TRANSACTION_APP_SET_NOTIFICATION_TRANSACTION = 'TRANSACTION_APP_SET_NOTIFICATION_TRANSACTION';
export const TRANSACTION_RECORD_PAYMENT_LOADING = 'TRANSACTION_RECORD_PAYMENT_LOADING';
export const TRANSACTION_RECORD_PAYMENT_SUCCESS = 'TRANSACTION_RECORD_PAYMENT_SUCCESS';
export const TRANSACTION_REMINDER_LOADING = 'TRANSACTION_REMINDER_LOADING';
export const TRANSACTION_REMINDER_SUCCESS = 'TRANSACTION_REMINDER_SUCCESS';
export const TRANSACTION_ACCEPT_LOADING = 'TRANSACTION_ACCEPT_LOADING';
export const TRANSACTION_ACCEPT_SUCCESS = 'TRANSACTION_ACCEPT_SUCCESS';

export type TransactionState = {
  loading: boolean;
  documents: TransactionType[];
  detailItem: TransactionType | null;
  morePage: boolean;
  notification: string | null;
  recordPayment: {
    loading: boolean;
    isSuccess: number | null;
  }
  reminder: {
    loading: boolean;
    isSuccess: boolean;
  }
  acceptQuote: {
    loading: boolean;
    isSuccess: boolean;
  }
}

export enum TransactionTypes {
  INVOICE = 'Invoice',
  BILL = 'Bill',
  SALES_ORDER = 'Estimate',
  PURCHASE_ORDER = 'Quote',
}

export enum TransactionTabsEnum {
  SALES = 'sales',
  EXPENSES = 'expenses',
  UNPAID_INVOICES = 'unpaidInvoices',
  UNPAID_BILLS = 'unpaidBills',
  ESTIMATES = 'estimates',
  QUOTES = 'quotes'
}

export enum TransactionNameTabDataEnum {
  sales = 'Invoices',
  expenses = 'Bills',
  unpaidInvoices = 'Unpaid Invoices',
  unpaidBills = 'Unpaid Bills',
  estimates = 'Estimates',
  quotes = 'Quotes'
}

export enum getNameSummaryOfYearEnum {
  sales = 'sales',
  expenses = 'expenses',
  unpaidInvoices = 'invoices-aging',
  unpaidBills = 'bills-aging',
  estimates = 'sales-orders',
  quotes = 'purchase-orders',
}

export const TransactionTabsName = ['Sales', 'Expenses', ' Unpaid Invoices', 'Unpaid Bills', 'Estimates', 'Quotes'];

export type TransactionTypesKey = keyof typeof TransactionTypes;

export interface ReminderData {
  entityId: string,
  entity: string,
  interval: number,
  startDate: string,
  endDate: string,
  data: {
    contactName: string,
    dueDate: string,
    paymentAmount: string,
    email: string,
    message: string
  }
}

export type Contact = {
  id: number;
  fullname: string;
};

export type PaymentsMethods = {
  id: number,
  name: string,
  alias: string,
}

export type SaleInsight = {
  salesChannel: string;
  sales: number;
  percentage: number;
  selected?: boolean;
  details?: SaleInsight[];
};

export type ResTransactionSummary =
  [
    {
      entryType: string,
      entryTotal: number,
      paidCount?: number,
      unpaidCount?: number,
      sentCount?: number,
      acceptedCount?: number,
      respondedCount?: number
    },
  ]

export interface TransactionSummary {
  name: TransactionTypes;
  total: number;
  firstCol: {
    count: number;
    title: string;
  };
  secondCol: {
    count: number;
    title: string;
  };
  percent: string;
}

export interface TransactionItem {
  brand?: string;
  category: string;
  createdAt: string;
  description: string;
  id: number;
  invoiceId: number;
  name: string;
  options: ItemOption[];
  price?: number;
  quantity: number;
  taxes: TaxesRes[]
}

export interface ResTransaction {
  contact: { firstName: string, lastName: string }
  contactId: number;
  createdAt: string;
  date: string;
  dueDate: string;
  id: number;
  notes: string;
  organisationId: number;
  statusId: number;
  status?: string;
  total: number;
  type: string;
  updatedAt: string;
  expiryDate?: string;
  terms: TermsPaymentType;
}

export interface ResTransactionDetails {
  balance: number
  associations?: null | {
    type: 'ORDER' | 'ESTIMATE',
    id: number
  }
  contact: {
    address?: AddressType;
    createdAt: string;
    email: string;
    firstName: string;
    id: number;
    lastName: string;
    mobileNumber: string;
    organisationId: number;
    organisationName: string;
    updatedAt: string;
  }
  contactId: number;
  createdAt: string;
  currencyISO: string;
  date: string;
  totalDiscount: number;
  expiryDate?: string;
  dueDate: string;
  externalId: string;
  id: string;
  items: Items[];
  notes: string;
  organisationId: number;
  payments: IPayments[] | [] | null;
  reference: string;
  salesChannel: null | string;
  fulfillment?: IFulfillmentDetails;
  status?: string;
  statusId: number
  terms: TermsPaymentType;
  total: number;
  fees?: Fees[];
  totalTax: number;
  type: string;
  updatedAt: string;
}
interface SetDetailItem {
  type: typeof TRANSACTION_SET_DETAIL_ITEM;
  payload: TransactionType;
}

interface TransactionLoadingAction {
  type: typeof TRANSACTION_LOADING;
  payload: boolean;
}

interface GetTransactionDataAction {
  type: typeof TRANSACTION_GET_DATA;
}
interface GetMoreDocumentsData {
  type: typeof TRANSACTION_DOCUMENTS_MORE_GET_DATA;
  payload: TransactionType[];
}

interface MorePages {
  type: typeof TRANSACTION_MORE_PAGE;
  payload: boolean;
}

interface GetDocumentsDataAction {
  type: typeof TRANSACTION_DOCUMENTS_GET_DATA;
  payload: TransactionType[];
}

interface AppSetNotificationAction {
  type: typeof TRANSACTION_APP_SET_NOTIFICATION_TRANSACTION;
  payload: string | null;
}

interface TransactionRecordPaymentLoadingAction {
  type: typeof TRANSACTION_RECORD_PAYMENT_LOADING;
  payload: boolean;
}

interface TransactionRecordPaymentIsSuccessAction {
  type: typeof TRANSACTION_RECORD_PAYMENT_SUCCESS;
  payload: number | null;
}

interface TransactionReminderLoadingAction {
  type: typeof TRANSACTION_REMINDER_LOADING;
  payload: boolean;
}

interface TransactionReminderIsSuccessAction {
  type: typeof TRANSACTION_REMINDER_SUCCESS;
  payload: boolean;
}

interface TransactionAcceptLoadingAction {
  type: typeof TRANSACTION_ACCEPT_LOADING;
  payload: boolean;
}

interface TransactionAcceptIsSuccessAction {
  type: typeof TRANSACTION_ACCEPT_SUCCESS;
  payload: boolean;
}

export type TransactionActions =
  | GetTransactionDataAction
  | TransactionLoadingAction
  | GetDocumentsDataAction
  | SetDetailItem
  | GetMoreDocumentsData
  | MorePages
  | AppSetNotificationAction
  | TransactionRecordPaymentLoadingAction
  | TransactionRecordPaymentIsSuccessAction
  | TransactionReminderLoadingAction
  | TransactionReminderIsSuccessAction
  | TransactionAcceptLoadingAction
  | TransactionAcceptIsSuccessAction
