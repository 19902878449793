import React, { useEffect } from 'react';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Assets
import { ReactComponent as Check } from 'assets/storefront-icons/check-circle.svg';
// Components
import StorefrontLayout from 'layouts/StorefrontLayout';
// Styles
import { useLocation } from 'react-router-dom';
import LoadingPage from 'pages/LoadingPage';
import classes from './Shipping.module.scss';

const Shipping: React.FC = () => {
  const { currentSalesChannel, loading } = useTypeSelector(
    ({ storefront }) => storefront.shop,
  );

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { shipping_page, menu_links } = useTranslationsStorefront();

  if (!currentSalesChannel) {
    return (
      <div className={classes.shipping}>
        <StorefrontLayout type="two" />
      </div>
    );
  }

  return (
    <div className={classes.shipping}>
      <StorefrontLayout type="two" />
      {loading && <LoadingPage />}
      <div className={classes.shipping_content}>
        <h2>{menu_links.shipping}</h2>
        <React.Fragment key={currentSalesChannel.id}>

          <div className={classes.shipping_item_wrapper}>
            <h3>{shipping_page.shipping.title}</h3>
            {currentSalesChannel.address && (
              <div className={classes.shipping_content_description}>
                {shipping_page.ships_from}
                <span>{` ${currentSalesChannel?.address?.line1}, ${currentSalesChannel?.address?.country} `}</span>
                {shipping_page.shipping.description2}
              </div>
            )}
            {currentSalesChannel?.fulfilment?.shipping?.length ? (
              <ul>
                {currentSalesChannel?.fulfilment.shipping.map((item) => (
                  <li key={item.name}>
                    <Check />
                    {item.name}
                  </li>
                ))}
              </ul>
            ) : (
              <div className={classes.shipping_content_nodata}>
                {shipping_page.shipping.nodata}
              </div>
            )}
          </div>

          <div className={classes.shipping_item_wrapper}>
            <h3>{shipping_page.in_store.title}</h3>
            <div className={classes.shipping_content_description}>
              {shipping_page.in_store.description}
            </div>
            {currentSalesChannel?.fulfilment?.pickup.length ? (
              <ul className={classes.local__delivery_list}>
                {currentSalesChannel?.fulfilment?.pickup.map((item) => (
                  <li key={item.address.countryISO}>
                    <div>
                      {`${item.address.line1}, ${item.address.countryISO}`}
                    </div>
                    <div>
                      <Check />
                      {item.name}
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className={classes.shipping_content_nodata}>
                {shipping_page.in_store.nodata}
              </div>
            )}
          </div>

          <div className={classes.shipping_item_wrapper}>
            <h3>{shipping_page.delivery.title}</h3>
            <div className={classes.shipping_content_description}>
              {shipping_page.delivery.description}
            </div>
            {currentSalesChannel?.fulfilment?.delivery.length ? (
              <ul className={classes.delivery_list}>
                {currentSalesChannel?.fulfilment?.delivery?.map((item) => (
                  <li key={item.countryISO}>
                    <div className={classes.delivery_title}>
                      {`${item.name}, ${item.countryISO}`}
                    </div>
                    <div className={classes.delivery_areas}>
                      {item.areas?.map((area) => (
                        <div key={area.name}>
                          <Check />
                          {area.name}
                        </div>
                      ))}
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className={classes.shipping_content_nodata}>
                {shipping_page.in_store.nodata}
              </div>
            )}
          </div>
        </React.Fragment>
      </div>
    </div>
  );
};

export default Shipping;
