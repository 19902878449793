import { DeliveryMethodsEnum } from 'constants/deliveryMethods';
import { ContactType } from 'types/globalTypes';
import { PayMethodsEnum } from 'constants/paymentsMethods';
import {
  CheckoutType,
  FormData,
  IAmount,
  ICheckoutFulfillment,
  OrderItem,
  PaymentsMethods,
  RequestCheckoutBody,
} from 'store/storefront/checkout/checkoutTypes';
import { CartItemType, SalesChannel } from 'store/storefront/shop/shopTypes';
import { dateToDue, dateToIso } from 'utils/dates';
import { CartFunctions } from './CartFunctions';

export class CheckoutFunctions {
  static convertItemsToOrderItems(
    items: CartItemType[],
    currentSalesChannel: SalesChannel,
  ): OrderItem[] {
    return items.map((item) => ({
      name: item.name,
      category: item.category?.name || '',
      description: item.description,
      image: item.images?.length !== 0 ? undefined : item.images[0],
      price: item.variations[0].price,
      sku: item.sku || '',
      unit: item.unit?.name || '',
      quantity: item.quantity,
      variantId: item.variations[0].id || '',
      locationId: item.variations[0].inventories.find((inventory) => inventory.locationId === currentSalesChannel.id)?.locationId || '',
      options: item.variations[0].options.map((option) => ({
        name: option.name,
        variant: option.variant,
      })),
      taxes: item.taxes,
    }));
  }

  static calcAmount(
    products: CartItemType[],
    fee: number,
  ): IAmount {
    const SumIncludedTax = products.reduce((acc, curr) => {
      if (curr.taxes && curr.taxes.length) {
        return acc + (curr.taxes.reduce(
          (a, c) => (c.included ? c.amount + a : a), 0,
        ));
      }
      return acc + 0;
    }, 0);
    const SumExcludedTax = products.reduce((acc, curr) => {
      if (curr.taxes && curr.taxes.length) {
        return acc + (curr.taxes.reduce(
          (a, c) => (!c.included ? c.amount + a : a), 0,
        ));
      }
      return acc + 0;
    }, 0);
    const totalPrice = CartFunctions.calculateTotalPrice(products);

    return {
      total: totalPrice + SumExcludedTax + fee,
      subtotal: totalPrice,
      fee,
      taxesIncluded: SumIncludedTax,
      taxesExcluded: SumExcludedTax,
    };
  }

  static createOrder(
    items: CartItemType[],
    location: SalesChannel,
    fulfillment: ICheckoutFulfillment,
    payMethods?: PayMethodsEnum[],
    paymentMethod: PaymentsMethods = 'STRIPE',
    checkoutType: CheckoutType = 'INSTANT',
  ): RequestCheckoutBody {
    const orderedItems = CheckoutFunctions.convertItemsToOrderItems(items, location);

    const terms = checkoutType === 'LATER' ? {
      paymentDueDate: dateToIso(dateToDue(0)).toString(),
    } : undefined;

    return {
      total: fulfillment.amount.total,
      totalTax: Number(
        (fulfillment.amount.taxesIncluded + fulfillment.amount.taxesExcluded).toFixed(2),
      ),
      items: orderedItems.map(({ locationId, ...item }): any => item),
      checkoutType: checkoutType.toUpperCase() as CheckoutType,
      fulfillment: {
        type: fulfillment?.method?.toUpperCase() as DeliveryMethodsEnum,
        address: fulfillment?.location.address,
        trackingProvider: fulfillment.provider?.name,
        carrierFirstName: fulfillment.deliveryDriver?.name,
      },
      contactId: fulfillment.contact?.id || '',
      terms,
      paymentMethod,
      locationId: location.id,
      paymentMethodTypes: checkoutType.toUpperCase() === 'LATER' ? undefined : payMethods,
      balance: fulfillment.amount.total,
    };
  }

  static updateAccount(data: FormData): Partial<ContactType> {
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      address: {
        line1: data.line1.trim(),
        line2: data.line2 || undefined,
        city: data.city,
        postalCode: data.postCode,
        countryISO: data.country.value,
        latitude: data.latitude || undefined,
        longitude: data.longitude || undefined,
      },
      email: data.email,
      mobileNumber: `${data.code}${data.phoneNumber}`,
    };
  }
}
