import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Paypal
import {
  PayPalScriptProvider,
  PayPalButtons,
} from '@paypal/react-paypal-js';
import { useLocation, useParams } from 'react-router-dom';
// Constants
import { supportCurrencyPayPal } from 'constants/supportCurrencyPayPal';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Actions
import { checkoutSetOrder, onApprovePayPalOrder, checkout } from 'store/storefront/checkout/checkoutActions';
// Types
import { LoadingEnum } from 'store/storefront/checkout/checkoutTypes';
// Styles
import { useStorefrontRoutes } from 'hooks/useStorefrontRoutes';
import classes from './PayPal.module.scss';

const style = { layout: 'horizontal', color: 'gold' };

interface Props {
  closePaymentModal: () => void
}

const PayPal: React.FC<Props> = ({ closePaymentModal }) => {
  const { goToOrder } = useStorefrontRoutes();
  const translations = useTranslationsStorefront();
  const location = useLocation();

  const { items, fulfillment, order } = useTypeSelector(({ storefront }) => storefront.checkout);
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);
  const [approvePayPal, setApprovePayPal] = useState<boolean>(false);

  const { id } = useParams<{ id: string, orderId: string }>();
  const dispatchRedux = useDispatch();
  const currencyIso = currentSalesChannel?.address.currencyISO || 'USD';
  const availablePayPal = supportCurrencyPayPal.includes(currencyIso);

  useEffect(() => {
    if (approvePayPal) {
      dispatchRedux(checkoutSetOrder({
        ...order,
        loading: null,
        isPayed: true,
      }));
      if (order?.orderId) {
        goToOrder(id, order?.orderId, location);
      }
      setApprovePayPal(false);
    }
  }, [approvePayPal, dispatchRedux, goToOrder, id, location, order]);

  if (!currentSalesChannel) {
    return null;
  }

  return (
    <div className={classes.paypal}>
      <PayPalScriptProvider
        options={{
          'client-id': `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
          components: 'buttons',
          currency: !availablePayPal ? 'USD' : currencyIso,
        }}
      >
        <PayPalButtons
          // @ts-ignore
          style={style}
          disabled={!availablePayPal}
          forceReRender={[fulfillment.amount.total, currencyIso, style]}
          fundingSource={undefined}
          createOrder={() => {
            dispatchRedux(checkoutSetOrder({
              ...order,
              loading: LoadingEnum.PAYPAL,
            }));
            return checkout(
              id,
              'INSTANT',
              items,
              fulfillment,
              currentSalesChannel,
              'PAYPAL',
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ).then((res: any) => {
              dispatchRedux(checkoutSetOrder({
                loading: null,
                error: undefined,
                orderId: res.id,
                isPayed: false,
              }));
              return (res.payment.id);
            });
          }}
          onError={() => {
            dispatchRedux(checkoutSetOrder({
              loading: null,
              error: 'PayPal Error',
              isPayed: false,
            }));
          }}
          onApprove={async (data) => {
            onApprovePayPalOrder(id, data);
            setApprovePayPal(true);
          }}
        />
      </PayPalScriptProvider>
      {!availablePayPal && (
        <p className={classes.error}>
          {translations.checkout.errors.pay_pal_not_supported}
        </p>
      )}
    </div>
  );
};

export default PayPal;
