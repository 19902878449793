import React from 'react';
import { FieldValues, UseFormReturn, UseFormSetValue } from 'react-hook-form';
// Hooks
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Components
import CountrySelect from 'components/StorefrontComponents/CountrySelect';
// Styles
import classes from './Address.module.scss';

interface Props {
  register: UseFormReturn['register'];
  addManually: boolean;
  getValues: UseFormReturn['getValues'];
  handleContinue: () => void;
  control: UseFormReturn['control']
  defaultCountryBrowser?: {
    label: string;
    value: string;
  }
  setValue: UseFormSetValue<FieldValues>
  errors: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [x: string]: any;
  }
}

const Address: React.FC<Props> = ({
  register,
  addManually,
  getValues,
  errors,
  handleContinue,
  control,
  defaultCountryBrowser,
  setValue,
}) => {
  const { checkout } = useTranslationsStorefront();

  return (
    <div className={addManually ? classes.address_active : classes.address}>
      <div className={classes.address_form}>
        <p className={classes.subtitle}>{checkout.address}</p>
        <CountrySelect
          control={control}
          defaultCountry={defaultCountryBrowser}
          setValue={setValue}
          addManually={addManually}
        />
        <div className={classes.address_inputs_wrapper}>
          <div className={classes.form_name_label}>
            {checkout.address_input.label}
          </div>
          <input
            {...register('line1', { required: true })}
            placeholder={checkout.address_input.placeholder}
            className={errors?.firstName?.type === 'required' ? classes.name_input_error : classes.name_input}
          />
          <input
            {...register('line2', { required: false })}
            placeholder={checkout.address_input.address2_placeholder}
            className={errors?.firstName?.type === 'required' ? classes.name_input_error : classes.name_input}
          />
        </div>
        <div className={classes.address_city_postcode_wrapper}>
          <div>
            <div className={classes.form_name_label}>
              {checkout.address_input.town_city_label}
            </div>
            <input
              {...register('city', { required: true })}
              placeholder={checkout.address_input.town_city_placeholder}
              className={errors?.firstName?.type === 'required' ? classes.name_input_error : classes.name_input}
            />
          </div>
          <div>
            <div className={classes.form_name_label}>
              {checkout.address_input.postcode_label}
            </div>
            <input
              {...register('postCode', { required: false })}
              placeholder={checkout.address_input.postcode_placeholder}
              className={errors?.firstName?.type === 'required' ? classes.name_input_error : classes.name_input}
            />
          </div>
        </div>
      </div>
      <div className={classes.form_buttons}>
        <button
          type="button"
          onClick={handleContinue}
          className={classes.form_buttons_submit}
          disabled={!getValues('line1')
            || !getValues('city')}
        >
          {checkout.customer_info_modal.continue_btn}
        </button>
      </div>
    </div>
  );
};

export default Address;
