import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Types
import { IDeliveryData, IPickupData, IShippingData } from 'store/storefront/shop/shopTypes';
import { AddressType, ContactType } from 'types/globalTypes';
// Actions
import { checkoutSetFulfillmentAction } from 'store/storefront/checkout/checkoutActions';
// Utils
import { CurrencyFormatByISO } from 'utils/price';
import { CheckoutFunctions } from 'utils/storefront/CheckoutFunctions';
// Assets
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/left-arrow.svg';
// Components
import { DeliveryMethodsEnum } from 'constants/deliveryMethods';
import PickupLocationModal from './PickupLocationModal/PickupLocationModal';
import ShippingOption from './ShippingOption';
// Styles
import classes from './ShippingModal.module.scss';

interface Props {
  active: boolean;
  handleModal: () => void;
  contact: ContactType
  currDeliveryPlace: IDeliveryData | undefined
  currentShippingCountry: IShippingData | undefined
  currentPickupCountry: IPickupData[] | undefined
}

const ShippingModal: React.FC<Props> = ({
  active, handleModal, contact, currDeliveryPlace, currentShippingCountry, currentPickupCountry,
}) => {
  const showHideClassName = active ? classes.modal_show : classes.modal_hide;
  const { items, fulfillment } = useTypeSelector(({ storefront }) => storefront.checkout);
  const dispatch = useDispatch();
  const [pickupLocationModal, setPickupLocationModal] = useState<boolean>();
  const [pickupLocation, setPickupLocation] = useState<{ name: string, address: AddressType }>();
  const [option, setOption] = useState<DeliveryMethodsEnum | null>(null);

  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);

  const formatCurrencyByISO = useMemo(
    () => CurrencyFormatByISO(currentSalesChannel?.address.currencyISO),
    [currentSalesChannel?.address.currencyISO],
  );

  const { checkout } = useTranslationsStorefront();
  const shippingModalTranslations = checkout.shipping_modal;
  const handlePickupLocationModal = useCallback(() => {
    setPickupLocationModal((prev) => !prev);
  }, []);

  const handleOption = useCallback((type: DeliveryMethodsEnum) => () => {
    setOption(type);
  }, []);

  const handlePickupLocation = useCallback((location: {
    name: string,
    address: AddressType,
  }) => () => {
    setPickupLocation(location);
  }, []);

  const handleContinue = useCallback(() => {
    if (option === DeliveryMethodsEnum.DELIVERY) {
      handleModal();
      const amount = CheckoutFunctions.calcAmount(items, currDeliveryPlace?.fee || 0);
      window.scrollTo(0, 0);
      dispatch(checkoutSetFulfillmentAction({
        ...fulfillment,
        method: DeliveryMethodsEnum.DELIVERY,
        location: {
          name: '',
          address: contact.address,
        },
        amount,
      }));
    }

    if (option === DeliveryMethodsEnum.PICKUP) {
      setPickupLocationModal(true);
    }

    if (option === DeliveryMethodsEnum.PICKUP && pickupLocation) {
      setPickupLocationModal(false);
      handleModal();
      const amount = CheckoutFunctions.calcAmount(items, 0);
      window.scrollTo(0, 0);
      dispatch(checkoutSetFulfillmentAction({
        ...fulfillment,
        method: DeliveryMethodsEnum.PICKUP,
        location: {
          name: pickupLocation?.name,
          address: pickupLocation?.address,
        },
        amount,
      }));
      setPickupLocation(undefined);
    }

    if (option === DeliveryMethodsEnum.SHIPPING) {
      handleModal();
      window.scrollTo(0, 0);
      const amount = CheckoutFunctions.calcAmount(items, currentShippingCountry?.fee || 0);
      dispatch(checkoutSetFulfillmentAction({
        ...fulfillment,
        method: DeliveryMethodsEnum.SHIPPING,
        location: {
          name: '',
          address: contact.address,
        },
        amount,
      }));
    }
  }, [
    currDeliveryPlace?.fee,
    currentShippingCountry?.fee,
    dispatch,
    fulfillment,
    handleModal,
    items,
    option,
    pickupLocation,
  ]);

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [active]);

  useEffect(() => {
    setOption(fulfillment.method);
  }, [fulfillment.method]);

  if (pickupLocationModal) {
    return (
      <PickupLocationModal
        active={active}
        pickupLocations={currentPickupCountry}
        handleModal={handleModal}
        handlePickupLocationModal={handlePickupLocationModal}
        pickupLocation={pickupLocation}
        handlePickupLocation={handlePickupLocation}
        handleContinue={handleContinue}
      />
    );
  }

  return (
    <div
      className={showHideClassName}
    >
      <div
        className={active ? classes.modal_wrapper_active : classes.modal_wrapper}
        tabIndex={0}
        role="button"
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.root}>
          <div className={classes.header}>
            <CloseIcon
              role="button"
              className={classes.close_icon}
              onClick={handleModal}
            />
            <LeftArrowIcon
              role="button"
              className={classes.left_arrow_icon}
              onClick={handleModal}
            />
          </div>
          <div className={classes.title}>
            {shippingModalTranslations.title}
          </div>
          <div className={classes.shipping_options}>
            {currentShippingCountry && (
              <ShippingOption
                checked={option === DeliveryMethodsEnum.SHIPPING}
                onCheck={handleOption(DeliveryMethodsEnum.SHIPPING)}
                address={`${shippingModalTranslations.shipping_options.shipping_address_text} ${contact?.address?.city}`}
                type={shippingModalTranslations.shipping_options.shipping_type_text}
                fee={formatCurrencyByISO(currentShippingCountry?.fee)}
                fee_label={shippingModalTranslations.shipping_options.shipping_fee_text}
              />
            )}
            {currentPickupCountry && !!currentPickupCountry.length && (
              <ShippingOption
                checked={option === DeliveryMethodsEnum.PICKUP}
                onCheck={handleOption(DeliveryMethodsEnum.PICKUP)}
                address={shippingModalTranslations.shipping_options.pickup_address_text}
                type={shippingModalTranslations.shipping_options.pickup_type_text}
                fee={formatCurrencyByISO(0)}
                fee_label={shippingModalTranslations.shipping_options.pickup_fee_text}
              />
            )}
            {currDeliveryPlace && (
              <ShippingOption
                checked={option === DeliveryMethodsEnum.DELIVERY}
                onCheck={handleOption(DeliveryMethodsEnum.DELIVERY)}
                address={`${shippingModalTranslations.shipping_options.delivery_address_text} ${contact?.address?.city}`}
                type={shippingModalTranslations.shipping_options.delivery_type_text}
                fee={formatCurrencyByISO(currDeliveryPlace?.fee)}
                fee_label={shippingModalTranslations.shipping_options.delivery_fee_text}
              />
            )}
          </div>
          <div className={classes.bottom}>
            <button
              type="button"
              className={classes.bottom_cancel}
              onClick={handleModal}
            >
              {shippingModalTranslations.cancel_btn}
            </button>
            <button
              type="button"
              className={classes.bottom_submit}
              onClick={handleContinue}
              disabled={!option}
            >
              {shippingModalTranslations.continue_btn}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingModal;
