/* eslint-disable no-console */
import { StripeError } from '@stripe/stripe-js';
import { AxiosResponse } from 'axios';
import { DeliveryMethodsEnum } from 'constants/deliveryMethods';
import { PayMethodsEnum } from 'constants/paymentsMethods';
import {
  ApiOrgPublicId, ApiOrgPublicIdPost, ApiFullHeaders, ApiOrgPublicIdPatch,
} from 'services/api';
import firebase, { auth, EmailAuthProvider } from 'services/firebase';
import { AppThunk } from 'store';
import { Variation } from 'store/dashboard/catalog/items/itemsTypes';
import { setTimeout } from 'timers';
import { ContactType, Unit } from 'types/globalTypes';
import { CheckoutFunctions } from 'utils/storefront/CheckoutFunctions';
import { SetAddToBagAction } from '../shop/shopActions';
import { CartItemType, SalesChannel } from '../shop/shopTypes';
import {
  CHECKOUT_SET_READY,
  CHECKOUT_SET_ITEMS,
  CHECKOUT_SET_FULFILLMENT_METHOD,
  CHECKOUT_SET_FULFILLMENT_DATA,
  CHECKOUT_SET_LOCATION,
  CHECKOUT_SET_CONTACT,
  CHECKOUT_SET_PAYMENT,
  CheckoutActions,
  ICheckoutFulfillment,
  IOrder,
  IPreCheckout,
  CHECKOUT_REMOVE_ITEM,
  CHECKOUT_UPDATE_ITEM,
  IUpdateItem,
  IResCheckout,
  CHECKOUT_SET_SALES_CHANNEL,
  CHECKOUT_SET_PRE_CHECKOUT_LOADING,
  CheckoutState,
  LoadingEnum,
  CheckoutType,
  FormData,
  CHECKOUT_SET_IS_USER,
  CHECKOUT_SET_IS_NEW_USER,
  CHECKOUT_SET_ORDER_LOADING,
} from './checkoutTypes';

export const checkoutSetReadyAction = (payload: IPreCheckout): CheckoutActions => ({
  type: CHECKOUT_SET_READY,
  payload,
});

export const checkoutSetSalesChannel = (payload: SalesChannel): CheckoutActions => ({
  type: CHECKOUT_SET_SALES_CHANNEL,
  payload,
});

export const checkoutSetItemsAction = (payload: CartItemType[]): CheckoutActions => ({
  type: CHECKOUT_SET_ITEMS,
  payload,
});

export const checkoutSetMethodAction = (payload: DeliveryMethodsEnum): CheckoutActions => ({
  type: CHECKOUT_SET_FULFILLMENT_METHOD,
  payload,
});

export const checkoutUpdateItemAction = (payload: IUpdateItem): CheckoutActions => ({
  type: CHECKOUT_UPDATE_ITEM,
  payload,
});

export const checkoutSetFulfillmentAction = (payload: ICheckoutFulfillment): CheckoutActions => ({
  type: CHECKOUT_SET_FULFILLMENT_DATA,
  payload,
});

export const checkoutSetLocationAction = (payload: ICheckoutFulfillment['location']): CheckoutActions => ({
  type: CHECKOUT_SET_LOCATION,
  payload,
});

export const checkoutSetContactData = (payload: ICheckoutFulfillment['contact']): CheckoutActions => ({
  type: CHECKOUT_SET_CONTACT,
  payload,
});

export const checkoutRemoveItemAction = (payload: IUpdateItem): CheckoutActions => ({
  type: CHECKOUT_REMOVE_ITEM,
  payload,
});

export const checkoutSetOrder = (payload: IOrder): CheckoutActions => ({
  type: CHECKOUT_SET_PAYMENT,
  payload,
});

export const checkoutSetPreCheckoutLoading = (payload: boolean): CheckoutActions => ({
  type: CHECKOUT_SET_PRE_CHECKOUT_LOADING,
  payload,
});

export const checkoutSetIsUser = (payload: boolean): CheckoutActions => ({
  type: CHECKOUT_SET_IS_USER,
  payload,
});

export const checkoutSetIsNewUser = (payload: boolean): CheckoutActions => ({
  type: CHECKOUT_SET_IS_NEW_USER,
  payload,
});

export const checkoutSetOrderLoadingAction = (payload: LoadingEnum | null): CheckoutActions => ({
  type: CHECKOUT_SET_ORDER_LOADING,
  payload,
});

export const checkout = async (
  shopId: string,
  checkoutType: CheckoutType,
  items: CartItemType[],
  fulfillment: ICheckoutFulfillment,
  location: SalesChannel,
  paymentMethod: 'STRIPE' | 'PAYPAL' | 'PAYSTACK',
  payMethods?: PayMethodsEnum[] | [],
): Promise<IResCheckout | undefined> => {
  try {
    const data = await ApiOrgPublicIdPost('/p/checkout', CheckoutFunctions.createOrder(
      items,
      location,
      fulfillment,
      payMethods,
      paymentMethod,
      checkoutType,
    ), shopId);

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return undefined;
  }
};

export const preCheckout = (
  shopId: string,
  bag: CartItemType[],
  fulfillment: ICheckoutFulfillment,
  currentSalesChannel: SalesChannel,
): AppThunk => async (dispatch) => {
  dispatch(checkoutSetPreCheckoutLoading(true));
  try {
    const units: Unit[] = await ApiOrgPublicId('/p/units', shopId);

    const addUnitName = bag.map((item) => ({
      ...item,
      unit: {
        name: units.find((unit) => unit.id === item.categoryId)?.name,
      },
    }));

    const preCheckoutItems = CheckoutFunctions.convertItemsToOrderItems(
      addUnitName, currentSalesChannel,
    );

    const data: IPreCheckout = await ApiOrgPublicIdPost<IPreCheckout>('/p/pre-checkout', preCheckoutItems, shopId);

    if (data.success) {
      dispatch(checkoutSetReadyAction(data));
      if (!data.success) {
        localStorage.removeItem(`leja-${shopId}-checkout`);
      } else {
        const amount = CheckoutFunctions.calcAmount(bag, fulfillment.amount.fee || 0);
        localStorage.setItem(`leja-${shopId}-checkout`, JSON.stringify({
          isReadyForCheckout: data,
          items: addUnitName,
          fulfillment: {
            ...fulfillment,
            amount: {
              ...fulfillment.amount,
              total: amount.total,
              subtotal: amount.subtotal,
              taxesIncluded: amount.taxesIncluded,
              taxesExcluded: amount.taxesExcluded,
            },
          },
        } as CheckoutState));
      }
      dispatch(checkoutSetPreCheckoutLoading(false));
    } else {
      const newProductsVariation = await Promise.all(bag.map(
        async (item) => {
          const unavailableProduct = data.data.find(
            (checkoutItems) => checkoutItems.sku === item.variations[0].id,
          );

          if (unavailableProduct) {
            const variations = await ApiOrgPublicId(`/p/items/${item.id}/variants`, shopId);
            const currVariant = variations?.data?.find(
              (variant: Variation) => variant.id === item.variations[0].id,
            );

            return {
              ...item,
              stock: currVariant?.stock || 0,
              variations: [currVariant || {
                ...item.variations[0],
                stock: 0,
                inventories: item?.inventories?.map((inventory) => ({
                  ...inventory,
                  quantity: 0,
                })),
              }],
            };
          }

          return {
            ...item,
          };
        },
      ));
      dispatch(SetAddToBagAction(newProductsVariation));
      dispatch(checkoutSetReadyAction(data));
      dispatch(checkoutSetPreCheckoutLoading(false));
    }
  } catch (error) {
    console.log(error);
  }
};

export const onApprovePayPalOrder = async <T>(
  shopId: string,
  data: { orderID: string },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<T | any>> => {
  const res = await ApiOrgPublicIdPost('/p/approve-payment', {
    orderId: data.orderID,
  }, shopId);
  return res;
};

export const onError = (
  error: StripeError | undefined | unknown,
  order: IOrder,
): AppThunk => async (dispatch) => {
  if (error) {
    dispatch(checkoutSetOrder({
      ...order,
      loading: null,
      error: 'Server error',
    }));
  } else {
    setTimeout(() => dispatch(checkoutSetOrder({
      loading: null,
      error: undefined,
      id: order.orderId,
      isPayed: false,
    })), 100);
  }
};

export const onSuccess = (order: IOrder): AppThunk => async (dispatch) => {
  dispatch(checkoutSetOrder({
    loading: null,
    error: undefined,
    loadingModal: false,
    orderId: order.id,
    isPayed: true,
  }));
};

export const authEmailAndPassword = (
  data: {
    email: string;
    password: string;
    checkbox: string;
  },
  order: IOrder,
  setWrongPassword: (value: boolean) => void,
  setActive: () => void,
): AppThunk => async (dispatch) => {
  try {
    dispatch(checkoutSetOrder({
      ...order,
      loading: LoadingEnum.SIGNIN,
    }));
    let err = false;
    await auth.setPersistence(data.checkbox
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION);

    await auth.signInWithEmailAndPassword(data.email, data.password).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;

      if (!error.code) {
        setActive();
      }

      if (errorCode === 'auth/wrong-password') {
        setWrongPassword(true);
        err = true;
        dispatch(checkoutSetOrderLoadingAction(null));
      } else {
        console.log(errorMessage);
        dispatch(checkoutSetOrderLoadingAction(null));
      }
    });

    if (!err) {
      setActive();
    }

    dispatch(checkoutSetOrderLoadingAction(null));
  } catch (err) {
    console.log(err);
    dispatch(checkoutSetOrderLoadingAction(null));
  }
};

export const registrationUser = (
  data: FormData,
  orgName: string,
): AppThunk => async (dispatch) => {
  try {
    dispatch(checkoutSetIsNewUser(true));
    dispatch(checkoutSetOrderLoadingAction(LoadingEnum.SIGNUP));
    const patchCustomerInfo = async () => {
      const contact = await ApiFullHeaders('/c/contacts/me', orgName);
      dispatch(checkoutSetContactData(contact));
      const res: ContactType = await ApiOrgPublicIdPatch('/c/contacts/me', CheckoutFunctions.updateAccount(data), orgName);
      dispatch(checkoutSetContactData(res));
      dispatch(checkoutSetOrderLoadingAction(null));
    };

    auth.onAuthStateChanged((user) => {
      if (!user) {
        auth.createUserWithEmailAndPassword(data.email, data.password).then(patchCustomerInfo);
      } else {
        const credential = EmailAuthProvider(data.email, data.password);
        auth.currentUser?.linkWithCredential(credential).then(patchCustomerInfo);
      }
    });
  } catch (err) {
    console.log(err);
    dispatch(checkoutSetOrderLoadingAction(null));
  }
};

export const updateCustomerInfo = (
  data: FormData,
  orgName: string,
): AppThunk => async (dispatch) => {
  try {
    const res: {
      data: ContactType
    } = await ApiOrgPublicIdPatch('/c/contacts/me', CheckoutFunctions.updateAccount(data), orgName);

    dispatch(checkoutSetContactData(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const checkIsLogin = (): AppThunk => async (dispatch) => {
  try {
    auth.onAuthStateChanged(async (user) => {
      if (!user?.email) {
        dispatch(checkoutSetIsUser(false));
        return;
      }

      dispatch(checkoutSetIsUser(true));
    });
  } catch (error) {
    console.log(error);
  }
};

export const getCustomerInfo = (
  orgName: string,
): AppThunk => async (dispatch) => {
  try {
    dispatch(checkoutSetOrderLoadingAction(LoadingEnum.SIGNIN));
    const contact = await ApiFullHeaders('/c/contacts/me', orgName);
    dispatch(checkoutSetContactData(contact));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(checkoutSetOrderLoadingAction(null));
  }
};
