import React from 'react';
// Styles
import classes from './CompanyAvatar.module.scss';

interface Props {
  logoUrl: string | undefined | null;
  title: string;
  onClick?: () => void;
}

const CompanyAvatar: React.FC<Props> = ({ logoUrl, title, onClick }) => (
  <div className={classes.avatar} role="button" tabIndex={0} onClick={onClick}>
    {logoUrl ? (
      <img src={logoUrl} alt="Shop logo" />
    ) : (
      <span>
        {title}
      </span>
    )}
  </div>
);

export default CompanyAvatar;
