import React, {
  memo, useCallback, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
// Hooks
import { useUser } from 'hooks/useUser';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useDashboardRoutes } from 'hooks/useDashboardRoutes';
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
// Actions
import { getMoreProducts, CatalogItemsSetProductsAction } from 'store/dashboard/catalog/items/itemsActions';
// Types
import { ReactSelectOption } from 'types/globalTypes';
// Components
import CustomSpinner from 'components/CustomSpinner';
import Header from 'pages/Dashboard/Transaction/TransactionTabContent/Header';
import MoreButton from 'components/Buttons/MoreButton';
import NoItems from 'components/DashboardComponents/NoItems';
import SelectSort from 'components/DashboardComponents/SelectSort';
import TableItemMobile from './TableItemMobile';
import TableItem from './TableItem';
// Styles
import classes from './ItemsTable.module.scss';

const ItemsTable: React.FC = () => {
  const { pathToItemDetails } = useDashboardRoutes();
  const {
    products,
  } = useTypeSelector((state) => state.dashboard.catalog.items);
  const dispatch = useDispatch();
  const user = useUser();
  const translations = useTranslations();
  const ts = translations.catalog;
  const { isMobile } = useMobileScreen();
  const [sort, setSort] = useState<string>();
  const [page, setPage] = useState<number>(1);

  const options = useMemo(() => translations.catalog.items.table.headers.map((header) => ({
    label: header,
    value: header,
    key: header,
  })), [translations.catalog.items.table.headers]);

  const handleChange = (e: ReactSelectOption | null) => {
    handleSort(String(e?.value));
  };

  const handleMoreClicked = useCallback(() => {
    if (!user?.currentOrganisation?.id) {
      return;
    }

    setPage((prev) => prev + 1);
    dispatch(getMoreProducts(user.currentOrganisation.id, page));
  }, [dispatch, page, user]);

  const handleSort = useCallback((header: string, sortConfig = 'asc') => {
    if (!products.data) {
      return;
    }

    setSort(header);
    if (header === translations.catalog.items.table.headers[0]) {
      if (sortConfig === 'asc') {
        dispatch(CatalogItemsSetProductsAction(products.data.sort((a, b) => {
          if (a.name > b.name) return -1;
          if (a.name < b.name) return 1;
          return 0;
        })));
      } else {
        dispatch(CatalogItemsSetProductsAction(products.data.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        })));
      }
    }

    if (header === translations.catalog.items.table.headers[1]) {
      if (sortConfig === 'asc') {
        dispatch(CatalogItemsSetProductsAction(products.data.sort((a, b) => {
          if (`${a.category?.name}` > `${b.category?.name}`) return -1;
          if (`${a.category?.name}` < `${b.category?.name}`) return 1;
          return 0;
        })));
      } else {
        dispatch(CatalogItemsSetProductsAction(products.data.sort((a, b) => {
          if (`${a.category?.name}` > `${b.category?.name}`) return 1;
          if (`${a.category?.name}` < `${b.category?.name}`) return -1;
          return 0;
        })));
      }
    }

    if (header === translations.catalog.items.table.headers[2]) {
      if (sortConfig === 'asc') {
        dispatch(CatalogItemsSetProductsAction(products.data.sort(
          (a, b) => Number(b?.countOptions) - Number(a?.countOptions),
        )));
      } else {
        dispatch(CatalogItemsSetProductsAction(products.data.sort(
          (a, b) => Number(a?.countOptions) - Number(b?.countOptions),
        )));
      }
    }

    if (header === translations.catalog.items.table.headers[3]) {
      if (sortConfig === 'desc') {
        dispatch(CatalogItemsSetProductsAction(products.data.sort(
          (a, b) => Number(a?.stock) - Number(b?.stock),
        )));
      } else {
        dispatch(CatalogItemsSetProductsAction(products.data.sort(
          (a, b) => Number(b?.stock) - Number(a?.stock),
        )));
      }
    }

    if (header === translations.catalog.items.table.headers[4]) {
      if (sortConfig === 'asc') {
        dispatch(CatalogItemsSetProductsAction(products.data.sort(
          (a, b) => Number(a?.salesChannelCount) - Number(b?.salesChannelCount),
        )));
      } else {
        dispatch(CatalogItemsSetProductsAction(products.data.sort(
          (a, b) => Number(b?.salesChannelCount) - Number(a?.salesChannelCount),
        )));
      }
    }

    if (header === translations.catalog.items.table.headers[5]) {
      if (sortConfig === 'asc') {
        dispatch(CatalogItemsSetProductsAction(products.data.sort(
          (a, b) => Number(b?.priceRange?.min) - Number(a?.priceRange?.min),
        )));
      } else {
        dispatch(CatalogItemsSetProductsAction(products.data.sort(
          (a, b) => Number(a?.priceRange?.min) - Number(b?.priceRange?.min),
        )));
      }
    }
  }, [dispatch, products, translations.catalog.items.table.headers]);

  if (isMobile) {
    return (
      <div className={classes.list}>
        <div className={classes.sort_mob}>
          <p className={classes.sort_mob_label}>
            {translations.transactions.sort_by}
          </p>
          <SelectSort
            options={options}
            defaultValue={options[1]}
            handleChange={handleChange}
            type="two"
          />
        </div>
        {products?.data?.map((item) => (
          <TableItemMobile
            key={item.id}
            item={item}
            pathToDetails={pathToItemDetails(item.id)}
          />
        ))}
        {products.isNextPage && !products.loading && (
          <div onClick={handleMoreClicked} tabIndex={0} role="button" className={classes.more_btn}>
            <MoreButton />
          </div>
        )}
        {!products.loading && !products.isNextPage && (
          <div className={classes.loading}>
            <CustomSpinner variant="two" />
          </div>
        )}
        {!products.data?.length && (
          <NoItems
            translation={ts.items.no_items}
          />
        )}
      </div>
    );
  }

  return (
    <div className={classes.list}>
      <div className={products.data?.length ? classes.table_header_no_items : classes.table_header}>
        {translations.catalog.items.table.headers.map((title) => (
          <Header
            header={title}
            key={title}
            sortItems={handleSort}
            isIconShow={sort === title}
            disabled={!!products.data?.length}
          />
        ))}
      </div>
      {products?.data?.map((item) => (
        <TableItem
          item={item}
          key={item.id}
          pathToDetails={pathToItemDetails(item.id)}
          currencyISO={user?.currentOrganisation?.address?.currencyISO || 'USD'}
        />
      ))}
      {products.data?.length && products.isNextPage && !products.loading && (
        <div onClick={handleMoreClicked} tabIndex={0} role="button" className={classes.more_btn}>
          <MoreButton />
        </div>
      )}
      {products.loading && (
        <div className={classes.loading}>
          <CustomSpinner variant="two" />
        </div>
      )}
      {!products.data?.length && (
        <NoItems
          translation={ts.items.no_items}
        />
      )}
    </div>
  );
};

export default memo(ItemsTable);
