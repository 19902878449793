import { useMobileScreen } from 'hooks/useMobileScreen';
import React from 'react';
import { TransactionType } from 'types/globalTypes';
import { formatReg } from 'utils/dates';
import { CurrencyFormatByISO } from 'utils/price';
import { useUser } from 'hooks/useUser';
import { useTranslations } from 'hooks/useTranslations';
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
// Styles
import classes from './TransactionItem.module.scss';

interface Props {
  item: TransactionType;
  handleDetailsClicked: (id: string, type: string, status: string) => void;
}

const TransactionItem: React.FC<Props> = ({ item, handleDetailsClicked }) => {
  const { isMobile } = useMobileScreen();
  const user = useUser();
  const formatCurrencyByISO = CurrencyFormatByISO(
    user?.currentOrganisation?.address?.currencyISO || 'USD',
  );
  const translations = useTranslations();
  const tsItem = translations.transactions.tabs_header;

  return isMobile ? (
    <button
      type="button"
      key={item.id}
      className={classes.item_mb}
      onClick={() => handleDetailsClicked(item.id, item.type, item.status)}
    >
      <div className={classes.item_mb_header}>
        <div className={classes.item_mb_header_id}>
          <p className={classes.item_mb_header_id_label}>
            {tsItem.transaction_id}
          </p>
          <p className={classes.item_mb_header_id_value}>{item.id}</p>
        </div>
        <div className={classes.item_mb_header_date}>
          <p className={classes.item_mb_header_date_label}>{tsItem.date}</p>
          <p className={classes.item_mb_header_date_value}>
            {formatReg(item.date)}
          </p>
        </div>
      </div>
      <div className={classes.item_mb_name}>
        <p className={classes.item_mb_name_name}>{item.name}</p>
        <p className={classes.item_mb_name_value}>
          {formatCurrencyByISO(item.amount)}
        </p>
      </div>
      <div className={classes.item_mb_bottom}>
        <p className={classes.item_mb_bottom_type}>{item.type}</p>
        <p className={classes.item_mb_bottom_status}>
          {
            translations.status[
              item.status as keyof TranslationDashboardModel['status']
            ]
          }
        </p>
      </div>
    </button>
  ) : (
    <button
      key={item.id}
      type="button"
      className={classes.table_content_item}
      onClick={() => handleDetailsClicked(item.id, item.type, item.status)}
    >
      <p>{item.id}</p>
      <p>{formatReg(item.date)}</p>
      <p>{item.name}</p>
      <p>{item.type}</p>
      <p>
        {
          translations.status[
            item.status as keyof TranslationDashboardModel['status']
          ]
        }
      </p>
      <p>{item.amount ? formatCurrencyByISO(item.amount) : '-'}</p>
    </button>
  );
};

export default TransactionItem;
