/* eslint-disable no-console */
import { ApiOrgPublicId, ApiOrgPublicIdPatch } from 'services/api';
import { auth, EmailAuthProvider } from 'services/firebase';
import { FirebaseError } from '@firebase/util';
import { AppThunk } from 'store';
import { ContactType } from 'types/globalTypes';
import { IOrderData } from '../order/orderTypes';
import {
  AccountActions,
  ACCOUNT_IS_NEXT_PAGE_ORDERS_LOADING_ACTION,
  ACCOUNT_SET_CONTACT,
  ACCOUNT_SET_ERROR,
  ACCOUNT_SET_LOADING,
  ACCOUNT_SET_MORE_ORDERS,
  ACCOUNT_SET_ORDERS,
  LoadingEnum,
} from './accountTypes';

export const accountSetContactAction = (payload: ContactType): AccountActions => ({
  type: ACCOUNT_SET_CONTACT,
  payload,
});

export const accountSetErrorAction = (payload: string | null): AccountActions => ({
  type: ACCOUNT_SET_ERROR,
  payload,
});

export const accountSetLoadingAction = (payload: LoadingEnum | null): AccountActions => ({
  type: ACCOUNT_SET_LOADING,
  payload,
});

export const accountSetOrdersAction = (payload: IOrderData[]): AccountActions => ({
  type: ACCOUNT_SET_ORDERS,
  payload,
});

export const accountSetMoreOrdersAction = (payload: IOrderData[]): AccountActions => ({
  type: ACCOUNT_SET_MORE_ORDERS,
  payload,
});

export const accountIsNextPageOrdersLoadingAction = (payload: boolean): AccountActions => ({
  type: ACCOUNT_IS_NEXT_PAGE_ORDERS_LOADING_ACTION,
  payload,
});

export const getCustomerContact = (
  orgName: string,
): AppThunk => async (dispatch) => {
  try {
    dispatch(accountSetLoadingAction(LoadingEnum.WAIT));
    auth.onAuthStateChanged(async (user) => {
      if (!user) {
        return;
      }

      const contact = await ApiOrgPublicId('/c/contacts/me', orgName);
      dispatch(accountSetContactAction(contact));
    });
    dispatch(accountSetLoadingAction(null));
  } catch (err) {
    console.log(err);
  }
};

export const patchCustomerInfo = (
  contact: ContactType,
  orgName: string,
): AppThunk => async (dispatch) => {
  try {
    dispatch(accountSetLoadingAction(LoadingEnum.WAIT));
    const updatedContact:ContactType = await ApiOrgPublicIdPatch('/c/contacts/me', contact, orgName);
    dispatch(accountSetContactAction(updatedContact));
    dispatch(accountSetLoadingAction(null));
  } catch (err) {
    console.log(err);
  }
};

export const reauthenticateWithCredential = (
  data: {
    password: string;
  },
): AppThunk => async (dispatch) => {
  try {
    dispatch(accountSetLoadingAction(LoadingEnum.WAIT));
    if (auth.currentUser) {
      const credentials = EmailAuthProvider(
        auth.currentUser.email,
        data.password,
      );
      await auth.currentUser?.reauthenticateWithCredential(credentials);
      dispatch(accountSetLoadingAction(null));
    }
  } catch (err) {
    if (err instanceof FirebaseError) {
      dispatch(accountSetErrorAction(err.code));
    }
    dispatch(accountSetLoadingAction(null));
  }
};

export const updatePassword = (data: {
  oldPassword: string,
  newPassword: string,
},
success: () => void): AppThunk => async (dispatch) => {
  try {
    dispatch(accountSetLoadingAction(LoadingEnum.WAIT));
    dispatch(accountSetErrorAction(null));
    if (auth.currentUser) {
      const credentials = EmailAuthProvider(
        auth.currentUser.email,
        data.oldPassword,
      );
      const user = await auth.currentUser?.reauthenticateWithCredential(credentials);
      if (user) {
        auth?.currentUser?.updatePassword(data.newPassword).then(() => {
          success();
          dispatch(accountSetLoadingAction(null));
        }).catch((err) => {
          dispatch(accountSetErrorAction(err.code));
          dispatch(accountSetLoadingAction(null));
        });
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      dispatch(accountSetErrorAction(err.message));
    }
    dispatch(accountSetLoadingAction(null));
  }
};

export const signOut = (): AppThunk => async (dispatch) => {
  dispatch(accountSetLoadingAction(LoadingEnum.WAIT));
  auth.signOut().catch((error) => {
    dispatch(accountSetErrorAction(error.code));
  });
  dispatch(accountSetLoadingAction(null));
};

export const getOrders = (orgName: string): AppThunk => async (dispatch) => {
  auth.onAuthStateChanged(async (user) => {
    dispatch(accountSetLoadingAction(LoadingEnum.WAIT));
    if (!user?.email) {
      return;
    }

    const orders = await ApiOrgPublicId('c/contacts/me/sales-orders?page=0', orgName);
    dispatch(accountSetOrdersAction(orders));
    dispatch(accountSetLoadingAction(null));
  });
};

export const getMoreOrders = (orgName: string, page: number): AppThunk => async (dispatch) => {
  try {
    const orders = await ApiOrgPublicId(`c/contacts/me/sales-orders?page=${page}`, orgName);
    dispatch(accountSetMoreOrdersAction(orders));
    dispatch(accountIsNextPageOrdersLoadingAction(orders.length > 10));
  } catch (err) {
    if (err instanceof Error) {
      dispatch(accountSetErrorAction(err.message));
    }
  }
};
