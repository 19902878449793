import React from 'react';
// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useUser } from 'hooks/useUser';
import { useMobileScreen } from 'hooks/useMobileScreen';
// Actions
import { selectTransactionDetails } from 'store/dashboard/transaction/transactionSelectors';
// Types
import { TransactionStatus } from 'types/globalTypes';
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
// Styles
import classes from './Contacts.module.scss';

const Contacts: React.FC = () => {
  const transaction = useTypeSelector(selectTransactionDetails);
  const user = useUser();
  const translations = useTranslations();
  const ts = translations.documents.contacts;
  const { isMobile } = useMobileScreen();
  const isActive = transaction?.status === TransactionStatus.UNPAID
    || transaction?.status === TransactionStatus.PENDING
    || transaction?.status === TransactionStatus.PRICED;

  const contactTitle = () => {
    if (transaction?.type === 'Invoice') {
      return ts.billed_to;
    }

    if (transaction?.type === 'Estimate') {
      return ts.customer;
    }

    return ts.vendor;
  };

  if (!transaction) {
    return null;
  }

  return (
    <div className={classes.contacts}>
      <div className={classes.contacts_wrapper}>
        <div className={classes.contact}>
          <h2 className={classes.contact_title}>
            {user?.currentOrganisation?.name}
          </h2>
          <div className={classes.contact_address}>
            {isMobile ? (
              <p>
                {user?.currentOrganisation?.address?.line1}
                {user?.currentOrganisation?.address?.line2}
                {user?.currentOrganisation?.address?.city},
                {user?.currentOrganisation?.address?.postalCode},
                {user?.currentOrganisation?.address?.country}
              </p>
            ) : (
              <>
                <p>
                  {user?.currentOrganisation?.address?.line1}
                </p>
                <p>
                  {user?.currentOrganisation?.address?.line2}
                </p>
                <p>
                  {user?.currentOrganisation?.address?.city},
                  {user?.currentOrganisation?.address?.postalCode},
                  {user?.currentOrganisation?.address?.country}
                </p>
              </>
            )}
            <p>
              {user?.currentOrganisation?.phoneNumber}
            </p>
            <p>
              {user?.currentOrganisation?.email}
            </p>
          </div>
          <div className={classes.contact_transaction_title}>
            <p className={classes.contact_transaction_title_name}>
              {`${translations.transaction_type[transaction.type as keyof TranslationDashboardModel['transaction_type']]} # ${transaction?.id}`}
            </p>
            <p className={isActive
              ? classes.contact_transaction_title_status
              : classes.contact_transaction_title_status_paid}
            >
              {translations.status[transaction?.status as keyof TranslationDashboardModel['status']]}
            </p>
          </div>
        </div>
        <div className={classes.contact}>
          <h2 className={classes.contact_title}>
            {contactTitle()}
          </h2>
          <div className={classes.contact_address}>
            <p>
              {`${transaction?.contact?.firstName || ''} ${transaction?.contact?.lastName || ''}`}
            </p>
            {isMobile ? (
              <p>
                {transaction?.contact?.address?.line1}
                {transaction?.contact?.address?.city}
                {transaction?.contact?.address?.postalCode}
                {transaction?.contact?.address?.country}
              </p>
            ) : (
              <>
                <p>
                  {transaction?.contact?.address?.line1}
                </p>
                <p>
                  {transaction?.contact?.address?.city}
                  {transaction?.contact?.address?.postalCode}
                  {transaction?.contact?.address?.country}
                </p>
              </>
            )}
            <p>
              {transaction?.contact?.mobileNumber}
            </p>
            <p>
              {transaction?.contact?.email}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
