import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getIncomeStatementData } from 'store/dashboard/report/reportActions';
// hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslations } from 'hooks/useTranslations';
import { useUser } from 'hooks/useUser';
// utils
import { CurrencyFormatByISO } from 'utils/price';
import { getYear } from 'utils/dates';
// styles
import CustomSpinner from 'components/CustomSpinner';
import classes from './IncomeStatementTab.module.scss';

export interface IncomeStatementData {
  paidInvoicesAmount: number;
  unpaidInvoicesAmount: number;
  paidBillsAmount: number;
  unpaidBillsAmount: number;
  incomeTaxesAmount: number;
  totalSalesAmount: number;
  totalExpenseAmount: number;
  totalIncomeAmount: number;
  totalProfitOrLossAmount: number;
}

const IncomeStatementTab: React.FC = () => {
  const dispatch = useDispatch();
  const translations = useTranslations();
  const user = useUser();
  const { incomeStatement, loading } = useTypeSelector(
    ({ dashboard }) => dashboard.report,
  );
  const ts = translations.home.report.income_statement;
  const isLoss = !!incomeStatement?.totalProfitOrLossAmount
    && incomeStatement?.totalProfitOrLossAmount <= 0;
  const formatCurrencyByISO = CurrencyFormatByISO(user?.currentOrganisation?.address?.currencyISO || 'USD');
  useEffect(() => {
    if (user?.currentOrganisation?.id) {
      dispatch(
        getIncomeStatementData(
          user.currentOrganisation.id,
        ),
      );
    }
  }, [dispatch, user]);

  if (loading) {
    return <CustomSpinner variant="two" />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.year}>{`${getYear()} ${ts.ytd}`}</div>
      <h1 className={classes.root_title}>
        {ts.title}
      </h1>
      <div className={classes.box}>
        <div className={classes.main_col}>
          <h2 className={classes.title}>
            {ts.sales}
          </h2>
          <p className={classes.value}>{formatCurrencyByISO(incomeStatement?.totalSalesAmount)}</p>
        </div>
        <div className={classes.sub_col}>
          <h2 className={classes.title}>
            {ts.actual_paid_invoice}
          </h2>
          <p className={classes.value}>
            {formatCurrencyByISO(incomeStatement?.paidInvoicesAmount)}
          </p>
        </div>
        <div className={classes.sub_col}>
          <h2 className={classes.title}>
            {ts.accounts_receivables_invoice}
          </h2>
          <p className={classes.value}>
            {formatCurrencyByISO(incomeStatement?.unpaidInvoicesAmount)}
          </p>
        </div>
      </div>
      <div className={classes.box}>
        <div className={classes.main_col}>
          <h2 className={classes.title}>
            {ts.expenses}
          </h2>
          <p className={classes.value}>
            {`${formatCurrencyByISO(1)[0]} (${formatCurrencyByISO(incomeStatement?.totalExpenseAmount).slice(3)})`}
          </p>
        </div>
        <div className={classes.sub_col}>
          <h2 className={classes.title}>
            {ts.actual_paid_bills}
          </h2>
          <p className={classes.value}>
            {`${formatCurrencyByISO(1)[0]} (${formatCurrencyByISO(incomeStatement?.paidBillsAmount).slice(3)})`}
          </p>
        </div>
        <div className={classes.sub_col}>
          <h2 className={classes.title}>
            {ts.accounts_payables_bills}
          </h2>
          <p className={classes.value}>
            {`${formatCurrencyByISO(1)[0]} (${formatCurrencyByISO(incomeStatement?.paidBillsAmount).slice(3)})`}
          </p>
        </div>
      </div>
      <div className={classes.box}>
        <div className={classes.main_col}>
          <h2 className={classes.title}>
            {ts.income_taxes}
          </h2>
          <p className={classes.value} />
        </div>
        <div className={classes.sub_col}>
          <h2 className={classes.title}>
            {ts.income}
          </h2>
          <p className={classes.value}>
            {formatCurrencyByISO(incomeStatement?.totalIncomeAmount)}
          </p>
        </div>
        <div className={classes.sub_col}>
          <h2 className={classes.title}>
            {ts.taxes}
          </h2>
          <p className={classes.value}>
            {`${formatCurrencyByISO(1)[0]} (${formatCurrencyByISO(incomeStatement?.incomeTaxesAmount).slice(3)})`}
          </p>
        </div>
      </div>
      <div className={classes.box_total}>
        <div className={classes.total}>
          <p className={classes.title}>{ts.re_profit_loss}</p>
          <p className={classes.value} style={{ color: isLoss ? 'rgba(246, 75, 60, 1)' : 'rgba(107, 89, 237, 1)' }}>
            {isLoss
              ? `(${formatCurrencyByISO(Math.abs(incomeStatement?.totalProfitOrLossAmount))})`
              : formatCurrencyByISO(incomeStatement?.totalProfitOrLossAmount)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default IncomeStatementTab;
