import React from 'react';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useUser } from 'hooks/useUser';
import { useTranslations } from 'hooks/useTranslations';
// Styles
import classes from './ProfileCard.module.scss';

interface Props {
  contactId: number;
}

const ProfileCard: React.FC<Props> = () => {
  const user = useUser();
  const translations = useTranslations();
  const { details } = useTypeSelector(({ directory }) => directory);
  const name = `${details.profile?.firstName} ${details.profile?.lastName}`;
  const phone = `${user?.currentOrganisation?.address?.idd} ${details.profile?.mobileNumber}`;

  if (!details.type) {
    return null;
  }

  return (
    <div className={classes.profile_card}>
      <div className={classes.profile_card_left}>
        <h3 className={classes.profile_card_left_name}>
          {name}
        </h3>
        <p className={classes.profile_card_left_address}>
          {`${details.profile?.address?.address1 || ''}
          ${details.profile?.address?.city || ''}
          ${details.profile?.address?.postalCode || ''}`}
        </p>
        <p className={classes.profile_card_left_phone}>
          {phone}
        </p>
        <p className={classes.profile_card_left_email}>
          {details.profile?.email}
        </p>
      </div>
      <div className={classes.profile_card_right}>
        {translations.directory.details.type[details.type]}
      </div>
    </div>
  );
};

export default ProfileCard;
