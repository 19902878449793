/* eslint-disable no-nested-ternary */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Element } from 'react-scroll';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useQuery } from 'hooks/useQuery';
// Types
import { InvoicePaymentLoadingEnum } from 'store/invoice/invoiceTypes';
// Actions
import {
  getInvoiceData,
  invoicePaymentSuccess,
} from 'store/invoice/invoiceActions';
// Types
import { TransactionStatus } from 'types/globalTypes';
// Components
import DocumentsGetApp from 'components/Documents/GetApp';
import Contacts from 'components/Documents/Contacts';
import LoadingPage from 'pages/LoadingPage';
import DocumentsLayout from 'layouts/DocumentsLayout';
import Title from 'components/Documents/Title';
import Status from 'components/Documents/Status';
import ItemsTable from 'components/Documents/ItemsTable';
import PayTable from 'components/Documents/PayTable';
import Notes from 'components/Documents/Notes';
import PayPalLoading from 'pages/Storefront/Checkout/PayPalLoading';
import PaymentModal from './PaymentModal';

interface IParams {
  id: string;
  publicId: string;
}

const Invoice: React.FC = () => {
  const { id, publicId } = useParams<IParams>();
  const dispatch = useDispatch();
  const query: any = useQuery();
  const { loading, data, payment } = useTypeSelector((state) => state.invoice);
  const [amount, setAmount] = useState<number>(0);
  const [openPayModal, setOpenPayModal] = useState(false);
  const history = useHistory();
  const translations = useTranslationsStorefront();
  const isUnpaid = useMemo(
    () => data?.termsPayment.remainingBalance
      && Math.sign(data?.termsPayment.remainingBalance || 0) !== -1,
    [data?.termsPayment.remainingBalance],
  );

  useEffect(() => {
    if (!data?.price.total) {
      return;
    }

    setAmount(data?.price.total);
  }, [data?.price.total]);

  useEffect(() => {
    if (!data?.items.length) {
      dispatch(getInvoiceData(id, publicId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (payment.success) {
      dispatch(getInvoiceData(id, publicId));
      dispatch(invoicePaymentSuccess(false));
      history.go(0);
    }
  }, [dispatch, history, id, payment.success, publicId]);

  const handlePayModal = useCallback(() => {
    setOpenPayModal((prev) => !prev);
  }, []);

  const handlePayPaymentSchedule = useCallback(
    (total) => {
      setAmount(total);
      handlePayModal();
    },
    [handlePayModal],
  );

  if (!data || loading) {
    return <LoadingPage />;
  }

  return (
    <DocumentsLayout data={{
      organisation: data.organisation,
    }}
    >
      <Title
        name={translations.documents.invoice}
        number={data.details.reference}
      />
      <Status
        status={isUnpaid ? TransactionStatus.UNPAID : TransactionStatus.PAID}
        date={data.date.toString()}
        dueDate={data?.dueDate}
        isPaymentScheduled={!!(isUnpaid && data.termsPayment.paymentSchedule)}
      />
      <Contacts
        organisation={data.organisation}
        contact={data.contact}
        translations={translations.documents.contacts}
        type={data.details.type}
      />
      <ItemsTable items={data.items} price={data.price} />
      <Element name="Schedule">
        <PayTable
          balance={data.termsPayment.remainingBalance}
          deposit={data.termsPayment.firstPaymentAmount}
          scheduleItems={data.termsPayment.paymentSchedule}
          handlePaymentTotal={handlePayModal}
          handlePaymentSchedule={handlePayPaymentSchedule}
          currencyIso={data.price.currencyIso}
          paidOn={data.updatedAt?.toString()}
          payments={data.termsPayment.payments}
        />
      </Element>
      <Notes
        title={translations.documents.notes}
        notes={data.termsPayment.notes}
      />
      <DocumentsGetApp
        type={data.details.type}
        name={data.contact.fullName}
        phone={data.contact.mobileNumber}
        text={translations.documents.send_message.title}
      />
      <PaymentModal
        active={openPayModal}
        setActive={handlePayModal}
        organisation={data.organisation}
        payAmount={amount}
      />
      {payment.loading === InvoicePaymentLoadingEnum.PAY && (
        <LoadingPage
          title={translations.checkout.paypal_loading.title}
          backgroundColor="rgba(32, 39, 43, 0.8)"
        />
      )}
      {payment.loading === InvoicePaymentLoadingEnum.PAYPAL && (
        <PayPalLoading />
      )}
    </DocumentsLayout>
  );
};

export default Invoice;
