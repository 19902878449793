import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useStorefrontRoutes } from 'hooks/useStorefrontRoutes';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { StorefrontRoutePath } from 'router/Routes/Storefront';
import { useQuery } from 'hooks/useQuery';
// Actions
import {
  checkoutSetReadyAction,
  checkoutSetItemsAction,
  checkoutSetFulfillmentAction,
  onError,
  onSuccess,
  checkoutSetOrder,
  getCustomerInfo,
  checkIsLogin,
  checkout as checkoutAPI,
} from 'store/storefront/checkout/checkoutActions';
import { SetAddToBagAction } from 'store/storefront/shop/shopActions';
// Types
import { CheckoutState, LoadingEnum } from 'store/storefront/checkout/checkoutTypes';
// Components
import StorefrontLayout from 'layouts/StorefrontLayout';
import LoadingPage from 'pages/LoadingPage';
import ModalFail from 'components/Modal/ModalFail';
import { NotificationEnum } from 'store/storefront/shop/shopTypes';
import { setNotificationAction } from 'store/storefront/app/appActions';
import LinkCard from '../ShoppingCart/LinkCard';
import Payments from '../ShoppingCart/Payments';
import Summary from './Summary';
import CustomerInfoCard from './CustomerInfoCard';
import CustomerInfoModal from './CustomerInfoModal';
import ShippingCard from './ShippingCard';
import ItemList from './ItemList';
import ShippingModal from './ShippingModal';
import MoreInfoModal from './MoreInfoModal';
import PaymentModal from './PaymentModal';
import PlacingOrder from './PlacingOrder';
import PaymentError from './PaymentError';
import PayPalLoading from './PayPalLoading';
import InfoModal from './InfoModal';
// Styles
import classes from './Checkout.module.scss';

const Checkout: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const {
    items,
    order,
    fulfillment,
    isUser,
    isNewUser,
  } = useTypeSelector(({ storefront }) => storefront.checkout);
  const { notification } = useTypeSelector(({ storefront }) => storefront.app);
  const {
    error,
    loading,
    currentSalesChannel,
  } = useTypeSelector(({ storefront }) => storefront.shop);
  const translations = useTranslationsStorefront();

  const dispatch = useDispatch();
  const { goToShop, goToCart, goToOrder } = useStorefrontRoutes();
  const { cart, server_error, checkout } = useTranslationsStorefront();
  const cache = localStorage.getItem(`leja-${id}-checkout`);
  const [openCustomerInfoModal, setOpenCustomerInfoModal] = useState(false);
  const [openSignInModal, setOpenSignInModal] = useState(false);
  const [openShippingModal, setOpenShippingModal] = useState(false);
  const [openPayModal, setOpenPayModal] = useState(false);
  const [openMoreInfo, setOpenMoreInfo] = useState(false);

  const currentShippingCountry = useMemo(
    () => currentSalesChannel?.fulfilment?.shipping?.find(
      (address) => fulfillment?.contact?.address?.countryISO === address.countryISO,
    ),
    [
      currentSalesChannel?.fulfilment?.shipping,
      fulfillment?.contact?.address?.countryISO],
  );

  const currentDeliveryPlace = useMemo(
    () => currentSalesChannel?.fulfilment?.delivery?.find(
      (serviceArea) => serviceArea.countryISO === fulfillment?.contact?.address?.countryISO,
    ),
    [
      currentSalesChannel?.fulfilment?.delivery,
      fulfillment.contact?.address?.city,
      fulfillment?.contact?.address?.countryISO,
    ],
  );

  const currentPickupCountry = useMemo(
    () => currentSalesChannel?.fulfilment?.pickup?.filter(
      ((loc) => loc.address.countryISO === fulfillment?.contact?.address?.countryISO),
    ), [currentSalesChannel?.fulfilment?.pickup, fulfillment?.contact?.address?.countryISO],
  );

  const handlePayModal = useCallback(async () => {
    if (currentSalesChannel?.address.countryISO === 'NG') {
      const data = await checkoutAPI(
        id,
        'INSTANT',
        items,
        fulfillment,
        currentSalesChannel,
        'PAYSTACK',
      );

      if (!data) {
        dispatch(checkoutSetOrder({
          ...order,
          loading: null,
          error: translations.server_error,
        }));
      } else {
        window.location.href = data?.payment.data.authorization_url;
      }
    } else {
      setOpenPayModal((prev) => !prev);
    }
  }, [id, order, items, fulfillment, currentSalesChannel, translations, dispatch]);

  const handleOpenMoreInfo = useCallback(() => {
    setOpenMoreInfo((prev) => !prev);
  }, []);

  const handleOpenCustomerInfoModal = useCallback(() => {
    setOpenCustomerInfoModal(true);
  }, []);

  const handleSignIn = useCallback(() => {
    setOpenSignInModal((prev) => !prev);
  }, []);

  const handleShippingModal = useCallback(() => {
    setOpenShippingModal((prev) => !prev);
  }, []);

  const handleGoToCart = useCallback(() => {
    goToCart(id, location);
    localStorage.removeItem(`leja-${id}-checkout`);
  }, [goToCart, id, location]);

  const handleToShop = useCallback(() => {
    goToShop(id, location.search);
    localStorage.removeItem(`leja-${id}-checkout`);
  }, [goToShop, id, location.search]);

  const handleError = useCallback(() => {
    dispatch(onError(null, order));
  }, [dispatch, order]);

  useEffect(() => {
    if (!cache) {
      goToShop(id, location.search);
      return;
    }

    const parsedCache: CheckoutState = JSON.parse(cache);
    dispatch(checkoutSetReadyAction(parsedCache.isReadyForCheckout));
    dispatch(checkoutSetItemsAction(parsedCache.items));
    dispatch(checkoutSetFulfillmentAction(parsedCache.fulfillment));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage]);

  useEffect(() => {
    if (order.isPayed) {
      const orderId = localStorage.getItem(`leja-${id}-order`);
      dispatch(SetAddToBagAction([]));
      localStorage.removeItem(`leja-${id}-cart`);
      localStorage.removeItem(`leja-${id}-checkout`);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      goToOrder(id, order.id! || orderId!, location);
    }
  }, [dispatch, goToOrder, id, location, order.id, order.isPayed]);

  useEffect(() => {
    if (query.get('redirect_status') === 'succeeded') {
      dispatch(onSuccess(order));
    }

    if (query.get('redirect_status') === 'failed') {
      dispatch(checkoutSetOrder({
        ...order,
        loading: null,
        error: translations.server_error,
      }));
      query.delete('redirect_status');
      history.replace({
        search: query.toString(),
      });
    }
  }, [dispatch, goToOrder, history, id, order, query, translations.server_error]);

  useEffect(() => {
    if (!isNewUser) {
      dispatch(checkIsLogin());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isUser && !fulfillment.contact?.id && !isNewUser) {
      dispatch(getCustomerInfo(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfillment.contact?.id, id, isUser]);

  if (error) {
    return (
      <div className={classes.root}>
        <h1 className={classes.error}>
          {server_error}
        </h1>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <StorefrontLayout type="two" handleClose={handleToShop} />
      {(items.length === 0 || loading || currentSalesChannel === undefined) && <LoadingPage />}
      <div className={classes.main}>
        <div className={classes.title}>
          {checkout.title}
        </div>
        <div className={classes.customer_info}>
          <CustomerInfoCard
            onClick={() => setOpenSignInModal(true)}
            handleEdit={handleOpenCustomerInfoModal}
            fulfillment={fulfillment}
          />
        </div>
        <div className={classes.shipping_info}>
          <ShippingCard
            existInfoCustomer={!!fulfillment.contact?.id}
            onClick={handleShippingModal}
            handleOpenMoreInfo={handleOpenMoreInfo}
            isShipping={!(currentPickupCountry && !!currentPickupCountry.length)
              && !currentDeliveryPlace && !currentShippingCountry}
          />
        </div>
        <div className={classes.item_list}>
          <ItemList />
        </div>
        <div className={classes.summary}>
          <Summary
            handleCheckout={handlePayModal}
            handleContinueShopping={handleGoToCart}
            existInfoCustomer={!!fulfillment.contact?.id && !!fulfillment.contact?.email}
            currDeliveryPlace={currentDeliveryPlace}
            currentShippingCountry={currentShippingCountry}
            currentPickupCountry={currentPickupCountry}
          />
        </div>
        <div className={classes.payments}>
          <Payments />
        </div>
        <div className={classes.link_card}>
          <LinkCard
            title={cart.links.title_shipping}
            link={`${StorefrontRoutePath.SHIPPING.replace(':id', id)}${location.search}`}
            link_label={cart.links.label_shipping}
          />
        </div>
        <div className={classes.link_card}>
          <LinkCard
            title={cart.links.title_return_policy}
            link={`${StorefrontRoutePath.REFUND_POLICY.replace(':id', id)}${location.search}`}
            link_label={cart.links.label_return_policy}
          />
        </div>
      </div>
      {order.loading === LoadingEnum.PAY && (
        <LoadingPage
          title={checkout.paypal_loading.title}
          backgroundColor="rgba(32, 39, 43, 0.8)"
        />
      )}
      {order.loading === LoadingEnum.PAYPAL && (
        <PayPalLoading />
      )}

      {order.loading === LoadingEnum.SET_ORDER && (
        <PlacingOrder />
      )}
      {notification === NotificationEnum.ERROR_NOT_WALLET && (
        <ModalFail
          handleClick={() => dispatch(setNotificationAction(null))}
          text={checkout.errors.not_wallets}
          buttonText={checkout.payment_error_modal.title_btn}
        />
      )}
      {order.error && <PaymentError active={!!order.error} setActive={handleError} />}
      <CustomerInfoModal
        active={openCustomerInfoModal}
        setActive={setOpenCustomerInfoModal}
      />
      <InfoModal active={openSignInModal} setActive={handleSignIn} />
      <ShippingModal
        active={openShippingModal}
        handleModal={handleShippingModal}
        contact={fulfillment.contact}
        currDeliveryPlace={currentDeliveryPlace}
        currentShippingCountry={currentShippingCountry}
        currentPickupCountry={currentPickupCountry}
      />
      <MoreInfoModal active={openMoreInfo} handleModal={handleOpenMoreInfo} />
      <PaymentModal active={openPayModal} setActive={handlePayModal} />
    </div>
  );
};

export default Checkout;
