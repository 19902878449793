import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// Stripe
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { PaymentRequest } from '@stripe/stripe-js';
// Hooks
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useQuery } from 'hooks/useQuery';
// Utils
import { PaymentFunctions } from 'utils/storefront/PaymentFunctions';
// Actions
import { setNotificationAction } from 'store/storefront/app/appActions';
// Types
import { NotificationEnum } from 'store/storefront/shop/shopTypes';
// Constants
import { PayMethodsEnum } from 'constants/paymentsMethods';
// Components
import Modal from 'components/Modal';
import CardFormModal from './CardFormModal';

interface Props {
  setActive: () => void;
  handleError: (error: unknown) => void;
  onSuccess: () => void;
  handleLoading: () => void;
  active: boolean;
  clientSecret?: string
  type?: PayMethodsEnum | null
  total: number;
}

const Payment: React.FC<Props> = ({
  active, setActive, clientSecret, type, total, handleError, handleLoading, onSuccess,
}) => {
  const query = useQuery();

  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);
  const { data } = useTypeSelector((state) => state.invoice);

  const { notification } = useTypeSelector(({ storefront }) => storefront.app);

  const translations = useTranslationsStorefront();
  const dispatch = useDispatch();
  const history = useHistory();

  const currencyIso = data?.organisation?.address.currencyISO || 'EUR';
  const totalPrice = total;
  const fullName = `${data?.contact?.fullName} ${data?.contact?.lastName}`;

  const handleSuccess = useCallback(() => {
    onSuccess();
    setActive();
  }, [onSuccess, setActive]);

  const handleNotificationError = (typeNotification: NotificationEnum) => {
    dispatch(setNotificationAction(typeNotification));
  };

  useEffect(() => {
    const PaymentIntentClientSecret = query.get('payment_intent_client_secret');
    if (PaymentIntentClientSecret && stripe) {
      const fetchPaymentIntent = async () => {
        const { error, paymentIntent } = await stripe.retrievePaymentIntent(
          PaymentIntentClientSecret,
        );

        if (error) {
          handleError(error);
        }

        if (paymentIntent?.status === 'requires_payment_method') {
          handleError(error);
        }

        if (paymentIntent?.status === 'succeeded') {
          handleSuccess();
        }
      };
      fetchPaymentIntent();
      query.delete('payment_intent_client_secret');
      query.delete('payment_intent');

      history.replace({
        search: query.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, query, stripe]);

  useEffect(() => {
    if (!stripe || !clientSecret) {
      return;
    }

    if (type === PayMethodsEnum.GIROPAY) {
      return;
    }

    PaymentFunctions.handlePay(
      handleLoading,
      handleError,
      handleSuccess,
      handleNotificationError,
      stripe,
      clientSecret,
      setPaymentRequest,
      fullName,
      data?.organisation?.address?.countryISO || 'US',
      currencyIso,
      translations.checkout.price_details.total_label,
      totalPrice,
      type,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, type]);

  if (type === PayMethodsEnum.CARD) {
    return (
      <CardFormModal
        active={!!active}
        setActive={setActive}
        clientSecret={clientSecret}
        translation={translations.checkout.card_form}
        handleSuccess={handleSuccess}
        handleNotificationError={handleNotificationError}
        handleError={handleError}
        handleLoading={handleLoading}
        total={total}
      />
    );
  }

  if ((type === PayMethodsEnum.APPLE || type === PayMethodsEnum.GOOGLE)
    && notification !== NotificationEnum.ERROR_NOT_WALLET) {
    return (
      <Modal active={!!active} setActive={setActive}>
        {paymentRequest && (
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        )}
      </Modal>
    );
  }
  return null;
};

export default Payment;
