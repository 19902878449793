/* eslint-disable no-console */
import { AppThunk } from 'store';
// API_URL
import { ApiOrgPublicId, ApiOrgPublicIdPatch } from 'services/api';
import { Items, Payments, TransactionStatus } from 'types/globalTypes';
import { dateFormatA } from 'utils/dates';
import { checkStatusExpired, getStatus, isEmptyObject } from 'utils/functions';
import { TransactionTypes } from 'store/dashboard/transaction/transactionTypes';
import { calcSubtotal } from 'utils/price';
import {
  ESTIMATE_LOADING,
  EstimateActions,
  ESTIMATE_GET_DATA,
  ESTIMATE_NOTIFICATION,
  IEstimateData,
  ESTIMATE_GET_STATUS,
  ESTIMATE_GET_ACCEPT,
} from './estimateTypes';

const EstimateLoadingAction = (payload: boolean): EstimateActions => ({
  type: ESTIMATE_LOADING,
  payload,
});

const EstimateLoadingAcceptAction = (payload: boolean): EstimateActions => ({
  type: ESTIMATE_GET_ACCEPT,
  payload,
});

export const EstimateNotificationAction = (
  payload: boolean,
): EstimateActions => ({
  type: ESTIMATE_NOTIFICATION,
  payload,
});

const estimateGetDataAction = (payload: IEstimateData): EstimateActions => ({
  type: ESTIMATE_GET_DATA,
  payload,
});

const EstimateGetStatusAction = (
  payload: TransactionStatus,
): EstimateActions => ({
  type: ESTIMATE_GET_STATUS,
  payload,
});

export const getEstimateData = (id: string, publicId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(EstimateLoadingAction(true));
    await ApiOrgPublicId('/p/organisations', publicId);
    const data = await ApiOrgPublicId(`/p/sales-orders/${id}`, publicId);

    if (data) {
      const subtotal = calcSubtotal(data);
      const estimateData = {
        organisation: data.organisation,
        contact: {
          fullName: `${data.contact.firstName || ''} ${
            data.contact.lastName || ''
          }`,
          ...data.contact,
        },
        details: {
          type: TransactionTypes.SALES_ORDER,
          reference: data.id.substring(0, 5),
          createdAt: dateFormatA(data.createdAt),
          status: checkStatusExpired(data),
          dueDate: data.dueDate,
        },
        items: data.items,
        price: {
          currencyIso: data?.currencyISO,
          subtotal,
          discount: data.totalDiscount,
          total: subtotal - data.totalDiscount,
          paymentMethod:
              data?.payments && data?.payments?.length !== 0
                ? data?.payments[0]
                : '',
        },
        payments: data.payments,
        termsPayment: {
          date: data.date,
          dueDate: data.terms.paymentDueDate,
          instalements:
              getStatus(data.statusId) !== TransactionStatus.PAID
              && data?.terms !== null,
          notes: data.notes,
          updatedAt: data.updatedAt,
          payments: data.payments,
          remainingBalance: data.balance,
          amountPaid:
              data?.payments && data?.payments?.length
                ? data?.payments.reduce(
                  (acc: number, curr: Payments) => acc + curr.amount,
                  0,
                )
                : '',
        },
      };
      dispatch(EstimateLoadingAction(false));
      dispatch(estimateGetDataAction(estimateData));
    }
  } catch (e) {
    console.error(e);
  }
};

export const updateUnexpiredEstimate = (
  id: string,
  status: TransactionStatus,
  estimate: IEstimateData,
  publicId: string,
): AppThunk => async (dispatch) => {
  try {
    dispatch(EstimateLoadingAcceptAction(true));
    const data = await ApiOrgPublicIdPatch(
      `/p/sales-orders/${id}`,
      {
        status: status === TransactionStatus.ACCEPTED ? 'ACCEPTED' : 'REJECTED',
      },
      publicId,
    );

    const estimateData = {
      ...estimate,
      details: {
        ...estimate.details,
        status: checkStatusExpired(data),
      },
      termsPayment: {
        dueDate: data.terms.paymentDueDate,
        instalements:
            getStatus(data.statusId) !== TransactionStatus.PAID
            && data?.terms !== null,
        notes: data.notes,
        updatedAt: data.updatedAt,
        payments: data.payments,
        remainingBalance: data.balance,
        amountPaid:
            data?.payments && data?.payments.length
              ? data?.payments.reduce(
                (acc: number, curr: Payments) => acc + curr.amount,
                0,
              )
              : '',
      },
    };
    if (data) {
      dispatch(EstimateNotificationAction(true));
      dispatch(EstimateLoadingAcceptAction(false));
      dispatch(estimateGetDataAction(estimateData));
      dispatch(EstimateGetStatusAction(checkStatusExpired(data)));
    }
  } catch (error) {
    console.error(error);
  }
};
