import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { HelmetProvider, Helmet } from 'react-helmet-async';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Actions
import { getTranslations } from 'store/storefront/app/appActions';
// Components
import Footer from 'layouts/DocumentsLayout/Footer';
import CompanyAvatar from 'components/StorefrontComponents/CompanyAvatar';
// Styles
import { SalesChannel } from 'store/storefront/shop/shopTypes';
import { IOrganisationData } from 'types/globalTypes';
import classes from './DocumentsLayout.module.scss';

interface Props {
  data: {
    organisation: IOrganisationData;
  };
}

const DocumentsLayout: React.FC<Props> = ({ children, data }) => {
  const dispatch = useDispatch();
  const translations = useTranslationsStorefront();
  const { activeTranslation, isTranslationsFetched } = useTypeSelector(
    ({ storefront }) => storefront.app,
  );

  useEffect(() => {
    if (!isTranslationsFetched || !translations.shopTranslations) {
      dispatch(getTranslations('en'));
    }
  }, [
    isTranslationsFetched,
    dispatch,
    activeTranslation,
    translations.shopTranslations,
  ]);

  return (
    <HelmetProvider>
      <div className={classes.layout}>
        <Helmet>
          <meta
            name="description"
            content="Leja Invoices Features: ✓ Automatic Reminders, ✓ACH Payments, ✓ Online Invoices, ✓ Credit Card Payments, ✓ CRM."
          />
          <meta
            name="keywords"
            content="Online invoicing software, invoices, Leja invoices, estimates, expense tracking, invoicing, online invoices"
          />
        </Helmet>
        <CompanyAvatar
          title={data?.organisation.name || ''}
          logoUrl={data?.organisation?.logo || ''}
        />
        {children}
        <Footer organisation={data.organisation} />
      </div>
    </HelmetProvider>
  );
};

export default DocumentsLayout;
