import { useDashboardRoutes } from 'hooks/useDashboardRoutes';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useTranslations } from 'hooks/useTranslations';
import { useUser } from 'hooks/useUser';
import React from 'react';
import { Link } from 'react-router-dom';
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
import { Destructuring } from 'utils/dashboard/directory/destructuring';
import { formatReg } from 'utils/dates';
import { CurrencyFormatByISO } from 'utils/price';
// Classes
import classes from './Transaction.module.scss';

interface Props {
  item: Destructuring;
  isOrders: boolean;
}

const Transaction: React.FC<Props> = ({ item, isOrders }) => {
  const { isMobile } = useMobileScreen();
  const { pathToOrderDetails, pathToTransactionDetails } = useDashboardRoutes();
  const translations = useTranslations();
  const user = useUser();
  const formatCurrencyByISO = CurrencyFormatByISO(user?.currentOrganisation?.address?.currencyISO || 'USD');

  return isMobile ? (
    <Link key={item.id} className={classes.item_mb} to={isOrders ? pathToOrderDetails(`${item.id}`) : pathToTransactionDetails(`${item.id}`, item.type)}>
      <div className={classes.item_mb_header}>
        <div className={classes.item_mb_header_transaction_id}>
          <span className={classes.item_mb_header_transaction_id_label}>
            {translations.directory.details.headers[0]}
          </span>
          <span className={classes.item_mb_header_transaction_id_value}>{item.id}</span>
        </div>
        <div className={classes.item_mb_header_date}>
          <span className={classes.item_mb_header_date_label}>
            {translations.directory.details.headers[1]}
          </span>
          <span className={classes.item_mb_header_date_value}>{formatReg(item.date)}</span>
        </div>
      </div>
      <p className={classes.item_mb_name}>{item.name}</p>
      <p className={classes.item_mb_value}>{formatCurrencyByISO(item.amount)}</p>
      <div className={classes.item_mb_bottom}>
        <div className={classes.item_mb_bottom_first_row}>
          <p>{item.type}</p>
          <div>{translations.status[item.status as keyof TranslationDashboardModel['status']]}</div>
        </div>
      </div>
    </Link>
  ) : (
    <Link
      key={item.id}
      className={classes.table_content_item}
      to={isOrders ? pathToOrderDetails(`${item.id}`) : pathToTransactionDetails(`${item.id}`, item.type)}
    >
      <p>{item.id}</p>
      <p>{formatReg(item.date)}</p>
      <p>{item.name}</p>
      <p>{item.type}</p>
      <p>{translations.status[item.status as keyof TranslationDashboardModel['status']]}</p>
      <p>{item.amount ? formatCurrencyByISO(item.amount) : '-'}</p>
    </Link>
  );
};

export default Transaction;
