import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Constants
import { DeliveryMethodsEnum } from 'constants/deliveryMethods';
// Types
import { IOrderData } from 'store/storefront/order/orderTypes';
// Utils
import { CurrencyFormatByISO } from 'utils/price';
// Styles
import { useDocumentRoutes } from 'hooks/useDocumentRoutes';
import { useDispatch } from 'react-redux';
import { setTranslationsFetchedAction } from 'store/auth/authActions';
import classes from './Payment.module.scss';

interface Props {
  fulfillmentType: DeliveryMethodsEnum;
  status: IOrderData['status']['name'];
}

const Payment: React.FC<Props> = ({ status }) => {
  const { data: order } = useTypeSelector(({ storefront }) => storefront.order);
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);
  const formatCurrencyByISO = useMemo(
    () => CurrencyFormatByISO(currentSalesChannel?.address.currencyISO),
    [currentSalesChannel?.address.currencyISO],
  );
  const { order: { payment: translations } } = useTranslationsStorefront();
  const { goToInvoice } = useDocumentRoutes();
  const dispatch = useDispatch();

  const handleOpenInvoice = useCallback(() => {
    dispatch(setTranslationsFetchedAction(false));
    goToInvoice(`${order?.associations?.id}`);
  }, [dispatch, goToInvoice, order?.associations?.id]);

  if (status === 'CANCELLED') {
    return <div className={classes.cancelled} />;
  }

  if (status === 'UNPAID') {
    return (
      <div className={classes.payment}>
        <div className={cn(classes.payment_left, {
          [classes.payment_left_unpaid]: status === 'UNPAID',
        })}
        >
          <div className={classes.payment_left_title}>
            {translations.pending_payment}
          </div>
          <div className={classes.payment_left_value}>
            {formatCurrencyByISO(order?.balance)}
          </div>
        </div>
        <button type="button" className={classes.payment_button} disabled={false} onClick={handleOpenInvoice}>
          {translations.pay_balance}
        </button>
      </div>
    );
  }

  return (
    <div className={classes.payment}>
      {order?.payments?.map((payment) => (
        <div className={classes.payment_left}>
          <div className={classes.payment_left_title}>
            {translations.pay_with}
          </div>
          <div className={classes.payment_left_value}>
            {payment.paymentMethod}
          </div>
        </div>
      ))}
      <button type="button" className={classes.payment_button} disabled={!order?.associations?.id} onClick={handleOpenInvoice}>
        {translations.invoice}
      </button>
    </div>
  );
};

export default Payment;
