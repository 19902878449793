import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
// Assets
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
// styles
import { QuoteGetDataAction } from 'store/quote/quoteActions';
import { calcTotalFees, quoteCurrencyFormatByISO } from 'utils/price';
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { QuotePriceType } from 'store/quote/quoteTypes';
import { calcTaxesQuote } from 'utils/functions';
import classes from './ModalAddDiscount.module.scss';

interface Props {
  handleClose: () => void;
  price: QuotePriceType;
  currentPrice?: number
  symbol?: string;
}

const ModalAddDiscount: React.FC<Props> = ({
  handleClose, price, currentPrice,
}) => {
  const {
    data,
  } = useTypeSelector((state) => state.quote);
  const [discount, setDiscount] = useState(price.discount);
  const { documents } = useTranslations();
  const formatCurrencyByISO = quoteCurrencyFormatByISO(price.currencyIso);
  const dispatch = useDispatch();
  const tsAddDiscount = documents.quotes.modal_add_discount;
  const handleChangeDiscount = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDiscount(Number(e.target.value));
    },
    [],
  );

  const newTotal = () => {
    if (discount === null) {
      return undefined;
    }

    return (price.total || 0) + (price.discount || 0) - Number(discount);
  };

  const handleSubmit = useCallback(() => {
    if (!data) {
      return;
    }
    const subtotal = () => {
      if (data.items.some((i) => i.price)) {
        return data.items.reduce(
          (acc: number, item) => acc + (item.price || 0) * item.quantity,
          0,
        );
      }
      return undefined;
    };

    const totalFees = calcTotalFees(data.price.fees);
    const taxes = calcTaxesQuote(data.items);

    const totalPrice = () => {
      const sbtl = subtotal();
      if (typeof sbtl === 'undefined') {
        return undefined;
      }

      return sbtl - Number(discount) + totalFees + (taxes.excluded ?? 0);
    };

    dispatch(QuoteGetDataAction({
      ...data,
      price: {
        ...data.price,
        discount: Number(discount),
        total: totalPrice(),
      },
    }));
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPrice, discount, handleClose]);

  return (
    <div className={classes.modal}>
      <div
        className={classes.modal_wrapper}
      >
        <div
          aria-hidden="true"
          onClick={handleClose}
          className={classes.modal_close}
        >
          <CloseIcon />
        </div>
        <div className={classes.modal_content}>
          <h2 className={classes.title}>
            {tsAddDiscount.title}
          </h2>
          <div className={classes.quote_total}>
            <p className={classes.quote_total_title}>
              {tsAddDiscount.quote_total}
            </p>
            <p className={classes.quote_total_price}>
              {formatCurrencyByISO((price.total || 0) + (price.discount || 0))}
            </p>
          </div>
          <form className={classes.price_form} onSubmit={handleSubmit}>
            <div className={classes.price_form_item}>
              <div className={classes.price_form_item_description}>
                <p className={classes.price_form_item_description_label}>
                  {tsAddDiscount.discount_amount.replace('{iso}', `${data?.organisation.address.currencySymbol}`)}
                </p>
              </div>
              <div>
                <input
                  className={classes.price_form_item_input}
                  type="number"
                  min="0"
                  step=".01"
                  id="discount"
                  value={discount}
                  onChange={handleChangeDiscount}
                  placeholder={tsAddDiscount.input_placeholder}
                />
              </div>
            </div>
            <div className={classes.price_form_item}>
              <div className={classes.price_form_item_description}>
                <p className={classes.price_form_item_description_label}>
                  {tsAddDiscount.new_total}
                </p>
              </div>
              <div>
                {formatCurrencyByISO(newTotal())}
              </div>
            </div>
            <div className={classes.add_discount_footer}>
              <div className={classes.add_discount_footer_left}>
                <p className={classes.add_discount_footer_left_title}>
                  {tsAddDiscount.title}
                </p>
                <p className={classes.add_discount_footer_left_price}>
                  {formatCurrencyByISO(Number(discount))}
                </p>
              </div>
              <input
                className={classes.add_discount_footer_btn}
                type="submit"
                value={tsAddDiscount.apply}
                disabled={!discount}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModalAddDiscount;
