import React, {
  useEffect, useState, Dispatch, SetStateAction, useCallback, useMemo,
} from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { FieldValues, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
// Utils
import { isValidPhoneNumber, parsePhoneNumber } from 'utils/phoneNumber';
import { getDefaultCode } from 'utils/functions';
// Types
import { ContactType } from 'types/globalTypes';
// Data
import phoneCodes from 'data/phone-codes.json';
import CountriesList from 'data/countries-list.json';
// Actions
import { patchCustomerInfo } from 'store/storefront/account/accountActions';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Components
import PhoneInput from 'components/StorefrontComponents/PhoneInput';
import CountrySelect from 'components/StorefrontComponents/CountrySelect';
import Modal from 'components/Modal';
import HeaderModal from 'components/StorefrontComponents/HeaderModal';
import DefaultInput from 'components/Inputs/DefaultInput';
import { FormData } from 'store/storefront/checkout/checkoutTypes';
import GooglePlacesInput from 'components/Inputs/GooglePlacesInput';
import InputEmail from 'pages/Storefront/Checkout/InfoModal/SignUp/InputEmail';
import Address from './Address';
// Styles
import classes from './AddressModal.module.scss';

interface Props {
  active: boolean;
  setActive: Dispatch<SetStateAction<boolean>>;
}

const AddressModal: React.FC<Props> = ({ active, setActive }) => {
  const { id } = useParams<{ id: string }>();
  const { contact } = useTypeSelector(({ storefront }) => storefront.account);
  const { geoInfo } = useTypeSelector(({ storefront }) => storefront.app);
  const { checkout, account } = useTranslationsStorefront();
  const dispatch = useDispatch();
  const [addManually, setAddManually] = useState<boolean>();
  const [address, setAddress] = useState<boolean>(false);
  const defaultCountryBrowser: { label: string, value: string } | undefined = useMemo(
    () => CountriesList.find(
      (item) => item.label === (contact?.address?.country || geoInfo?.countryName),
    ), [contact?.address?.country, geoInfo?.countryName],
  );

  const {
    register, handleSubmit, control, watch, setValue, getValues, reset, trigger,
    formState: {
      errors, isValid, isDirty,
    },
  } = useForm<FieldValues>({
    mode: 'onChange',
    defaultValues: useMemo(() => ({
      code: getDefaultCode(phoneCodes, defaultCountryBrowser?.value),
      phoneNumber: parsePhoneNumber(contact?.mobileNumber)?.number,
      country: defaultCountryBrowser,
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      email: contact?.email,
      city: contact?.address?.city,
      line1: contact?.address?.line1,
      line2: contact?.address?.line2,
      postCode: contact?.address?.postalCode,
      address: `${contact?.address?.line1} ${contact?.address?.postalCode} ${contact?.address?.city}`,
    }), [
      defaultCountryBrowser,
      contact,
    ]),
  });

  const watchCountry = watch('country');
  const watchPhoneNumber = watch('phoneNumber');
  const watchDialCode = watch('code');
  const watchAddress = watch('address');

  const isValidNumber = useMemo(() => {
    if (watchDialCode && watchPhoneNumber) {
      return isValidPhoneNumber(`${watchDialCode}${watchPhoneNumber}`);
    }

    return false;
  }, [watchDialCode, watchPhoneNumber]);

  const handleCloseModal = useCallback(() => {
    if (addManually) {
      setAddManually(false);
    } else {
      setActive(false);
    }
  }, [addManually, setActive]);

  const handleCloseModalAddress = useCallback(() => {
    setAddManually(false);
  }, []);

  const handleChangeLine2 = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('line2', e.target.value);
  }, [setValue]);

  const onSubmit = useCallback((data: FormData) => {
    window.scrollTo(0, 0);

    const updateContact: ContactType = {
      firstName: data.firstName,
      lastName: data.lastName,
      address: {
        line1: data.line1.trim(),
        line2: data.line2,
        city: data.city,
        postalCode: data.postCode,
        country: data.country.label,
        countryISO: data.country.value,
      },
      email: data.email,
      mobileNumber: `${data.code}${data.phoneNumber}`,
    };

    dispatch(patchCustomerInfo(updateContact, id));
    handleCloseModal();
  }, [dispatch, handleCloseModal, id]);

  const handleContinue = useCallback(
    () => {
      handleCloseModalAddress();
      setAddress(true);
      setValue('address', `${getValues().line1} ${getValues().postCode} ${getValues().city}`);
    },
    [getValues, handleCloseModalAddress, setValue],
  );

  const handleClose = useCallback(
    () => {
      if (!addManually) {
        setActive(false);
        return;
      }

      setAddress(false);
    },
    [addManually, setActive],
  );

  useEffect(() => {
    setValue('code', getDefaultCode(phoneCodes, watchCountry?.value));
  }, [setValue, watchCountry]);

  useEffect(() => {
    if (watchAddress) {
      setAddress(true);
    } else {
      setAddress(false);
    }
  }, [watchAddress]);

  useEffect(() => {
    reset({
      code: getDefaultCode(phoneCodes, defaultCountryBrowser?.value),
      country: defaultCountryBrowser,
      phoneNumber: parsePhoneNumber(contact?.mobileNumber)?.number,
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      email: contact?.email,
      city: contact?.address?.city,
      line1: contact?.address?.line1,
      line2: contact?.address?.line2,
      postCode: contact?.address?.postalCode,
      address: `${contact?.address?.line1} ${contact?.address?.postalCode} ${contact?.address?.city}`,
    });
    trigger();
  }, [defaultCountryBrowser,
    reset,
    trigger,
    contact,
  ]);

  return (
    <Modal active={active} setActive={setActive} className={classes.info_modal}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
        <div>
          <HeaderModal onClick={handleClose} className={classes.header} />
          <div className={classes.customer_info_main}>
            <h2 className={classes.customer_info_main_title}>
              {account.address.modal_title}
            </h2>
            <div className={cn(classes.form_info, {
              [classes.form_info_active]: !addManually,
            })}
            >
              <div className={classes.form_name}>
                <CountrySelect
                  control={control}
                  setValue={setValue}
                  addManually={addManually}
                />
              </div>
              <div className={classes.form_name}>
                <div className={classes.form_name_inputs}>
                  <DefaultInput
                    register={register}
                    name="firstName"
                    placeholder={checkout.name_inputs.placeholder_first_name}
                  />
                  <DefaultInput
                    register={register}
                    name="lastName"
                    placeholder={checkout.name_inputs.placeholder_first_name}
                  />
                </div>
              </div>
              {watchCountry?.value && (
                <div className={classes.email_and_phone}>
                  <PhoneInput
                    countryIso={watchCountry?.value}
                    control={control}
                    translations={checkout.phone_input}
                    placeholder={checkout.phone_input.placeholder_phone_number}
                    isValidPhoneNumber={isValidNumber}
                  />
                  <InputEmail register={register} isError={false} />
                </div>
              )}
              <div className={classes.form_address}>
                <div className={classes.email_and_phone}>
                  <GooglePlacesInput
                    setAddManually={setAddManually}
                    setAddress={setAddress}
                    setValue={setValue}
                    watch={watch}
                    control={control}
                    address={address}
                  />
                  {address && (
                    <input
                      className={`${classes.input} ${classes.address}`}
                      placeholder={checkout.address_input.address2_placeholder}
                      autoComplete="address-line2"
                      {...register('line2', {
                        required: false,
                      })}
                      onChange={handleChangeLine2}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {!addManually && (
          <div className={classes.form_buttons}>
            <button
              type="button"
              className={classes.form_buttons_cancel}
              onClick={handleCloseModal}
            >
              {checkout.customer_info_modal.cancel_btn}
            </button>
            <button
              type="submit"
              className={classes.form_buttons_submit}
              disabled={!isValid || !isValidNumber || !address || !isDirty}
            >
              {checkout.customer_info_modal.edit.update_btn}
            </button>
          </div>
        )}
        <Address
          register={register}
          getValues={getValues}
          addManually={!!addManually}
          handleContinue={handleContinue}
          errors={errors}
          control={control}
          defaultCountryBrowser={defaultCountryBrowser}
          setValue={setValue}
        />
      </form>
    </Modal>
  );
};

export default AddressModal;
