/* eslint-disable indent */
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { StorefrontRoutePath } from 'router/Routes/Storefront';

interface HookReturnType {
  getShopPath: (shopId: string) => string;
  getAccountPath: (shopId: string) => string;
  getAccountOrderPath: (shopId: string) => string;
  getAccountSecurityPath: (shopId: string) => string;
  getAccountAddressPath: (shopId: string) => string;
  goToShop: (shopId: string, search: string) => void;
  goBack: () => void;
  goToCart: (shopUrl: string, location: { search: string }) => void;
  goToCheckout: (shopUrl: string, location: { search: string }) => void;
  goToOrder: (shopUrl: string, orderId: string, location: { search: string }) => void;
  goToProduct: (shopId: string, productId: string, location: { search: string }) => void;
  pathToAbout: (shopId: string, search?: string) => string;
  goToAccount: (shopId: string, location: { search?: string }) => void;
  getOrderPath: (shopId: string, orderId: string) => string;
}

export const useStorefrontRoutes = (): HookReturnType => {
  const history = useHistory();

  const getShopPath = (shopId: string) => `${StorefrontRoutePath.MAIN.replace(':id', shopId)}`;

  const getAccountPath = (shopId: string) => StorefrontRoutePath.ACCOUNT.replace(':id', shopId);

  const getAccountOrderPath = (shopId: string) => StorefrontRoutePath.ACCOUNT_ORDERS.replace(':id', shopId);

  const getAccountSecurityPath = (shopId: string) => StorefrontRoutePath.ACCOUNT_SECURITY.replace(':id', shopId);

  const getAccountAddressPath = (shopId: string) => StorefrontRoutePath.ACCOUNT_ADDRESS.replace(':id', shopId);

  const getOrderPath = (shopId: string, id: string) => StorefrontRoutePath.ORDER.replace(':id', shopId).replace(':orderId', id);

  const goToShop = (shopId: string, search: string) => history.push({
    pathname: StorefrontRoutePath.MAIN.replace(':id', shopId),
    search,
  });

  const pathToAbout = useCallback((shopId: string, search?: string): string => `${StorefrontRoutePath.ABOUT.replace(':id', shopId)}${search}`, []);

  const goBack = () => history.goBack();

  const goToCart = (shopId: string, location: { search: string }) => {
    history.push({
      pathname: `/${shopId}/s/cart`,
      search: location.search,
    });
  };

  const goToProduct = (shopId: string,
    productId: string,
    location: { search: string }) => history.push({
      pathname: `/${shopId}/s/${productId}`,
      state: { background: location },
      search: location.search,
    });

  const goToCheckout = (shopId: string, location: { search: string }) => {
    history.push({
      pathname: `/${shopId}/s/checkout`,
      search: location.search,
    });
  };

  const goToOrder = (
    shopId: string,
    orderId: string,
    location: { search: string },
  ) => history.push({
    pathname: `/${shopId}/s/orders/${orderId}`,
    search: location.search,
  });

  const goToAccount = (
    shopId: string,
    location: { search?: string },
  ) => history.push({
    pathname: StorefrontRoutePath.ACCOUNT.replace(':id', shopId),
    search: location.search,
  });

  return {
    getAccountPath,
    getAccountAddressPath,
    getOrderPath,
    getAccountOrderPath,
    getAccountSecurityPath,
    pathToAbout,
    getShopPath,
    goToShop,
    goBack,
    goToProduct,
    goToCart,
    goToCheckout,
    goToOrder,
    goToAccount,
  };
};
