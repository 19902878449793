import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Actions
import { GetBillData } from 'store/bill/billActions';
// Components
import LoadingPage from 'pages/LoadingPage';
import DocumentsLayout from 'layouts/DocumentsLayout';
import Title from 'components/Documents/Title';
import Status from 'components/Documents/Status';
import { TransactionStatus } from 'types/globalTypes';
import Contacts from 'components/Documents/Contacts';
import ItemsTable from 'components/Documents/ItemsTable';
import Notes from 'components/Documents/Notes';

interface IParams {
  id: string;
  publicId: string;
}

const Bill: React.FC = () => {
  const { id, publicId } = useParams<IParams>();
  const dispatch = useDispatch();
  const { loading, data } = useTypeSelector((state) => state.bill);
  const translations = useTranslationsStorefront();

  useEffect(() => {
    dispatch(GetBillData(id, publicId));
  }, [dispatch, id, publicId]);

  if (!data || loading) {
    return (
      <LoadingPage />
    );
  }

  return (
    <DocumentsLayout data={{
      organisation: data.organisation,
    }}
    >
      <Title
        name="Bill"
        number={data.details.reference}
      />
      <Status
        status={data.paid ? TransactionStatus.PAID : TransactionStatus.UNPAID}
        isPaymentScheduled={false}
      />
      <Contacts
        organisation={data.organisation}
        contact={data.contact}
        translations={translations.documents.contacts}
        type={data.details.type}
      />
      <ItemsTable items={data.items} price={data.price} />
      <Notes
        title={translations.documents.notes}
        notes={data.termsPayment.notes}
      />
    </DocumentsLayout>
  );
};

export default Bill;
