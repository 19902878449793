import React, { useCallback, useMemo } from 'react';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
// Types
import { OrderItem } from 'store/storefront/checkout/checkoutTypes';
import { TranslationStorefrontModel } from 'types/TranslationStorefrontModel';
// Utils
import { calcTaxes } from 'utils/functions';
import { CurrencyFormatByISO, calcTotalFees, calcSubtotal } from 'utils/price';
import { capitalizeFirstLetter } from 'utils/strings';
// Styles
import classes from './Total.module.scss';

interface Props {
  translation: TranslationStorefrontModel['cart']['cart_item']
}

const Total: React.FC<Props> = ({ translation }) => {
  const { data } = useTypeSelector(({ storefront }) => storefront.order);
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);
  const formatCurrencyByISO = useMemo(
    () => CurrencyFormatByISO(currentSalesChannel?.address?.currencyISO),
    [currentSalesChannel?.address?.currencyISO],
  );

  const fulfillmentDetailsFeesCount = useCallback(() => {
    if (data?.fees && data?.fees.length > 0) {
      return data?.fees.reduce((acc, current) => acc + Object.values(current)[0], 0);
    }

    return 0;
  }, [data?.fulfillment]);

  const fulfillmentDetailsFees = useCallback(() => {
    if (data?.fulfillment?.type) {
      const name = data?.fulfillment.type;
      return {
        [capitalizeFirstLetter(name.toLowerCase())]: data?.fulfillment.fee,
      };
    }
    return null;
  }, [data?.fulfillment.fee, data?.fulfillment.type]);

  const fees = useMemo(() => {
    const fulfillmentFees = fulfillmentDetailsFees();
    if (fulfillmentFees && data?.fees) {
      return [...data?.fees, fulfillmentFees];
    }

    if (fulfillmentFees) {
      return [fulfillmentFees];
    }

    if (data?.fees) {
      return [...data?.fees];
    }

    return undefined;
  }, [data?.fees, fulfillmentDetailsFees]);

  const taxes = useMemo(() => calcTaxes(data?.items), [data?.items]);
  const subtotalValue = useMemo(() => data && calcSubtotal(data), [data]);
  const total = useMemo(() => (subtotalValue || 0)
    - (data?.totalDiscount || 0)
    + calcTotalFees(data?.fees) + taxes.excluded + fulfillmentDetailsFeesCount()!,
  [data?.totalDiscount, data?.fees, fulfillmentDetailsFeesCount, subtotalValue, taxes.excluded]);

  const subtotals = useMemo(() => [{
    name: translation.subtotal,
    value: formatCurrencyByISO(subtotalValue),
  },
  {
    name: translation.discount,
    value: formatCurrencyByISO(data?.totalDiscount),
  },
  ], [data?.totalDiscount, formatCurrencyByISO, subtotalValue, translation]);

  return (
    <div className={classes.root}>
      <div className={classes.subtotal_list}>
        {subtotals.map((subtotal) => (
          <div className={classes.subtotal}>
            <p>
              {subtotal.name}
            </p>
            <p>
              {subtotal.value}
            </p>
          </div>
        ))}
        {fees?.map((fee) => (
          <div className={classes.subtotal}>
            <p>
              {Object.keys(fee)[0]}
            </p>
            <p>
              {formatCurrencyByISO(fee[Object.keys(fee)[0]])}
            </p>
          </div>
        ))}
      </div>
      <div className={classes.total}>
        <p>
          {translation.total}
        </p>
        <p>
          {formatCurrencyByISO(total)}
        </p>
      </div>
    </div>
  );
};

export default Total;
