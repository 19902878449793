import React from 'react';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Utils
import { getDayName, getWeekTradingHours } from 'utils/dates';
//  Styles
import Modal from 'components/Modal';
import HeaderModal from 'components/StorefrontComponents/HeaderModal';
import classes from './TradingHours.module.scss';

interface Props {
  active: boolean;
  handleModal: () => void;
}

const MoreInfoModal: React.FC<Props> = ({ active, handleModal }) => {
  const { data } = useTypeSelector(({ storefront }) => storefront.order);
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);

  const { checkout } = useTranslationsStorefront();

  const tradingHours = currentSalesChannel?.fulfilment?.pickup?.find(
    (loc) => loc.address === data?.fulfillment.address,
  )?.tradingHours;

  const weekTradingHours = getWeekTradingHours(tradingHours);

  return (
    <Modal active={active} setActive={handleModal}>
      <div className={classes.root}>
        <HeaderModal onClick={handleModal} className={classes.header} />
        <div className={classes.title}>
          {data?.fulfillment.address.line1}
        </div>
        <div className={classes.address}>
          <p className={classes.address_title}>
            {checkout.more_info_modal.address_text}
          </p>
          <p className={classes.address_value}>
            {data?.fulfillment.address.line1}
          </p>
        </div>
        <div className={classes.operation_hours}>
          <div className={classes.operation_hours_title}>
            {checkout.more_info_modal.operating_hours_text}
          </div>
          <div className={classes.operation_hours_values}>
            {weekTradingHours?.map((item) => (
              <div className={classes.operation_hours_values_value} key={item.day}>
                <span>
                  {getDayName(item.day)}
                </span>
                {item.closed ? (
                  <span>
                    {checkout.more_info_modal.closed_text}
                  </span>
                ) : (
                  <span>
                    {item.from} - {item.to}
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MoreInfoModal;
