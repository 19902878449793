import React, { useState } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close-white.svg';
import { isEmptyObject, openInNewTab } from 'utils/functions';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Images
import { ReactComponent as TwitterIcon } from 'assets/storefront-icons/socials/twitter.svg';
import { ReactComponent as FacebookIcon } from 'assets/storefront-icons/socials/facebook.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { useParams } from 'react-router-dom';
// Styles
import classes from './ShareShopPopup.module.scss';

type RedirectType = 'whatsapp' | 'email' | 'twitter' | 'facebook';

const SocialLinks = [
  {
    title: 'Facebook',
    image: <FacebookIcon />,
    href: 'facebook',
  },
  {
    title: 'Twitter',
    image: <TwitterIcon />,
    href: 'twitter',
  },
];

interface Props {
  close: () => void;
  active: boolean;
}

const ShareShopPopup: React.FC<Props> = ({
  active, close,
}) => {
  const { id } = useParams<{ id: string }>();
  const [isCopied, setIsCopied] = useState(false);
  const showHideClassName = active ? classes.popup : classes.popup_hide;
  const { share_shop } = useTranslationsStorefront();
  const url = `${window.location.host}/${id}/s`;
  const shareShopHandler: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const type = event.currentTarget.getAttribute('data-link')! as RedirectType;

    switch (type) {
      case 'twitter': {
        const link = `https://twitter.com/share?text=${url}`;
        close();
        openInNewTab(link);
      }
        break;
      case 'facebook': {
        const link = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        close();
        openInNewTab(link);
      }
        break;
      default:
        break;
    }
  };

  const copyClickHandler = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 2500);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <div
      className={showHideClassName}
    >
      <CloseIcon onClick={close} className={classes.popup_close} />
      <p className={classes.popup_title}>
        {share_shop.title}
      </p>
      <div className={classes.popup_socials}>
        {SocialLinks.map((link) => (isEmptyObject(link)
          ? null
          : (
            <button
              type="button"
              data-link={link.href}
              onClick={shareShopHandler}
              key={link.title}
            >
              {link.image}{link.title}
            </button>
          )))}
      </div>
      <div className={classes.popup_input_wrapper}>
        <input type="text" placeholder={url} />
        <CopyIcon onClick={copyClickHandler} />
      </div>
      <span className={isCopied ? classes.popup_copied_active : classes.popup_copied}>
        {share_shop.copied}
      </span>
    </div>
  );
};

export default ShareShopPopup;
