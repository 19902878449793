import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useStorefrontRoutes } from 'hooks/useStorefrontRoutes';
import { useMobileScreen } from 'hooks/useMobileScreen';
// Types
import { Variation } from 'store/dashboard/catalog/items/itemsTypes';
// Actions
import {
  getProduct,
  getProductVariations,
  SetAddToBagAction,
  setProductAction, SetProductToDetailsAction, SetToConfirmAction,
} from 'store/storefront/shop/shopActions';
import { setNotificationAction } from 'store/storefront/app/appActions';
// Utils
import { CartFunctions } from 'utils/storefront/CartFunctions';
// Components
import LoadingPage from 'pages/LoadingPage';
import Notification from 'components/StorefrontComponents/Notification';
import { NotificationEnum } from 'store/storefront/shop/shopTypes';
import Variations from './Variations';
import Header from './Header';
import DetailsDeliveryOptions from './Variations/DetailsDeliveryOptions';
// Styles
import classes from './ShopItemDetails.module.scss';

const ShopItemDetails: React.FC = () => {
  const { isMobile } = useMobileScreen();
  const { id, productId }: { id: string, productId: string } = useParams();
  const dispatch = useDispatch();
  const { details_page, options, cart } = useTranslationsStorefront();
  const {
    bag,
    currentSalesChannel,
    productDetails,
  } = useTypeSelector(({ storefront }) => storefront.shop);
  const { notification } = useTypeSelector(({ storefront }) => storefront.app);
  const [currentOption, setOption] = useState<string | null>(null);
  const [variant, setVariant] = useState<Variation>();
  const [quantity, setQuantity] = useState<number>(1);
  const { goToShop } = useStorefrontRoutes();
  const location = useLocation();
  const toggleClose = useCallback(() => {
    document.body.style.overflow = 'unset';
    dispatch(setProductAction(null));
    goToShop(id, location.search);
    dispatch(setNotificationAction(null));
  }, [dispatch, goToShop, id, location.search]);

  const itemInBag = useMemo(() => bag.find(
    (product) => product.variations[0].id === currentOption,
  ), [bag, currentOption]);

  const inventory = useMemo(() => variant?.inventories.find(
    (i) => i.locationId === currentSalesChannel?.id,
  ), [currentSalesChannel?.id, variant?.inventories]);

  const productDetailsInventory = useMemo(() => {
    if (productDetails && productDetails.variations) {
      return productDetails.variations[0].inventories?.find(
        (salesChannel) => salesChannel.locationId === currentSalesChannel?.id,
      );
    }

    return undefined;
  }, [currentSalesChannel?.id, productDetails]);

  const disableButton = useMemo(() => {
    if (!currentOption && !productDetails?.variations && !currentSalesChannel) {
      return true;
    }

    if (itemInBag) {
      return ((inventory?.quantity || 1) - itemInBag.quantity <= 0);
    }

    return !inventory?.quantity;
  }, [currentOption, currentSalesChannel, inventory?.quantity, itemInBag, productDetails]);

  const getDisableAndQuantity = useMemo(() => {
    if (!currentOption || !productDetails?.variations || !currentSalesChannel) {
      return {
        quantity: 1,
        disabled: false,
      };
    }

    if (itemInBag) {
      return {
        quantity: (inventory?.quantity || 1) - itemInBag.quantity,
        disabled: (inventory?.quantity || 1) - itemInBag.quantity <= 0,
      };
    }

    return {
      quantity: inventory?.quantity || 1,
      disabled: false,
    };
  }, [currentOption, productDetails?.variations, currentSalesChannel, itemInBag, inventory]);

  const addToBagHandler = useCallback(() => {
    if (productDetails && currentOption) {
      const product = CartFunctions.getProductWithVariation(
        productDetails,
        currentOption,
      );

      const isProductInCart = CartFunctions.isProductInCart(bag, product, currentOption);
      if (!isProductInCart) {
        const updateBag = [
          ...bag,
          {
            ...product,
            quantity,
          },
        ];

        localStorage.setItem(`leja-${id}-cart`, JSON.stringify(updateBag));
        dispatch(SetAddToBagAction(updateBag));
        dispatch(SetToConfirmAction({
          product: {
            ...product,
            quantity,
          },
          show: true,
        }));
      } else {
        const updatedBag = CartFunctions.updateCart(bag, productId, quantity, currentOption);
        localStorage.setItem(`leja-${id}-cart`, JSON.stringify(updatedBag.bag));
        dispatch(SetAddToBagAction(updatedBag.bag));
        dispatch(SetToConfirmAction({
          product: updatedBag.product,
          show: true,
        }));
      }
      dispatch(setNotificationAction(NotificationEnum.ADD_TO_CART));
      document.body.style.overflow = 'unset';
      dispatch(setProductAction(null));
      goToShop(id, location.search);
      dispatch(SetProductToDetailsAction({
        productId,
        show: false,
      }));
    }
  }, [bag, currentOption,
    dispatch, goToShop, id, location.search, productDetails, productId, quantity]);

  const closeNotification = useCallback(() => {
    dispatch(setNotificationAction(null));
  }, [dispatch]);

  useEffect(() => {
    if (!productId
      || !id
      || productDetails?.variations
      || !productDetails) {
      return;
    }

    if (!productDetails.variations?.length) {
      dispatch(getProductVariations(id, productId, productDetails));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, productId, location.state, productDetails]);

  useEffect(() => {
    if (productDetails && productDetails.variations && currentSalesChannel) {
      if (productDetails?.variations[0].id) {
        setOption(productDetails?.variations[0].id);
      }
    }
  }, [currentSalesChannel, productDetails, productDetailsInventory]);

  useEffect(() => {
    if (!productDetails) {
      dispatch(getProduct(id, productId));
    }
  }, [productId, id, productDetails, dispatch]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    if (productDetails?.variations) {
      setVariant(productDetails.variations.find((v) => v.id === currentOption));
    }
  }, [currentOption, productDetails, productDetails?.variations]);

  useEffect(() => {
    if (quantity === variant?.stock) {
      dispatch(setNotificationAction(NotificationEnum.QUANTITY));
    }
  }, [dispatch, variant?.stock, quantity]);

  useEffect(() => {
    if (variant?.stock) {
      if (variant?.stock < quantity) {
        setQuantity(1);
      }
    }
  }, [quantity, variant?.stock]);

  if (!productDetails || !productDetails?.variations?.length) {
    return (
      <LoadingPage
        backgroundColor="rgba(32, 39, 43, 0.8)"
      />
    );
  }

  return (
    <div className={classes.modal}>
      <div
        className={classes.container}
      >
        <Header
          toggleClose={toggleClose}
          avatar={{
            name: productDetails.name,
            images: productDetails.images,
          }}
          btn={{
            title: details_page.add_btn_label,
            onClick: addToBagHandler,
            disabled: disableButton,
          }}
          quantity={{
            disabled: getDisableAndQuantity.disabled,
            quantity,
            setQuantity,
            stock: getDisableAndQuantity.quantity,
          }}
        />
        <div className={classes.title_wrapper}>
          <h3 className={classes.title}>
            {productDetails?.name}
          </h3>
          <div className={classes.product_stock}>
            {!!productDetails.stock
              && productDetails.stock < 6
              && (
                <div className={classes.product_stock_count}>
                  {`${productDetails.stock} ${cart.warning.left}`}
                </div>
              )}
          </div>
        </div>
        <Variations
          variations={productDetails.variations}
          product={productDetails}
          currentOption={currentOption}
          currentVariant={variant}
          setOption={setOption}
          translation={{
            label: options,
            in_stock: details_page.option_in_stock,
            out_stock: details_page.option_out_stock,
          }}
          currencyISO={currentSalesChannel?.address.currencyISO}
        />
        <DetailsDeliveryOptions
          shopName={currentSalesChannel?.name}
          itemName={productDetails.name}
          itemDescription={productDetails.description}
          currentSalesChannel={currentSalesChannel}
        />
        {isMobile
          && (
            <button
              type="button"
              className={classes.add_to_bag}
              onClick={addToBagHandler}
              disabled={disableButton}
            >
              {details_page.add_btn_label}
            </button>
          )}
        <Notification
          text={details_page.notification.text}
          active={notification === NotificationEnum.QUANTITY}
          handleClose={closeNotification}
        />
      </div>
    </div>
  );
};

export default ShopItemDetails;
