import React, { useMemo } from 'react';
import cn from 'classnames';
// Types
import { Payments, ResPaymentStatus, ScheduleItem } from 'types/globalTypes';
// Utils
import { CurrencyFormatByISO } from 'utils/price';
import { formatReg } from 'utils/dates';
// Assets
import { ReactComponent as CheckIcon } from 'assets/icons/round-tick.svg';
// Styles
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { capitalizeFirstLetter } from 'utils/strings';
import classes from './PayTable.module.scss';

interface Props {
  balance: number;
  deposit?: number;
  paidOn?: string;
  scheduleItems?: ScheduleItem[] | null;
  payments?: Payments[] | null;
  handlePaymentTotal?: () => void;
  handlePaymentSchedule?: (amount: number) => void;
  currencyIso?: string;
}

const PayTable: React.FC<Props> = ({
  balance,
  deposit,
  paidOn,
  scheduleItems,
  payments,
  currencyIso,
  handlePaymentSchedule,
  handlePaymentTotal,
}) => {
  const formatCurrencyByISO = useMemo(
    () => CurrencyFormatByISO(currencyIso),
    [currencyIso],
  );
  const translations = useTranslationsStorefront();
  const ts = translations.documents.pay_table;

  const paidData = useMemo(
    () => [
      {
        title: ts.paid_with,
        value:
          payments?.length
          && capitalizeFirstLetter(payments[0].paymentMethod?.toLocaleLowerCase()),
      },
      {
        title: ts.paid_on,
        value: formatReg(paidOn),
      },
      {
        title: ts.balance,
        value:
          Math.sign(balance) === -1
            ? formatCurrencyByISO(0)
            : formatCurrencyByISO(balance),
      },
    ],
    [
      balance,
      formatCurrencyByISO,
      paidOn,
      payments,
      ts.balance,
      ts.paid_on,
      ts.paid_with,
    ],
  );

  if (balance !== 0 && Math.sign(balance) !== -1 && scheduleItems) {
    return (
      <div className={classes.schedule_table}>
        <p className={classes.schedule_table_title}>{ts.payment_schedule}</p>
        <p className={classes.schedule_table_deposit}>Deposit: {formatCurrencyByISO(deposit)}</p>
        <div className={classes.schedule_table_list}>
          {scheduleItems.map((scheduleItem, i) => (
            <div className={classes.schedule_item} key={scheduleItem.date}>
              <div
                className={cn(classes.schedule_item_check, {
                  [classes.schedule_item_uncheck]:
                    scheduleItem.status === ResPaymentStatus.UNPAID,
                })}
              >
                <CheckIcon />
              </div>
              <div className={classes.schedule_item_info}>
                <p className={classes.schedule_item_info_date}>
                  {formatReg(scheduleItem.date)}
                </p>
                <p className={classes.schedule_item_info_amount}>
                  {formatCurrencyByISO(scheduleItem.amount)}
                </p>
                <p className={classes.schedule_item_info_instalment}>
                  {scheduleItem.status === ResPaymentStatus.PAID
                    ? ts.instalment_paid
                    : ts.instalment_due}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className={classes.divider}>
          <p>{ts.or}</p>
          <p />
        </div>
        <div className={classes.total_pay}>
          <div className={classes.total_pay_balance}>
            <p className={classes.total_pay_balance_title}>{ts.balance}</p>
            <p className={classes.total_pay_balance_value}>
              {formatCurrencyByISO(balance)}
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (balance !== 0 && Math.sign(balance) !== -1) {
    return (
      <div className={classes.unpaid_table}>
        <div className={classes.unpaid_table_balance}>
          <p className={classes.unpaid_table_balance_title}>{ts.balance}</p>
          <p className={classes.unpaid_table_balance_value}>
            {formatCurrencyByISO(balance)}
          </p>
        </div>
        <button
          type="button"
          className={classes.unpaid_table_btn}
          onClick={handlePaymentTotal}
        >
          {ts.pay}
        </button>
      </div>
    );
  }

  return (
    <div className={classes.pay_table}>
      {paidData.map((v) => (
        <div className={classes.pay_table_container} key={v.title}>
          <p className={classes.pay_table_container_title}>{v.title}</p>
          <p className={classes.pay_table_container_value}>{v.value}</p>
        </div>
      ))}
    </div>
  );
};

export default PayTable;
