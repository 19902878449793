import React from 'react';
import { DeliveryMethodsEnum } from 'constants/deliveryMethods';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { IOrderData } from 'store/storefront/order/orderTypes';
// Classes
import classes from './CustomerInfo.module.scss';

interface Props {
  fulfillmentType: DeliveryMethodsEnum;
  status: IOrderData['status']['name'];
  contact: IOrderData['contact'];
  fulfillment: IOrderData['fulfillment']
}

const CustomerInfo: React.FC<Props> = ({
  fulfillmentType,
  status,
  contact,
  fulfillment,
}) => {
  const { order: { customer_info: translations } } = useTranslationsStorefront();

  if (status === 'CANCELLED') {
    return (
      <div className={classes.customer_info_cancelled}>
        <div className={classes.customer_info_left}>
          <p className={classes.customer_info_left_title}>
            {translations.customer_text}
          </p>
          <div>
            <p>
              {`${contact.firstName} ${contact.lastName}`}
            </p>
            <p>
              {contact.mobileNumber}
            </p>
            <p>
              {contact.email}
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (status === 'UNPAID' || status === 'UNPROCESSED') {
    return (
      <div className={classes.customer_info}>
        <div className={classes.customer_info_left}>
          <p className={classes.customer_info_left_title}>
            {translations.customer_text}
          </p>
          <div>
            <p>
              {`${contact.firstName} ${contact.lastName}`}
            </p>
            <p>
              {contact.mobileNumber}
            </p>
            <p>
              {contact.email}
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (fulfillmentType === DeliveryMethodsEnum.DELIVERY) {
    return (
      <div className={classes.customer_info}>
        <div className={classes.customer_info_left}>
          <p className={classes.customer_info_title}>
            {translations.deliver_to_text}
          </p>
          <div>
            <p>
              {`${contact.firstName} ${contact.lastName}`}
            </p>
            <p>
              {contact.mobileNumber}
            </p>
            <p>
              {contact.email}
            </p>
          </div>
        </div>
        <div className={classes.customer_info_right}>
          <p className={classes.customer_info_title}>
            {translations.address_text}
          </p>
          <div>
            <p>
              {contact.address.line1}
            </p>
            <p>
              {contact.address.line2}
            </p>
            <p>
              {`${contact.address.city}, ${contact.address.postalCode} ${contact.address.countryISO}`}
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (fulfillmentType === DeliveryMethodsEnum.PICKUP) {
    return (
      <div className={classes.customer_info}>
        <div className={classes.customer_info_left}>
          <p className={classes.customer_info_title}>
            {translations.customer_text}
          </p>
          <div>
            <p>
              {`${contact.firstName} ${contact.lastName}`}
            </p>
            <p>
              {contact.mobileNumber}
            </p>
            <p>
              {contact.email}
            </p>
          </div>
        </div>
        <div className={classes.customer_info_right}>
          <p className={classes.customer_info_title}>
            {translations.pickup_location_text}
          </p>
          <div>
            <p className={classes.customer_info_name}>
              {fulfillment.name || 'Hello World'}
            </p>
            <p>
              {fulfillment?.address.line1}
            </p>
            <p>
              {`${fulfillment?.address.countryISO}`}
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (fulfillmentType === DeliveryMethodsEnum.SHIPPING) {
    return (
      <div className={classes.customer_info}>
        <div className={classes.customer_info_left}>
          <p className={classes.customer_info_title}>
            {translations.ship_to_text}
          </p>
          <div>
            <p>
              {`${contact.firstName} ${contact.lastName}`}
            </p>
            <p>
              {contact.mobileNumber}
            </p>
            <p>
              {contact.email}
            </p>
          </div>
        </div>
        <div className={classes.customer_info_right}>
          <p className={classes.customer_info_title}>
            {translations.address_text}
          </p>
          <div>
            <p>
              {fulfillment.name}
            </p>
            <p>
              {fulfillment?.address.line1}
            </p>
            <p>
              {`${fulfillment?.address.countryISO}`}
            </p>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default CustomerInfo;
