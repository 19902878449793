import React, { Dispatch, SetStateAction } from 'react';
// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useUser } from 'hooks/useUser';
import { useMobileScreen } from 'hooks/useMobileScreen';
// Actions
import { selectTransactionDetails } from 'store/dashboard/transaction/transactionSelectors';
// Utils
import { formatReg } from 'utils/dates';
import { CurrencyFormatByISO } from 'utils/price';
// Types
import { ResPaymentStatus, TransactionStatus } from 'types/globalTypes';
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
// Assets
import { ReactComponent as CloseIcon } from 'assets/dashboard-icons/close-icon.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/back-arrow.svg';
// Components
import Modal from 'components/Modal';
// Styles
import classes from './PaymentScheduleModal.module.scss';

interface ModalProps {
  setActive: Dispatch<SetStateAction<boolean>>;
  active: boolean;
  handleOpenRecordPayment: (amount?: number) => void;
  totalAmountPaymentSchedule?: number;
}

const PaymentScheduleModal: React.FC<ModalProps> = (
  {
    setActive, active, handleOpenRecordPayment, totalAmountPaymentSchedule,
  },
) => {
  const translations = useTranslations();
  const isPaid = (
    status: ResPaymentStatus,
  ) => status.toLowerCase() === TransactionStatus.PAID;

  const transaction = useTypeSelector(selectTransactionDetails);
  const tsTransaction = translations.transactions;
  const user = useUser();
  const { isMobile } = useMobileScreen();
  const formatCurrencyByISO = CurrencyFormatByISO(user?.currentOrganisation?.address?.currencyISO || 'USD');

  const handleClose = () => {
    setActive(false);
  };

  if (!transaction) {
    return null;
  }

  return (
    <Modal active={active} setActive={setActive} type="two">
      <div className={classes.content}>
        {isMobile ? (
          <ArrowIcon
            className={classes.close_icon}
            onClick={handleClose}
          />
        ) : (
          <CloseIcon
            className={classes.close_icon}
            onClick={handleClose}
          />
        )}
        <div className={classes.description}>
          <div className={classes.description_name}>
            <p className={classes.description_name_label}>
              {tsTransaction.payment_schedule}
            </p>
            <p className={classes.description_name_value}>
              {`${translations.documents.invoice} # ${transaction?.id}`}
            </p>
          </div>
          <div className={classes.description_total}>
            <p className={classes.description_total_label}>
              {translations.documents.invoice_total}
            </p>
            <p className={classes.description_total_value}>
              {formatCurrencyByISO(transaction.balance)}
            </p>
          </div>
        </div>
        <ol>
          {transaction?.terms?.paymentSchedule?.map((item, id, arr) => (isMobile
            ? (
              <li key={item.date}>
                <div className={classes.paymentSchedule_top}>
                  <div className={classes.paymentSchedule_top_due}>
                    <p className={classes.paymentSchedule_top_due_label}>
                      {tsTransaction.due}
                    </p>
                    <p className={isPaid(item.status)
                      ? classes.paymentSchedule_top_due_value
                      : classes.paymentSchedule_top_due_value_active}
                    >
                      {formatReg(item.date)}
                    </p>
                  </div>
                  <div className={classes.paymentSchedule_top_amount}>
                    <p className={classes.paymentSchedule_top_amount_label}>
                      {tsTransaction.tabs_header.amount}
                    </p>
                    <p className={isPaid(item.status)
                      ? classes.paymentSchedule_top_amount_value
                      : classes.paymentSchedule_top_amount_value_active}
                    >
                      {formatCurrencyByISO(item.balance
                        ? item.balance
                        : item.amount)}
                    </p>
                  </div>
                </div>
                <div className={classes.paymentSchedule_status}>
                  {isPaid(item.status) ? (
                    <p className={classes.paymentSchedule_status_name}>
                      {translations.status[item.status.toLowerCase() as keyof TranslationDashboardModel['status']]}
                    </p>
                  )
                    : (
                      <button
                        type="button"
                        className={classes.payment_schedule_button}
                        onClick={() => handleOpenRecordPayment(item.balance || item.amount)}
                        disabled={arr[id > 0 ? id - 1 : id].status === ResPaymentStatus.UNPAID
                          && !(id === 0 && arr[id].status === ResPaymentStatus.UNPAID)
                          && item.status === ResPaymentStatus.UNPAID}
                      >
                        {translations.documents.record_payment}
                      </button>
                    )}
                </div>
              </li>
            ) : (
              <li key={item.date}>
                <div className={classes.value_container}>
                  <p
                    className={isPaid(item.status) ? classes.value_paid : classes.value}
                  >
                    {formatCurrencyByISO(item.balance || item.amount)}
                  </p>
                  <p className={classes.date}>
                    {`${tsTransaction.due}  ${formatReg(item.date)}`}
                  </p>
                </div>
                {
                  isPaid(item.status) ? (
                    <div className={classes.status_container}>
                      <p className={classes.status}>{tsTransaction.tabs_header.status}</p>
                      <p
                        className={classes.status_type}
                      >
                        {translations.status[item.status.toLowerCase() as keyof TranslationDashboardModel['status']]}
                      </p>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className={classes.payment_schedule_button}
                      onClick={() => handleOpenRecordPayment(item.balance || item.amount)}
                      disabled={arr[id > 0 ? id - 1 : id].status === ResPaymentStatus.UNPAID
                        && !(id === 0 && arr[id].status === ResPaymentStatus.UNPAID)
                        && item.status === ResPaymentStatus.UNPAID}
                    >
                      {translations.documents.record_payment}
                    </button>
                  )
                }
              </li>
            )))}
        </ol>
        {isMobile
          ? (
            <div className={classes.btn_wrapper}>
              <p className={classes.btn_wrapper_label}>
                {tsTransaction.record_payment_total_outstanding}
              </p>
              <button
                className={classes.btn}
                type="button"
                onClick={() => handleOpenRecordPayment()}
              >
                {tsTransaction.record_payment_for.replace('{amount}', formatCurrencyByISO(totalAmountPaymentSchedule))}
              </button>
            </div>
          ) : (
            <button
              className={classes.btn}
              type="button"
              onClick={() => handleOpenRecordPayment()}
            >
              {tsTransaction.or_record_payment_for.replace('{amount}', formatCurrencyByISO(totalAmountPaymentSchedule))}
            </button>
          )}
      </div>
    </Modal>
  );
};

export default PaymentScheduleModal;
